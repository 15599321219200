import { v4 as uuidv4 } from 'uuid';

export function isDefined<Type>(item: Type | undefined | null): item is Type {
  return !!item;
}

export function unique<Type>(items: Type[]): Type[] {
  return Array.from(new Set(items));
}

export function toMap<Type, TKey, TValue>(
  items: Type[] | undefined | null,
  mapFn: (item: Type) => [TKey, TValue]
): Map<TKey, TValue> {
  const result = new Map<TKey, TValue>();

  (items ?? []).forEach((i) => {
    const [key, value] = mapFn(i);
    result.set(key, value);
  });

  return result;
}

interface ToObjectOptions {
  removeNullValues?: boolean;
  removeUndefinedValues?: boolean;
  throwNullOrUndefined?: boolean;
}

export function toObject<Type, TValue>(
  items: Type[] | undefined | null,
  mapFn: (item: Type, index: number) => [string, TValue],
  options: ToObjectOptions = {
    removeNullValues: false,
    removeUndefinedValues: false,
    throwNullOrUndefined: false,
  }
) {
  const result: { [key: string]: TValue } = {};
  (items ?? []).forEach((i, index) => {
    const [key, value] = mapFn(i, index);

    if (options.throwNullOrUndefined && (value === null || value === undefined)) {
      throw new Error(`${key} was null or undefined`);
    }
    if (options.removeNullValues && value === null) {
      return;
    }

    if (options.removeUndefinedValues && value === undefined) {
      return;
    }

    result[key] = value;
  });
  return result;
}

export function toList<TValue, Type extends { [key: string]: TValue }, TResult>(
  obj: Type,
  mapFn: (key: string, value: TValue) => TResult
) {
  return Object.keys(obj).map((k) => mapFn(k, obj[k]));
}

export function objectMap<Type, ResultType>(
  obj: { [key: string]: Type },
  fnValue: (key: string, input: Type) => ResultType,
  fnKey?: (key: string) => string
) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [fnKey?.(k) ?? k, fnValue(k, v)]));
}

export function assignIds<T>(items: T[]) {
  return items.map((i) => ({ ...i, id: uuidv4() }));
}

export function removeIds<T extends { id: string | number }>(items: T[]) {
  return items.map((i) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...item } = i;
    return item;
  });
}

export function isString(obj: any) {
  return typeof obj === 'string' || obj instanceof String;
}

export function fallbackIfEmpty(value: string | null | undefined, fallback: string) {
  return value && value !== '' ? value : fallback;
}

export function newUuid() {
  return uuidv4();
}

export function newShortId() {
  return newUuid().replace('-', '').slice(0, 10);
}

export function removeHtml(input: string) {
  const regex = /(<([^>]+)>)/gi;
  return input.replace(regex, '');
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function truncate(str: string, n: number) {
  return str.length > n ? `${str.slice(0, n - 1)}...` : str;
}

export function removeKey<T>(collection: { [key: string]: T }, key: string) {
  const copy = { ...collection };
  delete copy[key];
  return copy;
}

export function addToArray(
  items: (string | null)[] | undefined | null,
  item: string | null | undefined
): string[] {
  if (!items) {
    if (item) {
      return [item];
    }

    return [];
  }

  const nonNullItems = items?.filter((x) => x != null).map((x) => x!) ?? [];

  if (!item) {
    return nonNullItems ?? [];
  }

  if (items.includes(item)) {
    return nonNullItems;
  }

  return [...nonNullItems, item];
}

export function removeFromArray(
  items: (string | null)[] | undefined | null,
  item: string | null | undefined
): string[] {
  if (!items) {
    return [];
  }

  const nonNullItems = items?.filter((x) => x != null).map((x) => x!) ?? [];

  if (!item) {
    return nonNullItems ?? [];
  }

  if (!items.includes(item)) {
    return nonNullItems;
  }

  return [...nonNullItems.filter((x) => x !== item)];
}

export function filenameTimestamp() {
  return new Date().toJSON().slice(0, 10);
}

export const normalizeToPercentages = (values: (number | undefined)[]): number[] => {
  const total = values.reduce((sum, value) => (sum ?? 0) + (value ?? 0), 0) ?? 0;
  if (total === 0) return values.map(() => 0);

  const rawPercentages = values.map((value) => ((value ?? 0) / total) * 100);

  // Rond af en zorg dat de som precies 100% blijft
  const roundedPercentages = rawPercentages.map(Math.round);
  let diff = 100 - roundedPercentages.reduce((sum, p) => sum + p, 0);

  // Pas aan totdat de som exact 100 is
  while (diff !== 0) {
    const adjustmentIndex = roundedPercentages.findIndex((p) => p > 0);
    roundedPercentages[adjustmentIndex] += diff > 0 ? 1 : -1;
    diff = 100 - roundedPercentages.reduce((sum, p) => sum + p, 0);
  }

  return roundedPercentages;
};

export function keepDictionaryKeys<T>(
  dict: { [key: string]: T },
  keysToKeep: string[]
): { [key: string]: T } {
  return Object.fromEntries(Object.entries(dict).filter(([key]) => keysToKeep.includes(key)));
}
