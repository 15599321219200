import { useMsalAuthentication, useMsal, useIsAuthenticated } from "@azure/msal-react";

import { useMemo } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { platformBffApi } from 'src/redux/platformBffApi';
import { b2cPolicies, msalRequest } from "./msalInstance";

// ----------------------------------------------------------------------

const claimsToRoles = (claims: any): string[] => {
  if (!claims?.extension_Roles) {
    return [];
  }

  return claims.extension_Roles.toString().split(' ').map((s: string) => s.toUpperCase());
};

export const useLogin = () => {
  useMsalAuthentication(InteractionType.Redirect, msalRequest);
}

export const useAuthContext = () => {
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();
  const roles = claimsToRoles(activeAccount?.idTokenClaims);

  const isAuthenticated = useIsAuthenticated();
  const { data: currentUser } = platformBffApi.useUserGetCurrentUserQuery(undefined, { skip: !isAuthenticated });

  const resetPassword = () => {
    const resetPasswordRequest = {
      authority: b2cPolicies.authorities.forgotPassword.authority,
      scopes: [],
    };
    instance.loginRedirect(resetPasswordRequest);
  };

  const result = useMemo(() => ({
    isAuthenticated,
    user: currentUser ?? null,
    isAdmin: roles.map(r => r.toUpperCase()).includes('ADMIN'),
    method: 'jwt',
    login: () => instance.loginRedirect(msalRequest),
    logout: () => instance.logoutRedirect(),
    roles,
    resetPassword
  }), [currentUser, isAuthenticated]);

  return result;
};
