/* eslint-disable */
import { platformBffApi as api } from '../platformBffApiBase';
export const addTagTypes = [
  'User',
  'Trajectory',
  'Project',
  'Projects',
  'CustomerOrganizationalStructure',
  'CustomerParticipant',
  'Customer',
  'CustomerRepresentative',
  'Customer representative registration',
  'VisualizationAdmin',
  'AdminUser',
  'TrajectoryInstanceAdmin',
  'TrajectoryTypeAdmin',
  'TrajectoryPlannedCommandAdmin',
  'ScanAdmin',
  'ScanUrlAdmin',
  'ScanTypeAdmin',
  'ProjectAdmin',
  'ProjectTypeAdmin',
  'DataTransformerPreset',
  'DashboardUrlAdmin',
  'AdminCredit',
  'AdminCompany',
  'Service',
  'ScanSubmission',
  'DataReport',
  'Dashboard',
  'Scans',
  'Scan',
  'Ping',
  'Logo',
  'Company',
  'AdminEmail',
  'CustomerAdmin',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      userUpdateUser: build.mutation<UserUpdateUserApiResponse, UserUpdateUserApiArg>({
        query: (queryArg) => ({ url: `/user`, method: 'PUT', body: queryArg.updateUserDetailDto }),
        invalidatesTags: ['User'],
      }),
      trajectoryGetProjectParticipantsToAddTrajectory: build.query<
        TrajectoryGetProjectParticipantsToAddTrajectoryApiResponse,
        TrajectoryGetProjectParticipantsToAddTrajectoryApiArg
      >({
        query: (queryArg) => ({ url: `/trajectories/${queryArg.instanceId}/participants/import` }),
        providesTags: ['Trajectory'],
      }),
      trajectoryAddProjectParticipantsToTrajectory: build.mutation<
        TrajectoryAddProjectParticipantsToTrajectoryApiResponse,
        TrajectoryAddProjectParticipantsToTrajectoryApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/import`,
          method: 'PUT',
          params: { participantIds: queryArg.participantIds },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      projectUpdateProjectDetailsByPublicId: build.mutation<
        ProjectUpdateProjectDetailsByPublicIdApiResponse,
        ProjectUpdateProjectDetailsByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectPublicId}`,
          method: 'PUT',
          body: queryArg.updateProjectDetailsDto,
        }),
        invalidatesTags: ['Project', 'Projects'],
      }),
      projectUpdateProjectOrganizationalUnitsByPublicId: build.mutation<
        ProjectUpdateProjectOrganizationalUnitsByPublicIdApiResponse,
        ProjectUpdateProjectOrganizationalUnitsByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectPublicId}/organizational_units`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['Project', 'Projects'],
      }),
      customerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKey: build.query<
        CustomerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKeyApiResponse,
        CustomerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/external/organizationalStructure`,
          params: { anonymousAccessKey: queryArg.anonymousAccessKey },
        }),
        providesTags: ['CustomerOrganizationalStructure'],
      }),
      customerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKey:
        build.mutation<
          CustomerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKeyApiResponse,
          CustomerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKeyApiArg
        >({
          query: (queryArg) => ({
            url: `/external/organizationalStructure`,
            method: 'PUT',
            body: queryArg.body,
            params: {
              anonymousAccessKey: queryArg.anonymousAccessKey,
              completed: queryArg.completed,
            },
          }),
          invalidatesTags: ['CustomerOrganizationalStructure'],
        }),
      customerParticipantUpdateParticipant: build.mutation<
        CustomerParticipantUpdateParticipantApiResponse,
        CustomerParticipantUpdateParticipantApiArg
      >({
        query: (queryArg) => ({ url: `/customers`, method: 'PUT', body: queryArg.participantDto }),
        invalidatesTags: ['CustomerParticipant'],
      }),
      customerParticipantCreateParticipant: build.mutation<
        CustomerParticipantCreateParticipantApiResponse,
        CustomerParticipantCreateParticipantApiArg
      >({
        query: (queryArg) => ({ url: `/customers`, method: 'POST', body: queryArg.participantDto }),
        invalidatesTags: ['CustomerParticipant'],
      }),
      customerParticipantGetAllParticipantsByCustomerPublicId: build.query<
        CustomerParticipantGetAllParticipantsByCustomerPublicIdApiResponse,
        CustomerParticipantGetAllParticipantsByCustomerPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.customerPublicId}/participants` }),
        providesTags: ['CustomerParticipant'],
      }),
      customerParticipantAddParticipantsByPublicId: build.mutation<
        CustomerParticipantAddParticipantsByPublicIdApiResponse,
        CustomerParticipantAddParticipantsByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/${queryArg.customerPublicId}/participants`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['CustomerParticipant'],
      }),
      customerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicId: build.query<
        CustomerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicIdApiResponse,
        CustomerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/${queryArg.customerPublicId}/organizationalStructure`,
        }),
        providesTags: ['CustomerOrganizationalStructure'],
      }),
      customerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicId: build.mutation<
        CustomerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicIdApiResponse,
        CustomerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/${queryArg.customerPublicId}/organizationalStructure`,
          method: 'PUT',
          body: queryArg.body,
          params: { completed: queryArg.completed },
        }),
        invalidatesTags: ['CustomerOrganizationalStructure'],
      }),
      customerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicId:
        build.mutation<
          CustomerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicIdApiResponse,
          CustomerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicIdApiArg
        >({
          query: (queryArg) => ({
            url: `/customers/${queryArg.customerPublicId}/organizationalStructure`,
            method: 'DELETE',
          }),
          invalidatesTags: ['CustomerOrganizationalStructure'],
        }),
      customerSaveBillingInformationForCustomerByPublicId: build.mutation<
        CustomerSaveBillingInformationForCustomerByPublicIdApiResponse,
        CustomerSaveBillingInformationForCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/registration/${queryArg.publicCustomerId}/billingInformation`,
          method: 'PUT',
          body: queryArg.saveBillingInformationRequest,
        }),
        invalidatesTags: ['Customer'],
      }),
      customerUpdateBasicCustomerInformationByPublicId: build.mutation<
        CustomerUpdateBasicCustomerInformationByPublicIdApiResponse,
        CustomerUpdateBasicCustomerInformationByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/registration/${queryArg.publicCustomerId}/basicInformation`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['Customer'],
      }),
      customerRepresentativeUpdateCustomerRepresentative: build.mutation<
        CustomerRepresentativeUpdateCustomerRepresentativeApiResponse,
        CustomerRepresentativeUpdateCustomerRepresentativeApiArg
      >({
        query: (queryArg) => ({
          url: `/customerRepresentatives`,
          method: 'PUT',
          body: queryArg.saveCustomerRepresentativeRequest,
        }),
        invalidatesTags: ['CustomerRepresentative', 'Customer representative registration'],
      }),
      customerRepresentativeAddCustomerRepresentative: build.mutation<
        CustomerRepresentativeAddCustomerRepresentativeApiResponse,
        CustomerRepresentativeAddCustomerRepresentativeApiArg
      >({
        query: (queryArg) => ({
          url: `/customerRepresentatives`,
          method: 'POST',
          body: queryArg.saveCustomerRepresentativeRequest,
        }),
        invalidatesTags: ['CustomerRepresentative', 'Customer representative registration'],
      }),
      visualizationAdminGetAllVisualizations: build.query<
        VisualizationAdminGetAllVisualizationsApiResponse,
        VisualizationAdminGetAllVisualizationsApiArg
      >({
        query: () => ({ url: `/admin/visualizations` }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminUpdateVisualization: build.mutation<
        VisualizationAdminUpdateVisualizationApiResponse,
        VisualizationAdminUpdateVisualizationApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations`,
          method: 'PUT',
          body: queryArg.visualizationDto,
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminCreateVisualization: build.mutation<
        VisualizationAdminCreateVisualizationApiResponse,
        VisualizationAdminCreateVisualizationApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations`,
          method: 'POST',
          body: queryArg.visualizationDto,
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminSetVisualizationDatasetDefault: build.mutation<
        VisualizationAdminSetVisualizationDatasetDefaultApiResponse,
        VisualizationAdminSetVisualizationDatasetDefaultApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datasets/default/${queryArg.name}`,
          method: 'PUT',
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminUpdateVisualizationDataReport: build.mutation<
        VisualizationAdminUpdateVisualizationDataReportApiResponse,
        VisualizationAdminUpdateVisualizationDataReportApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datareports/${queryArg.dataReportName}`,
          method: 'PUT',
          body: queryArg.dataReportRequestDto,
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      adminUserGetAll: build.query<AdminUserGetAllApiResponse, AdminUserGetAllApiArg>({
        query: () => ({ url: `/admin/users` }),
        providesTags: ['AdminUser'],
      }),
      adminUserUpdateUser: build.mutation<
        AdminUserUpdateUserApiResponse,
        AdminUserUpdateUserApiArg
      >({
        query: (queryArg) => ({ url: `/admin/users`, method: 'PUT', body: queryArg.updateUserDto }),
        invalidatesTags: ['AdminUser'],
      }),
      adminUserSaveUser: build.mutation<AdminUserSaveUserApiResponse, AdminUserSaveUserApiArg>({
        query: (queryArg) => ({
          url: `/admin/users`,
          method: 'POST',
          body: queryArg.createUserDto,
        }),
        invalidatesTags: ['AdminUser'],
      }),
      adminUserImportUsers: build.mutation<
        AdminUserImportUsersApiResponse,
        AdminUserImportUsersApiArg
      >({
        query: (queryArg) => ({ url: `/admin/users/import`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['AdminUser'],
      }),
      trajectoryInstanceAdminGetAllTrajectoryInstances: build.query<
        TrajectoryInstanceAdminGetAllTrajectoryInstancesApiResponse,
        TrajectoryInstanceAdminGetAllTrajectoryInstancesApiArg
      >({
        query: () => ({ url: `/admin/trajectories` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminUpdateTrajectoryTypeInstance: build.mutation<
        TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceApiResponse,
        TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories`,
          method: 'PUT',
          body: queryArg.trajectoryTypeInstanceDto,
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminCreateTrajectory: build.mutation<
        TrajectoryInstanceAdminCreateTrajectoryApiResponse,
        TrajectoryInstanceAdminCreateTrajectoryApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories`,
          method: 'POST',
          params: { id: queryArg.id },
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetProjectParticipantsToAddTrajectory: build.query<
        TrajectoryInstanceAdminGetProjectParticipantsToAddTrajectoryApiResponse,
        TrajectoryInstanceAdminGetProjectParticipantsToAddTrajectoryApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/import`,
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminAddProjectParticipantsToTrajectory: build.mutation<
        TrajectoryInstanceAdminAddProjectParticipantsToTrajectoryApiResponse,
        TrajectoryInstanceAdminAddProjectParticipantsToTrajectoryApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/import`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryTypeAdminGetAllTrajectoryTypes: build.query<
        TrajectoryTypeAdminGetAllTrajectoryTypesApiResponse,
        TrajectoryTypeAdminGetAllTrajectoryTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types`,
          params: { tags: queryArg.tags },
        }),
        providesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryTypeAdminUpdateTrajectoryType: build.mutation<
        TrajectoryTypeAdminUpdateTrajectoryTypeApiResponse,
        TrajectoryTypeAdminUpdateTrajectoryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types`,
          method: 'PUT',
          body: queryArg.trajectoryTypeDto,
        }),
        invalidatesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryTypeAdminCreateTrajectoryType: build.mutation<
        TrajectoryTypeAdminCreateTrajectoryTypeApiResponse,
        TrajectoryTypeAdminCreateTrajectoryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types`,
          method: 'POST',
          body: queryArg.trajectoryTypeDto,
        }),
        invalidatesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryPlannedCommandAdminGettrajectoryPlannedCommand: build.query<
        TrajectoryPlannedCommandAdminGettrajectoryPlannedCommandApiResponse,
        TrajectoryPlannedCommandAdminGettrajectoryPlannedCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/plannedCommands/${queryArg.plannedCommandId}`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      trajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommand: build.mutation<
        TrajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommandApiResponse,
        TrajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/plannedCommands/${queryArg.plannedCommandId}`,
          method: 'PUT',
          body: queryArg.trajectoryPlannedCommandDto,
        }),
        invalidatesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      trajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommand: build.mutation<
        TrajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommandApiResponse,
        TrajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/plannedCommands/${queryArg.plannedCommandId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      trajectoryPlannedCommandAdminRetryCommand: build.mutation<
        TrajectoryPlannedCommandAdminRetryCommandApiResponse,
        TrajectoryPlannedCommandAdminRetryCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/plannedCommands/${queryArg.plannedCommandId}/retry`,
          method: 'PUT',
        }),
        invalidatesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      trajectoryPlannedCommandAdminCancelCommand: build.mutation<
        TrajectoryPlannedCommandAdminCancelCommandApiResponse,
        TrajectoryPlannedCommandAdminCancelCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/plannedCommands/${queryArg.plannedCommandId}/cancel`,
          method: 'PUT',
        }),
        invalidatesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      scanAdminGetScan: build.query<ScanAdminGetScanApiResponse, ScanAdminGetScanApiArg>({
        query: (queryArg) => ({ url: `/admin/scans/${queryArg.scanId}` }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminUpdateScan: build.mutation<
        ScanAdminUpdateScanApiResponse,
        ScanAdminUpdateScanApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}`,
          method: 'PUT',
          body: queryArg.scanDto,
        }),
        invalidatesTags: ['ScanAdmin'],
      }),
      scanAdminDeleteScan: build.mutation<
        ScanAdminDeleteScanApiResponse,
        ScanAdminDeleteScanApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/${queryArg.scanId}`, method: 'DELETE' }),
        invalidatesTags: ['ScanAdmin'],
      }),
      scanUrlAdminGetScanUrl: build.query<
        ScanUrlAdminGetScanUrlApiResponse,
        ScanUrlAdminGetScanUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/urls/${queryArg.scanUrlId}`,
        }),
        providesTags: ['ScanUrlAdmin'],
      }),
      scanUrlAdminUpdateScanUrl: build.mutation<
        ScanUrlAdminUpdateScanUrlApiResponse,
        ScanUrlAdminUpdateScanUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/urls/${queryArg.scanUrlId}`,
          method: 'PUT',
          body: queryArg.updateScanUrlDto,
        }),
        invalidatesTags: ['ScanUrlAdmin'],
      }),
      scanUrlAdminDeleteScanUrl: build.mutation<
        ScanUrlAdminDeleteScanUrlApiResponse,
        ScanUrlAdminDeleteScanUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/urls/${queryArg.scanUrlId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ScanUrlAdmin'],
      }),
      scanAdminSyncScanFromType: build.mutation<
        ScanAdminSyncScanFromTypeApiResponse,
        ScanAdminSyncScanFromTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/syncFromType`,
          method: 'PUT',
        }),
        invalidatesTags: ['ScanAdmin'],
      }),
      scanTypeAdminGetAllScanTypes: build.query<
        ScanTypeAdminGetAllScanTypesApiResponse,
        ScanTypeAdminGetAllScanTypesApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/types`, params: { tags: queryArg.tags } }),
        providesTags: ['ScanTypeAdmin'],
      }),
      scanTypeAdminUpdateScanType: build.mutation<
        ScanTypeAdminUpdateScanTypeApiResponse,
        ScanTypeAdminUpdateScanTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/types`,
          method: 'PUT',
          body: queryArg.scanTypeDto,
        }),
        invalidatesTags: ['ScanTypeAdmin'],
      }),
      scanTypeAdminCreateScanType: build.mutation<
        ScanTypeAdminCreateScanTypeApiResponse,
        ScanTypeAdminCreateScanTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/types`,
          method: 'POST',
          body: queryArg.scanTypeDto,
        }),
        invalidatesTags: ['ScanTypeAdmin'],
      }),
      scanAdminGetScanSubmission: build.query<
        ScanAdminGetScanSubmissionApiResponse,
        ScanAdminGetScanSubmissionApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/submissions/${queryArg.submissionId}` }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminUpdateScanSubmission: build.mutation<
        ScanAdminUpdateScanSubmissionApiResponse,
        ScanAdminUpdateScanSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/submissions/${queryArg.submissionId}`,
          method: 'PUT',
          body: queryArg.scanSubmissionDto,
        }),
        invalidatesTags: ['ScanAdmin'],
      }),
      scanAdminDeleteScanSubmission: build.mutation<
        ScanAdminDeleteScanSubmissionApiResponse,
        ScanAdminDeleteScanSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/submissions/${queryArg.submissionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ScanAdmin'],
      }),
      projectAdminGetProject: build.query<
        ProjectAdminGetProjectApiResponse,
        ProjectAdminGetProjectApiArg
      >({
        query: (queryArg) => ({ url: `/admin/projects/${queryArg.projectId}` }),
        providesTags: ['ProjectAdmin'],
      }),
      projectAdminUpdateProject: build.mutation<
        ProjectAdminUpdateProjectApiResponse,
        ProjectAdminUpdateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/${queryArg.projectId}`,
          method: 'PUT',
          body: queryArg.basicProjectInformationDto,
        }),
        invalidatesTags: ['ProjectAdmin'],
      }),
      projectAdminDeleteProject: build.mutation<
        ProjectAdminDeleteProjectApiResponse,
        ProjectAdminDeleteProjectApiArg
      >({
        query: (queryArg) => ({ url: `/admin/projects/${queryArg.projectId}`, method: 'DELETE' }),
        invalidatesTags: ['ProjectAdmin'],
      }),
      projectTypeAdminGetProjectType: build.query<
        ProjectTypeAdminGetProjectTypeApiResponse,
        ProjectTypeAdminGetProjectTypeApiArg
      >({
        query: (queryArg) => ({ url: `/admin/projects/types/${queryArg.projectTypeId}` }),
        providesTags: ['ProjectTypeAdmin'],
      }),
      projectTypeAdminUpdateProjectType: build.mutation<
        ProjectTypeAdminUpdateProjectTypeApiResponse,
        ProjectTypeAdminUpdateProjectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/types/${queryArg.projectTypeId}`,
          method: 'PUT',
          body: queryArg.projectTypeDto,
        }),
        invalidatesTags: ['ProjectTypeAdmin'],
      }),
      projectTypeAdminDeleteProjectType: build.mutation<
        ProjectTypeAdminDeleteProjectTypeApiResponse,
        ProjectTypeAdminDeleteProjectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/types/${queryArg.projectTypeId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ProjectTypeAdmin'],
      }),
      dataTransformerPresetGetAll: build.query<
        DataTransformerPresetGetAllApiResponse,
        DataTransformerPresetGetAllApiArg
      >({
        query: () => ({ url: `/admin/datatransformer/presets` }),
        providesTags: ['DataTransformerPreset'],
      }),
      dataTransformerPresetUpdate: build.mutation<
        DataTransformerPresetUpdateApiResponse,
        DataTransformerPresetUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/datatransformer/presets`,
          method: 'PUT',
          body: queryArg.dataTransformerPresetDto,
        }),
        invalidatesTags: ['DataTransformerPreset'],
      }),
      dataTransformerPresetCreate: build.mutation<
        DataTransformerPresetCreateApiResponse,
        DataTransformerPresetCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/datatransformer/presets`,
          method: 'POST',
          body: queryArg.dataTransformerPresetDto,
        }),
        invalidatesTags: ['DataTransformerPreset'],
      }),
      dataTransformerPresetPerformExcelTransformation: build.mutation<
        DataTransformerPresetPerformExcelTransformationApiResponse,
        DataTransformerPresetPerformExcelTransformationApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/datatransformer/presets/key:${queryArg.key}/excel`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['DataTransformerPreset'],
      }),
      dashboardUrlAdminUpsertDashboardUrl: build.mutation<
        DashboardUrlAdminUpsertDashboardUrlApiResponse,
        DashboardUrlAdminUpsertDashboardUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/dashboards/urls/`,
          method: 'PUT',
          body: queryArg.dashboardUrlDto,
        }),
        invalidatesTags: ['DashboardUrlAdmin'],
      }),
      adminCreditUpdateCredits: build.mutation<
        AdminCreditUpdateCreditsApiResponse,
        AdminCreditUpdateCreditsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/credits`,
          method: 'PUT',
          body: queryArg.creditUpdateDto,
        }),
        invalidatesTags: ['AdminCredit'],
      }),
      adminCompanyGetAll: build.query<AdminCompanyGetAllApiResponse, AdminCompanyGetAllApiArg>({
        query: () => ({ url: `/admin/company` }),
        providesTags: ['AdminCompany'],
      }),
      adminCompanyUpdate: build.mutation<AdminCompanyUpdateApiResponse, AdminCompanyUpdateApiArg>({
        query: (queryArg) => ({ url: `/admin/company`, method: 'PUT', body: queryArg.companyDto }),
        invalidatesTags: ['AdminCompany'],
      }),
      adminCompanyCreate: build.mutation<AdminCompanyCreateApiResponse, AdminCompanyCreateApiArg>({
        query: (queryArg) => ({
          url: `/admin/company`,
          method: 'POST',
          body: queryArg.companyCreateDto,
        }),
        invalidatesTags: ['AdminCompany'],
      }),
      trajectoryGetTrajectoryTypeInstanceContentTemplates: build.query<
        TrajectoryGetTrajectoryTypeInstanceContentTemplatesApiResponse,
        TrajectoryGetTrajectoryTypeInstanceContentTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/type/content`,
          params: { locale: queryArg.locale, role: queryArg.role },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryUpdateTrajectoryTypeInstanceContentTemplates: build.mutation<
        TrajectoryUpdateTrajectoryTypeInstanceContentTemplatesApiResponse,
        TrajectoryUpdateTrajectoryTypeInstanceContentTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/type/content`,
          method: 'POST',
          body: queryArg.body,
          params: { role: queryArg.role },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      trajectoryUpdateTrajectoryTypeInstanceContentTemplate: build.mutation<
        TrajectoryUpdateTrajectoryTypeInstanceContentTemplateApiResponse,
        TrajectoryUpdateTrajectoryTypeInstanceContentTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/type/content/${queryArg.contentKey}`,
          method: 'POST',
          body: queryArg.contentTemplateUpdateDto,
          params: { role: queryArg.role },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      trajectoryInvokeTrajectoryParticipantInstanceAction: build.mutation<
        TrajectoryInvokeTrajectoryParticipantInstanceActionApiResponse,
        TrajectoryInvokeTrajectoryParticipantInstanceActionApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      trajectoryInvokeTrajectoryInstanceBulkAction: build.mutation<
        TrajectoryInvokeTrajectoryInstanceBulkActionApiResponse,
        TrajectoryInvokeTrajectoryInstanceBulkActionApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/bulkactions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.bulkActionBodyDto,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      trajectoryInvokeTrajectoryInstanceAction: build.mutation<
        TrajectoryInvokeTrajectoryInstanceActionApiResponse,
        TrajectoryInvokeTrajectoryInstanceActionApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['Trajectory'],
      }),
      serviceTokenIssuance: build.mutation<
        ServiceTokenIssuanceApiResponse,
        ServiceTokenIssuanceApiArg
      >({
        query: (queryArg) => ({
          url: `/service/tokenIssuance`,
          method: 'POST',
          body: queryArg.signUpOrSignInRequestDto,
        }),
        invalidatesTags: ['Service'],
      }),
      projectSaveBasicProjectInformation: build.mutation<
        ProjectSaveBasicProjectInformationApiResponse,
        ProjectSaveBasicProjectInformationApiArg
      >({
        query: (queryArg) => ({
          url: `/projects`,
          method: 'POST',
          body: queryArg.basicProjectInformationDto,
        }),
        invalidatesTags: ['Project', 'Projects'],
      }),
      scanSubmissionSubmitSurvey: build.mutation<
        ScanSubmissionSubmitSurveyApiResponse,
        ScanSubmissionSubmitSurveyApiArg
      >({
        query: (queryArg) => ({
          url: `/external/survey/${queryArg.scanUrlId}/submit`,
          method: 'POST',
          body: queryArg.submitScanSubmissionDto,
          params: { overridePartialSubmissionMismatch: queryArg.overridePartialSubmissionMismatch },
        }),
        invalidatesTags: ['ScanSubmission'],
      }),
      scanSubmissionProgressSurvey: build.mutation<
        ScanSubmissionProgressSurveyApiResponse,
        ScanSubmissionProgressSurveyApiArg
      >({
        query: (queryArg) => ({
          url: `/external/survey/${queryArg.scanUrlId}/progress`,
          method: 'POST',
          body: queryArg.submitScanSubmissionDto,
          params: { overridePartialSubmissionMismatch: queryArg.overridePartialSubmissionMismatch },
        }),
        invalidatesTags: ['ScanSubmission'],
      }),
      dataReportGetDataReport: build.mutation<
        DataReportGetDataReportApiResponse,
        DataReportGetDataReportApiArg
      >({
        query: (queryArg) => ({
          url: `/datareports`,
          method: 'POST',
          body: queryArg.body,
          params: { iterations: queryArg.iterations },
        }),
        invalidatesTags: ['DataReport'],
      }),
      dataReportGetIterations: build.mutation<
        DataReportGetIterationsApiResponse,
        DataReportGetIterationsApiArg
      >({
        query: (queryArg) => ({
          url: `/datareports/iterations`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['DataReport'],
      }),
      dashboardInvokeDashboardAction: build.mutation<
        DashboardInvokeDashboardActionApiResponse,
        DashboardInvokeDashboardActionApiArg
      >({
        query: (queryArg) => ({
          url: `/dashboards/${queryArg.urlId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['Dashboard'],
      }),
      customerSaveBasicCustomerInformation: build.mutation<
        CustomerSaveBasicCustomerInformationApiResponse,
        CustomerSaveBasicCustomerInformationApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/registration/companyId:${queryArg.companyPublicId}/basicInformation`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Customer'],
      }),
      visualizationAdminUploadVisualisationDataset: build.mutation<
        VisualizationAdminUploadVisualisationDatasetApiResponse,
        VisualizationAdminUploadVisualisationDatasetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datasets`,
          method: 'POST',
          body: queryArg.body,
          params: { isDefault: queryArg.isDefault },
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGenerateVisualizations: build.mutation<
        VisualizationAdminGenerateVisualizationsApiResponse,
        VisualizationAdminGenerateVisualizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/visualizations`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminCloneVisualization: build.mutation<
        VisualizationAdminCloneVisualizationApiResponse,
        VisualizationAdminCloneVisualizationApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/clone/${queryArg.visualizationId}`,
          method: 'POST',
          body: queryArg.cloneVisualizationDto,
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      adminUserResendOnboardingMail: build.mutation<
        AdminUserResendOnboardingMailApiResponse,
        AdminUserResendOnboardingMailApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/users/${queryArg.id}/resend-onboarding-email`,
          method: 'POST',
        }),
        invalidatesTags: ['AdminUser'],
      }),
      trajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplate: build.mutation<
        TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplateApiResponse,
        TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/type/content/${queryArg.contentKey}`,
          method: 'POST',
          body: queryArg.contentTemplateUpdateDto,
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminInvokeTrajectoryTypeInstanceAction: build.mutation<
        TrajectoryInstanceAdminInvokeTrajectoryTypeInstanceActionApiResponse,
        TrajectoryInstanceAdminInvokeTrajectoryTypeInstanceActionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/type/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminInvokeTrajectoryParticipantInstanceAction: build.mutation<
        TrajectoryInstanceAdminInvokeTrajectoryParticipantInstanceActionApiResponse,
        TrajectoryInstanceAdminInvokeTrajectoryParticipantInstanceActionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminInvokeTrajectoryInstanceBulkAction: build.mutation<
        TrajectoryInstanceAdminInvokeTrajectoryInstanceBulkActionApiResponse,
        TrajectoryInstanceAdminInvokeTrajectoryInstanceBulkActionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/bulkactions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.bulkActionBodyDto,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminInvokeTrajectoryInstanceAction: build.mutation<
        TrajectoryInstanceAdminInvokeTrajectoryInstanceActionApiResponse,
        TrajectoryInstanceAdminInvokeTrajectoryInstanceActionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryTypeAdminInvokeTrajectoryAction: build.mutation<
        TrajectoryTypeAdminInvokeTrajectoryActionApiResponse,
        TrajectoryTypeAdminInvokeTrajectoryActionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types/${queryArg.typeId}/actions/${queryArg.actionName}/invoke`,
          method: 'POST',
          body: queryArg.body,
          params: { locale: queryArg.locale },
        }),
        invalidatesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryTypeAdminEvaluateExpression: build.mutation<
        TrajectoryTypeAdminEvaluateExpressionApiResponse,
        TrajectoryTypeAdminEvaluateExpressionApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types/evaluate-expression`,
          method: 'POST',
          body: queryArg.dynamicObjectValueDto,
          params: {
            typeId: queryArg.typeId,
            instanceId: queryArg.instanceId,
            participantInstanceId: queryArg.participantInstanceId,
          },
        }),
        invalidatesTags: ['TrajectoryTypeAdmin'],
      }),
      scanAdminGetAllScans: build.query<
        ScanAdminGetAllScansApiResponse,
        ScanAdminGetAllScansApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans`,
          params: {
            tags: queryArg.tags,
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminCreateScan: build.mutation<
        ScanAdminCreateScanApiResponse,
        ScanAdminCreateScanApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans`,
          method: 'POST',
          body: queryArg.createScanDto,
        }),
        invalidatesTags: ['ScanAdmin'],
      }),
      scanUrlAdminGetAllScanUrlsForScan: build.query<
        ScanUrlAdminGetAllScanUrlsForScanApiResponse,
        ScanUrlAdminGetAllScanUrlsForScanApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/${queryArg.scanId}/urls/` }),
        providesTags: ['ScanUrlAdmin'],
      }),
      scanUrlAdminCreateScanUrl: build.mutation<
        ScanUrlAdminCreateScanUrlApiResponse,
        ScanUrlAdminCreateScanUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/urls/`,
          method: 'POST',
          body: queryArg.createScanUrlDto,
        }),
        invalidatesTags: ['ScanUrlAdmin'],
      }),
      projectAdminGetAllProjects: build.query<
        ProjectAdminGetAllProjectsApiResponse,
        ProjectAdminGetAllProjectsApiArg
      >({
        query: () => ({ url: `/admin/projects` }),
        providesTags: ['ProjectAdmin'],
      }),
      projectAdminCreateProject: build.mutation<
        ProjectAdminCreateProjectApiResponse,
        ProjectAdminCreateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects`,
          method: 'POST',
          body: queryArg.basicProjectInformationDto,
        }),
        invalidatesTags: ['ProjectAdmin'],
      }),
      projectTypeAdminGetAllProjectTypes: build.query<
        ProjectTypeAdminGetAllProjectTypesApiResponse,
        ProjectTypeAdminGetAllProjectTypesApiArg
      >({
        query: () => ({ url: `/admin/projects/types` }),
        providesTags: ['ProjectTypeAdmin'],
      }),
      projectTypeAdminCreateProjectType: build.mutation<
        ProjectTypeAdminCreateProjectTypeApiResponse,
        ProjectTypeAdminCreateProjectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/types`,
          method: 'POST',
          body: queryArg.projectTypeDto,
        }),
        invalidatesTags: ['ProjectTypeAdmin'],
      }),
      projectTypeAdminAddCompanyToProjectType: build.mutation<
        ProjectTypeAdminAddCompanyToProjectTypeApiResponse,
        ProjectTypeAdminAddCompanyToProjectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/types/${queryArg.projectTypeId}/companies/${queryArg.companyId}`,
          method: 'POST',
        }),
        invalidatesTags: ['ProjectTypeAdmin'],
      }),
      projectTypeAdminDeleteCompanyFromProjectType: build.mutation<
        ProjectTypeAdminDeleteCompanyFromProjectTypeApiResponse,
        ProjectTypeAdminDeleteCompanyFromProjectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/projects/types/${queryArg.projectTypeId}/companies/${queryArg.companyId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ProjectTypeAdmin'],
      }),
      userGetCurrentUser: build.query<UserGetCurrentUserApiResponse, UserGetCurrentUserApiArg>({
        query: () => ({ url: `/user/details` }),
        providesTags: ['User'],
      }),
      trajectoryGetTrajectoryInstance: build.query<
        TrajectoryGetTrajectoryInstanceApiResponse,
        TrajectoryGetTrajectoryInstanceApiArg
      >({
        query: (queryArg) => ({ url: `/trajectories/${queryArg.instanceId}` }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryInstanceView: build.query<
        TrajectoryGetTrajectoryInstanceViewApiResponse,
        TrajectoryGetTrajectoryInstanceViewApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/view`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryInstanceProjection: build.query<
        TrajectoryGetTrajectoryInstanceProjectionApiResponse,
        TrajectoryGetTrajectoryInstanceProjectionApiArg
      >({
        query: (queryArg) => ({ url: `/trajectories/${queryArg.instanceId}/projection` }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetAllTrajectoryParticipantProjectionsByInstanceId: build.query<
        TrajectoryGetAllTrajectoryParticipantProjectionsByInstanceIdApiResponse,
        TrajectoryGetAllTrajectoryParticipantProjectionsByInstanceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/projection/participants`,
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryParticipantInstanceByInstanceId: build.query<
        TrajectoryGetTrajectoryParticipantInstanceByInstanceIdApiResponse,
        TrajectoryGetTrajectoryParticipantInstanceByInstanceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}`,
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryParticipantInstanceView: build.query<
        TrajectoryGetTrajectoryParticipantInstanceViewApiResponse,
        TrajectoryGetTrajectoryParticipantInstanceViewApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/view`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetParticipantDashboard: build.query<
        TrajectoryGetParticipantDashboardApiResponse,
        TrajectoryGetParticipantDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/dashboard`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryParticipantInstanceActions: build.query<
        TrajectoryGetTrajectoryParticipantInstanceActionsApiResponse,
        TrajectoryGetTrajectoryParticipantInstanceActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/actions`,
          params: { locale: queryArg.locale, role: queryArg.role },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetDashboard: build.query<
        TrajectoryGetDashboardApiResponse,
        TrajectoryGetDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/dashboard`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryInstanceBulkActions: build.query<
        TrajectoryGetTrajectoryInstanceBulkActionsApiResponse,
        TrajectoryGetTrajectoryInstanceBulkActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/bulkactions`,
          params: { locale: queryArg.locale, role: queryArg.role },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryInstanceActions: build.query<
        TrajectoryGetTrajectoryInstanceActionsApiResponse,
        TrajectoryGetTrajectoryInstanceActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/${queryArg.instanceId}/actions`,
          params: { locale: queryArg.locale, role: queryArg.role },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryDownloadAllTrajectoryVisualizationsByPath: build.query<
        TrajectoryDownloadAllTrajectoryVisualizationsByPathApiResponse,
        TrajectoryDownloadAllTrajectoryVisualizationsByPathApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/trajectories/${queryArg.instanceId}/visualizations`,
          params: { visualizationPath: queryArg.visualizationPath },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryDownloadTrajectoryVisualization: build.query<
        TrajectoryDownloadTrajectoryVisualizationApiResponse,
        TrajectoryDownloadTrajectoryVisualizationApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/trajectories/${queryArg.instanceId}/visualizations/${queryArg.visualizationId}/${queryArg.filename}`,
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryDownloadAllTrajectoryParticipantVisualizationsByPath: build.query<
        TrajectoryDownloadAllTrajectoryParticipantVisualizationsByPathApiResponse,
        TrajectoryDownloadAllTrajectoryParticipantVisualizationsByPathApiArg
      >({
        query: (queryArg) => ({
          url: `/trajectories/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/visualizations/`,
          params: { visualizationPath: queryArg.visualizationPath },
        }),
        providesTags: ['Trajectory'],
      }),
      trajectoryGetTrajectoryInstanceByProjectId: build.query<
        TrajectoryGetTrajectoryInstanceByProjectIdApiResponse,
        TrajectoryGetTrajectoryInstanceByProjectIdApiArg
      >({
        query: (queryArg) => ({ url: `/trajectories/projectId:${queryArg.projectPublicId}` }),
        providesTags: ['Trajectory'],
      }),
      scansGetSubmissionOverview: build.query<
        ScansGetSubmissionOverviewApiResponse,
        ScansGetSubmissionOverviewApiArg
      >({
        query: (queryArg) => ({
          url: `/scans/submission-overview`,
          params: { since: queryArg.since },
        }),
        providesTags: ['Scans', 'Scan'],
      }),
      scansGetSubmissionOverviewPerDay: build.query<
        ScansGetSubmissionOverviewPerDayApiResponse,
        ScansGetSubmissionOverviewPerDayApiArg
      >({
        query: (queryArg) => ({
          url: `/scans/submission-overview/days`,
          params: { since: queryArg.since },
        }),
        providesTags: ['Scans', 'Scan'],
      }),
      projectGetProjectById: build.query<
        ProjectGetProjectByIdApiResponse,
        ProjectGetProjectByIdApiArg
      >({
        query: (queryArg) => ({ url: `/projects/${queryArg.projectId}` }),
        providesTags: ['Project', 'Projects'],
      }),
      projectDeleteProject: build.mutation<
        ProjectDeleteProjectApiResponse,
        ProjectDeleteProjectApiArg
      >({
        query: (queryArg) => ({ url: `/projects/${queryArg.projectId}`, method: 'DELETE' }),
        invalidatesTags: ['Project', 'Projects'],
      }),
      pingGetPing: build.query<PingGetPingApiResponse, PingGetPingApiArg>({
        query: () => ({ url: `/ping` }),
        providesTags: ['Ping'],
      }),
      logoRetrieveManualLogo: build.query<
        LogoRetrieveManualLogoApiResponse,
        LogoRetrieveManualLogoApiArg
      >({
        query: (queryArg) => ({ url: `/logo/storage`, params: { filename: queryArg.filename } }),
        providesTags: ['Logo'],
      }),
      scanSubmissionGetSurvey: build.query<
        ScanSubmissionGetSurveyApiResponse,
        ScanSubmissionGetSurveyApiArg
      >({
        query: (queryArg) => ({
          url: `/external/survey/${queryArg.scanUrlId}`,
          params: { machineId: queryArg.machineId },
        }),
        providesTags: ['ScanSubmission'],
      }),
      scanSubmissionValidatePartialSubmissionId: build.query<
        ScanSubmissionValidatePartialSubmissionIdApiResponse,
        ScanSubmissionValidatePartialSubmissionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/external/survey/${queryArg.scanUrlId}/partial/${queryArg.partialSubmissionId}/valid`,
        }),
        providesTags: ['ScanSubmission'],
      }),
      scanSubmissionSurveyUpload: build.query<
        ScanSubmissionSurveyUploadApiResponse,
        ScanSubmissionSurveyUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/external/survey/upload`,
          params: { filename: queryArg.filename },
        }),
        providesTags: ['ScanSubmission'],
      }),
      dashboardGetDashboard: build.query<
        DashboardGetDashboardApiResponse,
        DashboardGetDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/dashboards/${queryArg.urlId}`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['Dashboard'],
      }),
      dashboardGetDashboardTitle: build.query<
        DashboardGetDashboardTitleApiResponse,
        DashboardGetDashboardTitleApiArg
      >({
        query: (queryArg) => ({
          url: `/dashboards/${queryArg.urlId}/title`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['Dashboard'],
      }),
      dashboardGetDashboardAttachmentList: build.query<
        DashboardGetDashboardAttachmentListApiResponse,
        DashboardGetDashboardAttachmentListApiArg
      >({
        query: (queryArg) => ({ url: `/dashboards/${queryArg.urlId}/attachments/${queryArg.key}` }),
        providesTags: ['Dashboard'],
      }),
      dashboardGetDashboardAttachment: build.query<
        DashboardGetDashboardAttachmentApiResponse,
        DashboardGetDashboardAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/dashboards/${queryArg.urlId}/attachments/${queryArg.key}/${queryArg.filename}`,
        }),
        providesTags: ['Dashboard'],
      }),
      dashboardGetMainDashboard: build.query<
        DashboardGetMainDashboardApiResponse,
        DashboardGetMainDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/dashboards/main`,
          params: { userId: queryArg.userId, locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['Dashboard'],
      }),
      customerRepresentativeGetCustomerRepresentativesForCustomerByPublicId: build.query<
        CustomerRepresentativeGetCustomerRepresentativesForCustomerByPublicIdApiResponse,
        CustomerRepresentativeGetCustomerRepresentativesForCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.publicCustomerId}/representatives` }),
        providesTags: ['CustomerRepresentative'],
      }),
      customerGetCustomerByPublicId: build.query<
        CustomerGetCustomerByPublicIdApiResponse,
        CustomerGetCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.customerPublicId}` }),
        providesTags: ['Customer'],
      }),
      customerDeleteCustomerByPublicId: build.mutation<
        CustomerDeleteCustomerByPublicIdApiResponse,
        CustomerDeleteCustomerByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.customerPublicId}`, method: 'DELETE' }),
        invalidatesTags: ['Customer'],
      }),
      customerParticipantDownloadTemplateByPublicId: build.query<
        CustomerParticipantDownloadTemplateByPublicIdApiResponse,
        CustomerParticipantDownloadTemplateByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.customerPublicId}/template` }),
        providesTags: ['CustomerParticipant'],
      }),
      projectGetProjectOverviewByPublicId: build.query<
        ProjectGetProjectOverviewByPublicIdApiResponse,
        ProjectGetProjectOverviewByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/${queryArg.customerPublicId}/projects` }),
        providesTags: ['Project', 'Projects'],
      }),
      customerGetSingleByCompanyPublicId: build.query<
        CustomerGetSingleByCompanyPublicIdApiResponse,
        CustomerGetSingleByCompanyPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/companyId:${queryArg.companyPublicId}/single` }),
        providesTags: ['Customer'],
      }),
      customerGetOverviewByCompanyPublicId: build.query<
        CustomerGetOverviewByCompanyPublicIdApiResponse,
        CustomerGetOverviewByCompanyPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/customers/companyId:${queryArg.companyPublicId}/overview` }),
        providesTags: ['Customer'],
      }),
      projectGetProjectOverviewForCompany: build.query<
        ProjectGetProjectOverviewForCompanyApiResponse,
        ProjectGetProjectOverviewForCompanyApiArg
      >({
        query: () => ({ url: `/company` }),
        providesTags: ['Project', 'Projects'],
      }),
      companyGetByPublicId: build.query<
        CompanyGetByPublicIdApiResponse,
        CompanyGetByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/company/${queryArg.publicId}` }),
        providesTags: ['Company'],
      }),
      companyGetAllUsers: build.query<CompanyGetAllUsersApiResponse, CompanyGetAllUsersApiArg>({
        query: (queryArg) => ({ url: `/company/${queryArg.id}/users` }),
        providesTags: ['Company'],
      }),
      companyGetProjectTypesForCompany: build.query<
        CompanyGetProjectTypesForCompanyApiResponse,
        CompanyGetProjectTypesForCompanyApiArg
      >({
        query: (queryArg) => ({ url: `/company/${queryArg.id}/projectTypes` }),
        providesTags: ['Company'],
      }),
      companyGetAllUsersByPublicId: build.query<
        CompanyGetAllUsersByPublicIdApiResponse,
        CompanyGetAllUsersByPublicIdApiArg
      >({
        query: (queryArg) => ({ url: `/company/publicId:${queryArg.publicId}/users` }),
        providesTags: ['Company'],
      }),
      companyGetProjectTypesForCompanyByPublicId: build.query<
        CompanyGetProjectTypesForCompanyByPublicIdApiResponse,
        CompanyGetProjectTypesForCompanyByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/company/publicId:${queryArg.publicId}/projectTypes`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['Company'],
      }),
      companyGet: build.query<CompanyGetApiResponse, CompanyGetApiArg>({
        query: (queryArg) => ({ url: `/company/id:${queryArg.id}` }),
        providesTags: ['Company'],
      }),
      visualizationAdminGetVisualizationById: build.query<
        VisualizationAdminGetVisualizationByIdApiResponse,
        VisualizationAdminGetVisualizationByIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/visualizations/${queryArg.visualizationId}` }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminDeleteVisualization: build.mutation<
        VisualizationAdminDeleteVisualizationApiResponse,
        VisualizationAdminDeleteVisualizationApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGetRows: build.query<
        VisualizationAdminGetRowsApiResponse,
        VisualizationAdminGetRowsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/rows`,
          params: {
            dataReporterConfiguration: queryArg.dataReporterConfiguration,
            dataInputType: queryArg.dataInputType,
            dataInputFile: queryArg.dataInputFile,
            scanId: queryArg.scanId,
            transformerName: queryArg.transformerName,
          },
        }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGetIterations: build.query<
        VisualizationAdminGetIterationsApiResponse,
        VisualizationAdminGetIterationsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/iterations`,
          params: {
            dataReporterConfiguration: queryArg.dataReporterConfiguration,
            dataInputType: queryArg.dataInputType,
            dataInputFile: queryArg.dataInputFile,
            scanId: queryArg.scanId,
            transformerName: queryArg.transformerName,
            locale: queryArg.locale,
          },
        }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminDownloadVisualisationDataset: build.query<
        VisualizationAdminDownloadVisualisationDatasetApiResponse,
        VisualizationAdminDownloadVisualisationDatasetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datasets/${queryArg.name}`,
        }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminDeleteVisualisationDataset: build.mutation<
        VisualizationAdminDeleteVisualisationDatasetApiResponse,
        VisualizationAdminDeleteVisualisationDatasetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datasets/${queryArg.name}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGetDataReports: build.query<
        VisualizationAdminGetDataReportsApiResponse,
        VisualizationAdminGetDataReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visualizations/${queryArg.visualizationId}/datareports`,
          params: {
            iterations: queryArg.iterations,
            dataReporterConfiguration: queryArg.dataReporterConfiguration,
            dataInputType: queryArg.dataInputType,
            dataInputFile: queryArg.dataInputFile,
            scanId: queryArg.scanId,
            transformerName: queryArg.transformerName,
            filters: queryArg.filters,
            locale: queryArg.locale,
          },
        }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGetAllVisualizationsForExportPage: build.query<
        VisualizationAdminGetAllVisualizationsForExportPageApiResponse,
        VisualizationAdminGetAllVisualizationsForExportPageApiArg
      >({
        query: () => ({ url: `/admin/visualizations/export-page` }),
        providesTags: ['VisualizationAdmin'],
      }),
      visualizationAdminGetAllCreationalTemplateVisualizations: build.query<
        VisualizationAdminGetAllCreationalTemplateVisualizationsApiResponse,
        VisualizationAdminGetAllCreationalTemplateVisualizationsApiArg
      >({
        query: () => ({ url: `/admin/visualizations/creational-templates` }),
        providesTags: ['VisualizationAdmin'],
      }),
      adminUserGetById: build.query<AdminUserGetByIdApiResponse, AdminUserGetByIdApiArg>({
        query: (queryArg) => ({ url: `/admin/users/${queryArg.id}` }),
        providesTags: ['AdminUser'],
      }),
      adminUserGetIdentitiesById: build.query<
        AdminUserGetIdentitiesByIdApiResponse,
        AdminUserGetIdentitiesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/users/${queryArg.id}/identities` }),
        providesTags: ['AdminUser'],
      }),
      adminUserGetDashboardsById: build.query<
        AdminUserGetDashboardsByIdApiResponse,
        AdminUserGetDashboardsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/users/${queryArg.id}/dashboards` }),
        providesTags: ['AdminUser'],
      }),
      adminUserDownloadTemplate: build.query<
        AdminUserDownloadTemplateApiResponse,
        AdminUserDownloadTemplateApiArg
      >({
        query: () => ({ url: `/admin/users/template` }),
        providesTags: ['AdminUser'],
      }),
      trajectoryInstanceAdminGetTrajectoryInstance: build.query<
        TrajectoryInstanceAdminGetTrajectoryInstanceApiResponse,
        TrajectoryInstanceAdminGetTrajectoryInstanceApiArg
      >({
        query: (queryArg) => ({ url: `/admin/trajectories/${queryArg.instanceId}` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminDeleteTrajectoryInstance: build.mutation<
        TrajectoryInstanceAdminDeleteTrajectoryInstanceApiResponse,
        TrajectoryInstanceAdminDeleteTrajectoryInstanceApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryInstanceView: build.query<
        TrajectoryInstanceAdminGetTrajectoryInstanceViewApiResponse,
        TrajectoryInstanceAdminGetTrajectoryInstanceViewApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/view`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryTypeInstance: build.query<
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceApiResponse,
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceApiArg
      >({
        query: (queryArg) => ({ url: `/admin/trajectories/${queryArg.instanceId}/type` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplates: build.query<
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplatesApiResponse,
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/type/content`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetResolvedTemplateValue: build.query<
        TrajectoryInstanceAdminGetResolvedTemplateValueApiResponse,
        TrajectoryInstanceAdminGetResolvedTemplateValueApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/type/content/${queryArg.contentKey}/evaluate`,
          params: {
            locale: queryArg.locale,
            participantInstanceId: queryArg.participantInstanceId,
          },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryTypeInstanceActions: build.query<
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceActionsApiResponse,
        TrajectoryInstanceAdminGetTrajectoryTypeInstanceActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/type/actions`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceId: build.query<
        TrajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceIdApiResponse,
        TrajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}`,
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminDeleteTrajectoryParticipant: build.mutation<
        TrajectoryInstanceAdminDeleteTrajectoryParticipantApiResponse,
        TrajectoryInstanceAdminDeleteTrajectoryParticipantApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetParticipantDashboard: build.query<
        TrajectoryInstanceAdminGetParticipantDashboardApiResponse,
        TrajectoryInstanceAdminGetParticipantDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/dashboard`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryParticipantInstanceActions: build.query<
        TrajectoryInstanceAdminGetTrajectoryParticipantInstanceActionsApiResponse,
        TrajectoryInstanceAdminGetTrajectoryParticipantInstanceActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/${queryArg.participantInstanceId}/actions`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceId: build.query<
        TrajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceIdApiResponse,
        TrajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/participants/projections`,
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetDashboard: build.query<
        TrajectoryInstanceAdminGetDashboardApiResponse,
        TrajectoryInstanceAdminGetDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/dashboard`,
          params: { locale: queryArg.locale, filter: queryArg.filter },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryInstanceBulkActions: build.query<
        TrajectoryInstanceAdminGetTrajectoryInstanceBulkActionsApiResponse,
        TrajectoryInstanceAdminGetTrajectoryInstanceBulkActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/bulkactions`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetAttachmentUrl: build.query<
        TrajectoryInstanceAdminGetAttachmentUrlApiResponse,
        TrajectoryInstanceAdminGetAttachmentUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/attachments/url`,
          params: {
            container: queryArg.container,
            directory: queryArg.directory,
            filename: queryArg.filename,
          },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminDownloadAttachmentBytes: build.query<
        TrajectoryInstanceAdminDownloadAttachmentBytesApiResponse,
        TrajectoryInstanceAdminDownloadAttachmentBytesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/attachments/bytes`,
          params: {
            container: queryArg.container,
            directory: queryArg.directory,
            filename: queryArg.filename,
          },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryInstanceActions: build.query<
        TrajectoryInstanceAdminGetTrajectoryInstanceActionsApiResponse,
        TrajectoryInstanceAdminGetTrajectoryInstanceActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/${queryArg.instanceId}/actions`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryTypeAdminGetTrajectoryType: build.query<
        TrajectoryTypeAdminGetTrajectoryTypeApiResponse,
        TrajectoryTypeAdminGetTrajectoryTypeApiArg
      >({
        query: (queryArg) => ({ url: `/admin/trajectories/types/${queryArg.typeId}` }),
        providesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryTypeAdminDeleteTrajectoryType: build.mutation<
        TrajectoryTypeAdminDeleteTrajectoryTypeApiResponse,
        TrajectoryTypeAdminDeleteTrajectoryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types/${queryArg.typeId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryInstanceAdminGetAllTrajectoryInstancesByTypeId: build.query<
        TrajectoryInstanceAdminGetAllTrajectoryInstancesByTypeIdApiResponse,
        TrajectoryInstanceAdminGetAllTrajectoryInstancesByTypeIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/trajectories/types/${queryArg.typeId}/instances` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryTypeAdminGetTrajectoryActions: build.query<
        TrajectoryTypeAdminGetTrajectoryActionsApiResponse,
        TrajectoryTypeAdminGetTrajectoryActionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/types/${queryArg.typeId}/actions`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryTypeAdminGetTags: build.query<
        TrajectoryTypeAdminGetTagsApiResponse,
        TrajectoryTypeAdminGetTagsApiArg
      >({
        query: () => ({ url: `/admin/trajectories/types/tags` }),
        providesTags: ['TrajectoryTypeAdmin'],
      }),
      trajectoryInstanceAdminGetTags: build.query<
        TrajectoryInstanceAdminGetTagsApiResponse,
        TrajectoryInstanceAdminGetTagsApiArg
      >({
        query: () => ({ url: `/admin/trajectories/tags` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetAllTrajectoryProjections: build.query<
        TrajectoryInstanceAdminGetAllTrajectoryProjectionsApiResponse,
        TrajectoryInstanceAdminGetAllTrajectoryProjectionsApiArg
      >({
        query: () => ({ url: `/admin/trajectories/projections` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetTrajectoryInstanceByProjectId: build.query<
        TrajectoryInstanceAdminGetTrajectoryInstanceByProjectIdApiResponse,
        TrajectoryInstanceAdminGetTrajectoryInstanceByProjectIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/trajectories/projectId:${queryArg.projectPublicId}` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceId: build.query<
        TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceIdApiResponse,
        TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/plannedcommands/${queryArg.trajectoryInstanceId}/plannedCommands`,
          params: { locale: queryArg.locale },
        }),
        providesTags: ['TrajectoryPlannedCommandAdmin'],
      }),
      trajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceId:
        build.query<
          TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceIdApiResponse,
          TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceIdApiArg
        >({
          query: (queryArg) => ({
            url: `/admin/trajectories/plannedcommands/${queryArg.trajectoryInstanceId}/participants/${queryArg.participantInstanceId}/plannedCommands`,
            params: { locale: queryArg.locale },
          }),
          providesTags: ['TrajectoryPlannedCommandAdmin'],
        }),
      trajectoryInstanceAdminGetPlannedCommands: build.query<
        TrajectoryInstanceAdminGetPlannedCommandsApiResponse,
        TrajectoryInstanceAdminGetPlannedCommandsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/planned`,
          params: { state: queryArg.state },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetNameMap: build.query<
        TrajectoryInstanceAdminGetNameMapApiResponse,
        TrajectoryInstanceAdminGetNameMapApiArg
      >({
        query: () => ({ url: `/admin/trajectories/names` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetLatestTrajectoryEvents: build.query<
        TrajectoryInstanceAdminGetLatestTrajectoryEventsApiResponse,
        TrajectoryInstanceAdminGetLatestTrajectoryEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/latest`,
          params: { limit: queryArg.limit },
        }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      trajectoryInstanceAdminGetAllTrajectoryFlows: build.query<
        TrajectoryInstanceAdminGetAllTrajectoryFlowsApiResponse,
        TrajectoryInstanceAdminGetAllTrajectoryFlowsApiArg
      >({
        query: () => ({ url: `/admin/trajectories/flows` }),
        providesTags: ['TrajectoryInstanceAdmin'],
      }),
      scanAdminGetExcelExport: build.query<
        ScanAdminGetExcelExportApiResponse,
        ScanAdminGetExcelExportApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/xlsx`,
          params: { transformationName: queryArg.transformationName },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanUrlAdminGetScanUrlByUrl: build.query<
        ScanUrlAdminGetScanUrlByUrlApiResponse,
        ScanUrlAdminGetScanUrlByUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/urls/url:${queryArg.scanUrl}`,
        }),
        providesTags: ['ScanUrlAdmin'],
      }),
      scanAdminGetScanSubmissions: build.query<
        ScanAdminGetScanSubmissionsApiResponse,
        ScanAdminGetScanSubmissionsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/submissions`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
            includePending: queryArg.includePending,
          },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminGetTransformedData: build.query<
        ScanAdminGetTransformedDataApiResponse,
        ScanAdminGetTransformedDataApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/${queryArg.scanId}/data`,
          params: { transformationName: queryArg.transformationName },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanTypeAdminGetScanType: build.query<
        ScanTypeAdminGetScanTypeApiResponse,
        ScanTypeAdminGetScanTypeApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/types/${queryArg.scanTypeId}` }),
        providesTags: ['ScanTypeAdmin'],
      }),
      scanTypeAdminDeleteScan: build.mutation<
        ScanTypeAdminDeleteScanApiResponse,
        ScanTypeAdminDeleteScanApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/types/${queryArg.scanTypeId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ScanTypeAdmin'],
      }),
      scanTypeAdminGetScanTypeTags: build.query<
        ScanTypeAdminGetScanTypeTagsApiResponse,
        ScanTypeAdminGetScanTypeTagsApiArg
      >({
        query: () => ({ url: `/admin/scans/types/tags` }),
        providesTags: ['ScanTypeAdmin'],
      }),
      scanAdminGetScanTags: build.query<
        ScanAdminGetScanTagsApiResponse,
        ScanAdminGetScanTagsApiArg
      >({
        query: () => ({ url: `/admin/scans/tags` }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminGetSubmissionOverview: build.query<
        ScanAdminGetSubmissionOverviewApiResponse,
        ScanAdminGetSubmissionOverviewApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/submission-overview`,
          params: { since: queryArg.since },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminGetSubmissionOverviewPerDay: build.query<
        ScanAdminGetSubmissionOverviewPerDayApiResponse,
        ScanAdminGetSubmissionOverviewPerDayApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/scans/submission-overview/days`,
          params: { since: queryArg.since },
        }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminDownloadFile: build.query<
        ScanAdminDownloadFileApiResponse,
        ScanAdminDownloadFileApiArg
      >({
        query: (queryArg) => ({ url: `/admin/scans/download`, params: { path: queryArg.path } }),
        providesTags: ['ScanAdmin'],
      }),
      scanAdminGetAssetManagementUrl: build.query<
        ScanAdminGetAssetManagementUrlApiResponse,
        ScanAdminGetAssetManagementUrlApiArg
      >({
        query: () => ({ url: `/admin/scans/asset-management` }),
        providesTags: ['ScanAdmin'],
      }),
      projectTypeAdminGetCompaniesForProjectType: build.query<
        ProjectTypeAdminGetCompaniesForProjectTypeApiResponse,
        ProjectTypeAdminGetCompaniesForProjectTypeApiArg
      >({
        query: (queryArg) => ({ url: `/admin/projects/types/${queryArg.projectTypeId}/companies` }),
        providesTags: ['ProjectTypeAdmin'],
      }),
      projectAdminGetTags: build.query<ProjectAdminGetTagsApiResponse, ProjectAdminGetTagsApiArg>({
        query: () => ({ url: `/admin/projects/tags` }),
        providesTags: ['ProjectAdmin'],
      }),
      adminEmailGetDetailsByMessageId: build.query<
        AdminEmailGetDetailsByMessageIdApiResponse,
        AdminEmailGetDetailsByMessageIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/email/${queryArg.messageId}` }),
        providesTags: ['AdminEmail'],
      }),
      adminEmailGetStatusByMessageId: build.query<
        AdminEmailGetStatusByMessageIdApiResponse,
        AdminEmailGetStatusByMessageIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/email/status/${queryArg.messageId}` }),
        providesTags: ['AdminEmail'],
      }),
      adminEmailGetUndeliveredMailsOfLastMonth: build.query<
        AdminEmailGetUndeliveredMailsOfLastMonthApiResponse,
        AdminEmailGetUndeliveredMailsOfLastMonthApiArg
      >({
        query: () => ({ url: `/admin/email/status/undeliveredLastMonth` }),
        providesTags: ['AdminEmail'],
      }),
      adminEmailGetMailsOfLastMonth: build.query<
        AdminEmailGetMailsOfLastMonthApiResponse,
        AdminEmailGetMailsOfLastMonthApiArg
      >({
        query: () => ({ url: `/admin/email/status/lastMonth` }),
        providesTags: ['AdminEmail'],
      }),
      dataTransformerPresetGetById: build.query<
        DataTransformerPresetGetByIdApiResponse,
        DataTransformerPresetGetByIdApiArg
      >({
        query: (queryArg) => ({ url: `/admin/datatransformer/presets/${queryArg.id}` }),
        providesTags: ['DataTransformerPreset'],
      }),
      dataTransformerPresetDelete: build.mutation<
        DataTransformerPresetDeleteApiResponse,
        DataTransformerPresetDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/datatransformer/presets/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['DataTransformerPreset'],
      }),
      dataTransformerPresetGetByKey: build.query<
        DataTransformerPresetGetByKeyApiResponse,
        DataTransformerPresetGetByKeyApiArg
      >({
        query: (queryArg) => ({ url: `/admin/datatransformer/presets/key:${queryArg.key}` }),
        providesTags: ['DataTransformerPreset'],
      }),
      customerAdminGetCustomer: build.query<
        CustomerAdminGetCustomerApiResponse,
        CustomerAdminGetCustomerApiArg
      >({
        query: (queryArg) => ({ url: `/admin/customers/${queryArg.customerId}` }),
        providesTags: ['CustomerAdmin'],
      }),
      adminCreditGetCredits: build.query<
        AdminCreditGetCreditsApiResponse,
        AdminCreditGetCreditsApiArg
      >({
        query: (queryArg) => ({ url: `/admin/credits/${queryArg.id}` }),
        providesTags: ['AdminCredit'],
      }),
      adminCompanyGet: build.query<AdminCompanyGetApiResponse, AdminCompanyGetApiArg>({
        query: (queryArg) => ({ url: `/admin/company/${queryArg.id}` }),
        providesTags: ['AdminCompany'],
      }),
      adminCompanyDelete: build.mutation<AdminCompanyDeleteApiResponse, AdminCompanyDeleteApiArg>({
        query: (queryArg) => ({ url: `/admin/company/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['AdminCompany'],
      }),
      adminCompanyGetAllUsers: build.query<
        AdminCompanyGetAllUsersApiResponse,
        AdminCompanyGetAllUsersApiArg
      >({
        query: (queryArg) => ({ url: `/admin/company/${queryArg.id}/users` }),
        providesTags: ['AdminCompany'],
      }),
      adminCompanyGetProjectTypesForCompany: build.query<
        AdminCompanyGetProjectTypesForCompanyApiResponse,
        AdminCompanyGetProjectTypesForCompanyApiArg
      >({
        query: (queryArg) => ({ url: `/admin/company/${queryArg.id}/projectTypes` }),
        providesTags: ['AdminCompany'],
      }),
      adminCompanyGetTags: build.query<AdminCompanyGetTagsApiResponse, AdminCompanyGetTagsApiArg>({
        query: () => ({ url: `/admin/company/tags` }),
        providesTags: ['AdminCompany'],
      }),
      customerParticipantDeleteParticipantByPublicId: build.mutation<
        CustomerParticipantDeleteParticipantByPublicIdApiResponse,
        CustomerParticipantDeleteParticipantByPublicIdApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/publicId:${queryArg.publicCustomerId}/participants/${queryArg.participantId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CustomerParticipant'],
      }),
      customerRepresentativeDeleteCustomerRepresentative: build.mutation<
        CustomerRepresentativeDeleteCustomerRepresentativeApiResponse,
        CustomerRepresentativeDeleteCustomerRepresentativeApiArg
      >({
        query: (queryArg) => ({
          url: `/customerRepresentatives/${queryArg.customerRepresentativeId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CustomerRepresentative'],
      }),
      trajectoryInstanceAdminDeletePlannedCommand: build.mutation<
        TrajectoryInstanceAdminDeletePlannedCommandApiResponse,
        TrajectoryInstanceAdminDeletePlannedCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/trajectories/planned/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TrajectoryInstanceAdmin'],
      }),
      dashboardUrlAdminDeleteDashboardUrl: build.mutation<
        DashboardUrlAdminDeleteDashboardUrlApiResponse,
        DashboardUrlAdminDeleteDashboardUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/dashboards/urls/${queryArg.dashboardUrlId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['DashboardUrlAdmin'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as platformBffApiGenerated };
export type UserUpdateUserApiResponse = /** status 200 OK */ UserDto;
export type UserUpdateUserApiArg = {
  updateUserDetailDto: UpdateUserDetailDto;
};
export type TrajectoryGetProjectParticipantsToAddTrajectoryApiResponse =
  /** status 200 OK */ ParticipantDto[];
export type TrajectoryGetProjectParticipantsToAddTrajectoryApiArg = {
  instanceId: string;
};
export type TrajectoryAddProjectParticipantsToTrajectoryApiResponse =
  /** status 200 OK */ undefined;
export type TrajectoryAddProjectParticipantsToTrajectoryApiArg = {
  instanceId: string;
  participantIds?: string[];
};
export type ProjectUpdateProjectDetailsByPublicIdApiResponse =
  /** status 200 When the project has been updated */ BaseProjectDto;
export type ProjectUpdateProjectDetailsByPublicIdApiArg = {
  projectPublicId: string;
  updateProjectDetailsDto: UpdateProjectDetailsDto;
};
export type ProjectUpdateProjectOrganizationalUnitsByPublicIdApiResponse =
  /** status 200 OK */ BaseProjectDto;
export type ProjectUpdateProjectOrganizationalUnitsByPublicIdApiArg = {
  projectPublicId: string;
  body: ProjectOrganizationalUnitDto[];
};
export type CustomerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKeyApiResponse =
  /** status 200 The organizational structure for the access key */ CustomerOrganizationalStructureDto;
export type CustomerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKeyApiArg = {
  anonymousAccessKey: string;
};
export type CustomerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKeyApiResponse =
  /** status 200 The updated organizational structure for the access key */ CustomerOrganizationalStructureDto;
export type CustomerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKeyApiArg =
  {
    anonymousAccessKey: string;
    completed?: boolean;
    body: OrganizationalUnitDto[];
  };
export type CustomerParticipantUpdateParticipantApiResponse =
  /** status 200 When the participant has been updated */ ParticipantDto;
export type CustomerParticipantUpdateParticipantApiArg = {
  participantDto: ParticipantDto;
};
export type CustomerParticipantCreateParticipantApiResponse =
  /** status 200 When the participant has been created */ ParticipantDto;
export type CustomerParticipantCreateParticipantApiArg = {
  participantDto: ParticipantDto;
};
export type CustomerParticipantGetAllParticipantsByCustomerPublicIdApiResponse =
  /** status 200 OK */ ParticipantDto[];
export type CustomerParticipantGetAllParticipantsByCustomerPublicIdApiArg = {
  customerPublicId: string;
};
export type CustomerParticipantAddParticipantsByPublicIdApiResponse =
  /** status 204 When the participants have been added */ ParticipantsSummaryDto;
export type CustomerParticipantAddParticipantsByPublicIdApiArg = {
  customerPublicId: string;
  body: {
    participantList: Blob;
  };
};
export type CustomerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicIdApiResponse =
  /** status 200 The organizational structure for the customer */ CustomerOrganizationalStructureDto;
export type CustomerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicIdApiArg = {
  customerPublicId: string;
};
export type CustomerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicIdApiResponse =
  /** status 200 The saved organizational structure for the customer */ CustomerOrganizationalStructureDto;
export type CustomerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicIdApiArg = {
  customerPublicId: string;
  completed?: boolean;
  body: OrganizationalUnitDto[];
};
export type CustomerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicIdApiResponse =
  /** status 204 When the organizational structure is deleted */ undefined;
export type CustomerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicIdApiArg =
  {
    customerPublicId: string;
  };
export type CustomerSaveBillingInformationForCustomerByPublicIdApiResponse =
  /** status 200 The updated customer */ CustomerDto;
export type CustomerSaveBillingInformationForCustomerByPublicIdApiArg = {
  publicCustomerId: string;
  saveBillingInformationRequest: SaveBillingInformationRequest;
};
export type CustomerUpdateBasicCustomerInformationByPublicIdApiResponse =
  /** status 200 The updated Customer */ CustomerDto;
export type CustomerUpdateBasicCustomerInformationByPublicIdApiArg = {
  publicCustomerId: string;
  body: {
    name: string;
    comments?: string;
    logo?: Blob;
  };
};
export type CustomerRepresentativeUpdateCustomerRepresentativeApiResponse =
  /** status 200 When the customer representative is successfully updated */ CustomerRepresentativeDto;
export type CustomerRepresentativeUpdateCustomerRepresentativeApiArg = {
  saveCustomerRepresentativeRequest: SaveCustomerRepresentativeRequest;
};
export type CustomerRepresentativeAddCustomerRepresentativeApiResponse =
  /** status 200 When the customer representative is successfully created */ CustomerRepresentativeDto;
export type CustomerRepresentativeAddCustomerRepresentativeApiArg = {
  saveCustomerRepresentativeRequest: SaveCustomerRepresentativeRequest;
};
export type VisualizationAdminGetAllVisualizationsApiResponse =
  /** status 200 OK */ VisualizationDto[];
export type VisualizationAdminGetAllVisualizationsApiArg = void;
export type VisualizationAdminUpdateVisualizationApiResponse =
  /** status 200 When the scan has been updated */ VisualizationDto;
export type VisualizationAdminUpdateVisualizationApiArg = {
  visualizationDto: VisualizationDto;
};
export type VisualizationAdminCreateVisualizationApiResponse =
  /** status 200 When the scan has been updated */ VisualizationDto;
export type VisualizationAdminCreateVisualizationApiArg = {
  visualizationDto: VisualizationDto;
};
export type VisualizationAdminSetVisualizationDatasetDefaultApiResponse =
  /** status 200 OK */ VisualizationDto;
export type VisualizationAdminSetVisualizationDatasetDefaultApiArg = {
  visualizationId: number;
  name: string;
};
export type VisualizationAdminUpdateVisualizationDataReportApiResponse =
  /** status 200 When the scan has been updated */ VisualizationDto;
export type VisualizationAdminUpdateVisualizationDataReportApiArg = {
  visualizationId: number;
  dataReportName: string;
  dataReportRequestDto: DataReportRequestDto;
};
export type AdminUserGetAllApiResponse = /** status 200 OK */ UserDto[];
export type AdminUserGetAllApiArg = void;
export type AdminUserUpdateUserApiResponse = /** status 200 OK */ UserDto;
export type AdminUserUpdateUserApiArg = {
  updateUserDto: UpdateUserDto;
};
export type AdminUserSaveUserApiResponse = /** status 200 OK */ UserDto;
export type AdminUserSaveUserApiArg = {
  createUserDto: CreateUserDto;
};
export type AdminUserImportUsersApiResponse =
  /** status 204 When the users have been added */ UserImportSummaryDto;
export type AdminUserImportUsersApiArg = {
  body: {
    userList: Blob;
  };
};
export type TrajectoryInstanceAdminGetAllTrajectoryInstancesApiResponse =
  /** status 200 OK */ TrajectoryInstanceDto[];
export type TrajectoryInstanceAdminGetAllTrajectoryInstancesApiArg = void;
export type TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceApiResponse =
  /** status 200 When the trajectory type has been updated */ TrajectoryTypeInstanceDto;
export type TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceApiArg = {
  trajectoryTypeInstanceDto: TrajectoryTypeInstanceDto;
};
export type TrajectoryInstanceAdminCreateTrajectoryApiResponse =
  /** status 200 When the trajectory instance has been created */ TrajectoryInstanceDto;
export type TrajectoryInstanceAdminCreateTrajectoryApiArg = {
  id: number;
};
export type TrajectoryInstanceAdminGetProjectParticipantsToAddTrajectoryApiResponse =
  /** status 200 OK */ ParticipantDto[];
export type TrajectoryInstanceAdminGetProjectParticipantsToAddTrajectoryApiArg = {
  instanceId: string;
};
export type TrajectoryInstanceAdminAddProjectParticipantsToTrajectoryApiResponse =
  /** status 200 OK */ undefined;
export type TrajectoryInstanceAdminAddProjectParticipantsToTrajectoryApiArg = {
  instanceId: string;
  body: string[];
};
export type TrajectoryTypeAdminGetAllTrajectoryTypesApiResponse =
  /** status 200 OK */ TrajectoryTypeDto[];
export type TrajectoryTypeAdminGetAllTrajectoryTypesApiArg = {
  tags?: string[];
};
export type TrajectoryTypeAdminUpdateTrajectoryTypeApiResponse =
  /** status 200 When the trajectory type has been updated */ TrajectoryTypeDto;
export type TrajectoryTypeAdminUpdateTrajectoryTypeApiArg = {
  trajectoryTypeDto: TrajectoryTypeDto;
};
export type TrajectoryTypeAdminCreateTrajectoryTypeApiResponse =
  /** status 200 When the trajectory type has been created */ TrajectoryTypeDto;
export type TrajectoryTypeAdminCreateTrajectoryTypeApiArg = {
  trajectoryTypeDto: TrajectoryTypeDto;
};
export type TrajectoryPlannedCommandAdminGettrajectoryPlannedCommandApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto;
export type TrajectoryPlannedCommandAdminGettrajectoryPlannedCommandApiArg = {
  plannedCommandId: number;
  locale?: string;
};
export type TrajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommandApiResponse =
  /** status 200 When the trajectory planned command has been updated */ TrajectoryPlannedCommandDto;
export type TrajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommandApiArg = {
  plannedCommandId: number;
  trajectoryPlannedCommandDto: TrajectoryPlannedCommandDto;
};
export type TrajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommandApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommandApiArg = {
  plannedCommandId: number;
};
export type TrajectoryPlannedCommandAdminRetryCommandApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto;
export type TrajectoryPlannedCommandAdminRetryCommandApiArg = {
  plannedCommandId: number;
};
export type TrajectoryPlannedCommandAdminCancelCommandApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto;
export type TrajectoryPlannedCommandAdminCancelCommandApiArg = {
  plannedCommandId: number;
};
export type ScanAdminGetScanApiResponse = /** status 200 OK */ ScanDto;
export type ScanAdminGetScanApiArg = {
  scanId: number;
};
export type ScanAdminUpdateScanApiResponse =
  /** status 200 When the scan has been updated */ ScanDto;
export type ScanAdminUpdateScanApiArg = {
  scanId: number;
  scanDto: ScanDto;
};
export type ScanAdminDeleteScanApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanAdminDeleteScanApiArg = {
  scanId: number;
};
export type ScanUrlAdminGetScanUrlApiResponse = /** status 200 OK */ ScanUrlDto;
export type ScanUrlAdminGetScanUrlApiArg = {
  scanId: number;
  scanUrlId: number;
};
export type ScanUrlAdminUpdateScanUrlApiResponse =
  /** status 200 When the scan has been updated */ ScanUrlDto;
export type ScanUrlAdminUpdateScanUrlApiArg = {
  scanId: number;
  scanUrlId: number;
  updateScanUrlDto: UpdateScanUrlDto;
};
export type ScanUrlAdminDeleteScanUrlApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanUrlAdminDeleteScanUrlApiArg = {
  scanId: number;
  scanUrlId: number;
};
export type ScanAdminSyncScanFromTypeApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanAdminSyncScanFromTypeApiArg = {
  scanId: number;
};
export type ScanTypeAdminGetAllScanTypesApiResponse = /** status 200 OK */ ScanTypeDto[];
export type ScanTypeAdminGetAllScanTypesApiArg = {
  tags?: string[];
};
export type ScanTypeAdminUpdateScanTypeApiResponse =
  /** status 200 When the scan type has been updated */ ScanTypeDto;
export type ScanTypeAdminUpdateScanTypeApiArg = {
  scanTypeDto: ScanTypeDto;
};
export type ScanTypeAdminCreateScanTypeApiResponse =
  /** status 200 When the scan type has been created */ ScanTypeDto;
export type ScanTypeAdminCreateScanTypeApiArg = {
  scanTypeDto: ScanTypeDto;
};
export type ScanAdminGetScanSubmissionApiResponse = /** status 200 OK */ ScanSubmissionDto;
export type ScanAdminGetScanSubmissionApiArg = {
  submissionId: number;
};
export type ScanAdminUpdateScanSubmissionApiResponse = /** status 200 OK */ ScanSubmissionDto;
export type ScanAdminUpdateScanSubmissionApiArg = {
  submissionId: number;
  scanSubmissionDto: ScanSubmissionDto;
};
export type ScanAdminDeleteScanSubmissionApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanAdminDeleteScanSubmissionApiArg = {
  submissionId: number;
};
export type ProjectAdminGetProjectApiResponse = /** status 200 OK */ ProjectDto;
export type ProjectAdminGetProjectApiArg = {
  projectId: number;
};
export type ProjectAdminUpdateProjectApiResponse =
  /** status 200 When the project has been updated */ BaseProjectDto;
export type ProjectAdminUpdateProjectApiArg = {
  projectId: number;
  basicProjectInformationDto: BasicProjectInformationDto;
};
export type ProjectAdminDeleteProjectApiResponse = /** status 200 OK */ SuccessResponse;
export type ProjectAdminDeleteProjectApiArg = {
  projectId: number;
};
export type ProjectTypeAdminGetProjectTypeApiResponse = /** status 200 OK */ ProjectTypeDto;
export type ProjectTypeAdminGetProjectTypeApiArg = {
  projectTypeId: number;
};
export type ProjectTypeAdminUpdateProjectTypeApiResponse =
  /** status 200 When the project type has been updated */ ProjectTypeDto;
export type ProjectTypeAdminUpdateProjectTypeApiArg = {
  projectTypeId: number;
  projectTypeDto: ProjectTypeDto;
};
export type ProjectTypeAdminDeleteProjectTypeApiResponse = /** status 200 OK */ SuccessResponse;
export type ProjectTypeAdminDeleteProjectTypeApiArg = {
  projectTypeId: number;
};
export type DataTransformerPresetGetAllApiResponse =
  /** status 200 OK */ DataTransformerPresetDto[];
export type DataTransformerPresetGetAllApiArg = void;
export type DataTransformerPresetUpdateApiResponse =
  /** status 200 When the preset type has been updated */ DataTransformerPresetDto;
export type DataTransformerPresetUpdateApiArg = {
  dataTransformerPresetDto: DataTransformerPresetDto;
};
export type DataTransformerPresetCreateApiResponse =
  /** status 200 When the preset type has been created */ DataTransformerPresetDto;
export type DataTransformerPresetCreateApiArg = {
  dataTransformerPresetDto: DataTransformerPresetDto;
};
export type DataTransformerPresetPerformExcelTransformationApiResponse = /** status 200 OK */ Blob;
export type DataTransformerPresetPerformExcelTransformationApiArg = {
  key: string;
  body: {
    file: Blob;
  };
};
export type DashboardUrlAdminUpsertDashboardUrlApiResponse =
  /** status 200 When the dashboard has been updated */ DashboardUrlDto;
export type DashboardUrlAdminUpsertDashboardUrlApiArg = {
  dashboardUrlDto: DashboardUrlDto;
};
export type AdminCreditUpdateCreditsApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type AdminCreditUpdateCreditsApiArg = {
  creditUpdateDto: CreditUpdateDto;
};
export type AdminCompanyGetAllApiResponse = /** status 200 OK */ CompanyWithCreditDto[];
export type AdminCompanyGetAllApiArg = void;
export type AdminCompanyUpdateApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type AdminCompanyUpdateApiArg = {
  companyDto: CompanyDto;
};
export type AdminCompanyCreateApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type AdminCompanyCreateApiArg = {
  companyCreateDto: CompanyCreateDto;
};
export type TrajectoryGetTrajectoryTypeInstanceContentTemplatesApiResponse = /** status 200 OK */ {
  [key: string]: ContentTemplateViewDto;
};
export type TrajectoryGetTrajectoryTypeInstanceContentTemplatesApiArg = {
  instanceId: string;
  locale?: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
};
export type TrajectoryUpdateTrajectoryTypeInstanceContentTemplatesApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryUpdateTrajectoryTypeInstanceContentTemplatesApiArg = {
  instanceId: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  body: {
    [key: string]: ContentTemplateUpdateDto;
  };
};
export type TrajectoryUpdateTrajectoryTypeInstanceContentTemplateApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryUpdateTrajectoryTypeInstanceContentTemplateApiArg = {
  instanceId: string;
  contentKey: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  contentTemplateUpdateDto: ContentTemplateUpdateDto;
};
export type TrajectoryInvokeTrajectoryParticipantInstanceActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryInvokeTrajectoryParticipantInstanceActionApiArg = {
  instanceId: string;
  participantInstanceId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type TrajectoryInvokeTrajectoryInstanceBulkActionApiResponse = /** status 200 OK */ {
  [key: string]: CommandExecutionResultDto;
};
export type TrajectoryInvokeTrajectoryInstanceBulkActionApiArg = {
  instanceId: string;
  actionName: string;
  locale?: string;
  bulkActionBodyDto: BulkActionBodyDto;
};
export type TrajectoryInvokeTrajectoryInstanceActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryInvokeTrajectoryInstanceActionApiArg = {
  instanceId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type ServiceTokenIssuanceApiResponse = /** status 200 OK */ SignUpOrSignInResponseDto;
export type ServiceTokenIssuanceApiArg = {
  signUpOrSignInRequestDto: SignUpOrSignInRequestDto;
};
export type ProjectSaveBasicProjectInformationApiResponse =
  /** status 200 When the project has been created */ BaseProjectDto;
export type ProjectSaveBasicProjectInformationApiArg = {
  basicProjectInformationDto: BasicProjectInformationDto;
};
export type ScanSubmissionSubmitSurveyApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanSubmissionSubmitSurveyApiArg = {
  scanUrlId: string;
  overridePartialSubmissionMismatch?: boolean;
  submitScanSubmissionDto: SubmitScanSubmissionDto;
};
export type ScanSubmissionProgressSurveyApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanSubmissionProgressSurveyApiArg = {
  scanUrlId: string;
  overridePartialSubmissionMismatch?: boolean;
  submitScanSubmissionDto: SubmitScanSubmissionDto;
};
export type DataReportGetDataReportApiResponse =
  /** status 200 When the data report has been returned */ DataReportContainerDto;
export type DataReportGetDataReportApiArg = {
  iterations?: string[];
  body: {
    request: DataReportRequestDto;
    data: Blob;
  };
};
export type DataReportGetIterationsApiResponse =
  /** status 200 When the data report has been returned */ string[];
export type DataReportGetIterationsApiArg = {
  body: {
    request: DataReportRequestDto;
    data: Blob;
  };
};
export type DashboardInvokeDashboardActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type DashboardInvokeDashboardActionApiArg = {
  urlId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type CustomerSaveBasicCustomerInformationApiResponse =
  /** status 200 The new Customer with its id to use in the rest of the registration flow */ CustomerDto;
export type CustomerSaveBasicCustomerInformationApiArg = {
  companyPublicId: string;
  body: {
    name: string;
    comments?: string;
    logo?: Blob;
  };
};
export type VisualizationAdminUploadVisualisationDatasetApiResponse =
  /** status 200 OK */ VisualizationDto;
export type VisualizationAdminUploadVisualisationDatasetApiArg = {
  visualizationId: number;
  isDefault?: boolean;
  body: {
    dataset: Blob;
  };
};
export type VisualizationAdminGenerateVisualizationsApiResponse = /** status 200 OK */ Blob;
export type VisualizationAdminGenerateVisualizationsApiArg = {
  locale?: string;
  body: {
    customerLogo?: Blob;
    iterations?: string[];
    filenameExpression?: string;
    surveyResults?: Blob;
    request: VisualizationRequestDto;
  };
};
export type VisualizationAdminCloneVisualizationApiResponse = /** status 200 OK */ VisualizationDto;
export type VisualizationAdminCloneVisualizationApiArg = {
  visualizationId: number;
  cloneVisualizationDto: CloneVisualizationDto;
};
export type AdminUserResendOnboardingMailApiResponse = /** status 200 OK */ SuccessResponse;
export type AdminUserResendOnboardingMailApiArg = {
  id: number;
};
export type TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplateApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplateApiArg = {
  instanceId: string;
  contentKey: string;
  contentTemplateUpdateDto: ContentTemplateUpdateDto;
};
export type TrajectoryInstanceAdminInvokeTrajectoryTypeInstanceActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryInstanceAdminInvokeTrajectoryTypeInstanceActionApiArg = {
  instanceId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type TrajectoryInstanceAdminInvokeTrajectoryParticipantInstanceActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryInstanceAdminInvokeTrajectoryParticipantInstanceActionApiArg = {
  instanceId: string;
  participantInstanceId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type TrajectoryInstanceAdminInvokeTrajectoryInstanceBulkActionApiResponse =
  /** status 200 OK */ {
    [key: string]: CommandExecutionResultDto;
  };
export type TrajectoryInstanceAdminInvokeTrajectoryInstanceBulkActionApiArg = {
  instanceId: string;
  actionName: string;
  locale?: string;
  bulkActionBodyDto: BulkActionBodyDto;
};
export type TrajectoryInstanceAdminInvokeTrajectoryInstanceActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryInstanceAdminInvokeTrajectoryInstanceActionApiArg = {
  instanceId: string;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type TrajectoryTypeAdminInvokeTrajectoryActionApiResponse =
  /** status 200 OK */ CommandExecutionResultDto;
export type TrajectoryTypeAdminInvokeTrajectoryActionApiArg = {
  typeId: number;
  actionName: string;
  locale?: string;
  body: {
    [key: string]: object;
  };
};
export type TrajectoryTypeAdminEvaluateExpressionApiResponse = /** status 200 OK */ object;
export type TrajectoryTypeAdminEvaluateExpressionApiArg = {
  typeId?: number;
  instanceId?: string;
  participantInstanceId?: string;
  dynamicObjectValueDto: DynamicObjectValueDto;
};
export type ScanAdminGetAllScansApiResponse = /** status 200 OK */ PageScanForListDto;
export type ScanAdminGetAllScansApiArg = {
  tags?: string[];
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
  filter?: string;
};
export type ScanAdminCreateScanApiResponse =
  /** status 200 When the scan has been created */ ScanDto;
export type ScanAdminCreateScanApiArg = {
  createScanDto: CreateScanDto;
};
export type ScanUrlAdminGetAllScanUrlsForScanApiResponse = /** status 200 OK */ ScanUrlDto[];
export type ScanUrlAdminGetAllScanUrlsForScanApiArg = {
  scanId: number;
};
export type ScanUrlAdminCreateScanUrlApiResponse =
  /** status 200 When the scan url has been created */ ScanUrlDto;
export type ScanUrlAdminCreateScanUrlApiArg = {
  scanId: number;
  createScanUrlDto: CreateScanUrlDto;
};
export type ProjectAdminGetAllProjectsApiResponse = /** status 200 OK */ OverviewProjectDto[];
export type ProjectAdminGetAllProjectsApiArg = void;
export type ProjectAdminCreateProjectApiResponse =
  /** status 200 When the project has been created */ BaseProjectDto;
export type ProjectAdminCreateProjectApiArg = {
  basicProjectInformationDto: BasicProjectInformationDto;
};
export type ProjectTypeAdminGetAllProjectTypesApiResponse = /** status 200 OK */ ProjectTypeDto[];
export type ProjectTypeAdminGetAllProjectTypesApiArg = void;
export type ProjectTypeAdminCreateProjectTypeApiResponse =
  /** status 200 When the project type has been created */ ProjectTypeDto;
export type ProjectTypeAdminCreateProjectTypeApiArg = {
  projectTypeDto: ProjectTypeDto;
};
export type ProjectTypeAdminAddCompanyToProjectTypeApiResponse =
  /** status 200 When the project-type company releation has been created */ undefined;
export type ProjectTypeAdminAddCompanyToProjectTypeApiArg = {
  projectTypeId: number;
  companyId: number;
};
export type ProjectTypeAdminDeleteCompanyFromProjectTypeApiResponse =
  /** status 200 When the project-type company releation has been removed */ undefined;
export type ProjectTypeAdminDeleteCompanyFromProjectTypeApiArg = {
  projectTypeId: number;
  companyId: number;
};
export type UserGetCurrentUserApiResponse = /** status 200 OK */ UserDto;
export type UserGetCurrentUserApiArg = void;
export type TrajectoryGetTrajectoryInstanceApiResponse = /** status 200 OK */ TrajectoryInstanceDto;
export type TrajectoryGetTrajectoryInstanceApiArg = {
  instanceId: string;
};
export type TrajectoryGetTrajectoryInstanceViewApiResponse =
  /** status 200 OK */ TrajectoryTypeDefinitionViewDto;
export type TrajectoryGetTrajectoryInstanceViewApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryGetTrajectoryInstanceProjectionApiResponse =
  /** status 200 OK */ TrajectoryInstanceProjectionDto;
export type TrajectoryGetTrajectoryInstanceProjectionApiArg = {
  instanceId: string;
};
export type TrajectoryGetAllTrajectoryParticipantProjectionsByInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryParticipantInstanceProjectionForListDto[];
export type TrajectoryGetAllTrajectoryParticipantProjectionsByInstanceIdApiArg = {
  instanceId: string;
};
export type TrajectoryGetTrajectoryParticipantInstanceByInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryParticipantInstanceDto;
export type TrajectoryGetTrajectoryParticipantInstanceByInstanceIdApiArg = {
  instanceId: string;
  participantInstanceId: string;
};
export type TrajectoryGetTrajectoryParticipantInstanceViewApiResponse =
  /** status 200 OK */ TrajectoryParticipantInstanceViewDto;
export type TrajectoryGetTrajectoryParticipantInstanceViewApiArg = {
  instanceId: string;
  participantInstanceId: string;
  locale?: string;
};
export type TrajectoryGetParticipantDashboardApiResponse = /** status 200 OK */ DashboardViewDto;
export type TrajectoryGetParticipantDashboardApiArg = {
  instanceId: string;
  participantInstanceId: string;
  locale?: string;
  filter?: string;
};
export type TrajectoryGetTrajectoryParticipantInstanceActionsApiResponse = /** status 200 OK */ {
  [key: string]: ManualActionViewDto;
};
export type TrajectoryGetTrajectoryParticipantInstanceActionsApiArg = {
  instanceId: string;
  participantInstanceId: string;
  locale?: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
};
export type TrajectoryGetDashboardApiResponse = /** status 200 OK */ DashboardViewDto;
export type TrajectoryGetDashboardApiArg = {
  instanceId: string;
  locale?: string;
  filter?: string;
};
export type TrajectoryGetTrajectoryInstanceBulkActionsApiResponse = /** status 200 OK */ {
  [key: string]: ManualActionBulkViewDto;
};
export type TrajectoryGetTrajectoryInstanceBulkActionsApiArg = {
  instanceId: string;
  locale?: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
};
export type TrajectoryGetTrajectoryInstanceActionsApiResponse = /** status 200 OK */ {
  [key: string]: ManualActionViewDto;
};
export type TrajectoryGetTrajectoryInstanceActionsApiArg = {
  instanceId: string;
  locale?: string;
  role?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
};
export type TrajectoryDownloadAllTrajectoryVisualizationsByPathApiResponse =
  /** status 200 OK */ string;
export type TrajectoryDownloadAllTrajectoryVisualizationsByPathApiArg = {
  instanceId: string;
  visualizationPath: string;
};
export type TrajectoryDownloadTrajectoryVisualizationApiResponse = /** status 200 OK */ string;
export type TrajectoryDownloadTrajectoryVisualizationApiArg = {
  instanceId: string;
  visualizationId: string;
  filename: string;
};
export type TrajectoryDownloadAllTrajectoryParticipantVisualizationsByPathApiResponse =
  /** status 200 OK */ string;
export type TrajectoryDownloadAllTrajectoryParticipantVisualizationsByPathApiArg = {
  instanceId: string;
  participantInstanceId: string;
  visualizationPath: string;
};
export type TrajectoryGetTrajectoryInstanceByProjectIdApiResponse =
  /** status 200 OK */ TrajectoryInstanceDto;
export type TrajectoryGetTrajectoryInstanceByProjectIdApiArg = {
  projectPublicId: string;
};
export type ScansGetSubmissionOverviewApiResponse =
  /** status 200 OK */ ScanSubmissionOverviewDto[];
export type ScansGetSubmissionOverviewApiArg = {
  since?: string;
};
export type ScansGetSubmissionOverviewPerDayApiResponse =
  /** status 200 OK */ ScanSubmissionCountPerDayDto[];
export type ScansGetSubmissionOverviewPerDayApiArg = {
  since?: string;
};
export type ProjectGetProjectByIdApiResponse =
  /** status 200 When the project is found */ DetailedProjectDto;
export type ProjectGetProjectByIdApiArg = {
  projectId: string;
};
export type ProjectDeleteProjectApiResponse =
  /** status 204 When the project has been deleted */ undefined;
export type ProjectDeleteProjectApiArg = {
  projectId: string;
};
export type PingGetPingApiResponse = /** status 200 OK */ SuccessResponse;
export type PingGetPingApiArg = void;
export type LogoRetrieveManualLogoApiResponse = /** status 200 OK */ Blob;
export type LogoRetrieveManualLogoApiArg = {
  filename: string;
};
export type ScanSubmissionGetSurveyApiResponse =
  /** status 200 When all info has been successfully returned */ SurveyViewDto;
export type ScanSubmissionGetSurveyApiArg = {
  scanUrlId: string;
  machineId?: string;
};
export type ScanSubmissionValidatePartialSubmissionIdApiResponse =
  /** status 200 OK */ ValidPartialSubmissionIdResponseDto;
export type ScanSubmissionValidatePartialSubmissionIdApiArg = {
  scanUrlId: string;
  partialSubmissionId: string;
};
export type ScanSubmissionSurveyUploadApiResponse = /** status 200 OK */ SurveyUploadUrlDto;
export type ScanSubmissionSurveyUploadApiArg = {
  filename: string;
};
export type DashboardGetDashboardApiResponse = /** status 200 OK */ DashboardViewDto;
export type DashboardGetDashboardApiArg = {
  urlId: string;
  locale?: string;
  filter?: string;
};
export type DashboardGetDashboardTitleApiResponse = /** status 200 OK */ DashboardTitleViewDto;
export type DashboardGetDashboardTitleApiArg = {
  urlId: string;
  locale?: string;
};
export type DashboardGetDashboardAttachmentListApiResponse =
  /** status 200 OK */ AttachmentListItemDto[];
export type DashboardGetDashboardAttachmentListApiArg = {
  urlId: string;
  key: string;
};
export type DashboardGetDashboardAttachmentApiResponse = /** status 200 OK */ string;
export type DashboardGetDashboardAttachmentApiArg = {
  urlId: string;
  key: string;
  filename: string;
};
export type DashboardGetMainDashboardApiResponse = /** status 200 OK */ DashboardViewDto[];
export type DashboardGetMainDashboardApiArg = {
  userId?: number;
  locale?: string;
  filter?: string;
};
export type CustomerRepresentativeGetCustomerRepresentativesForCustomerByPublicIdApiResponse =
  /** status 200 OK */ CustomerRepresentativeDto[];
export type CustomerRepresentativeGetCustomerRepresentativesForCustomerByPublicIdApiArg = {
  publicCustomerId: string;
};
export type CustomerGetCustomerByPublicIdApiResponse =
  /** status 200 When the customer is found */ CustomerDto;
export type CustomerGetCustomerByPublicIdApiArg = {
  customerPublicId: string;
};
export type CustomerDeleteCustomerByPublicIdApiResponse =
  /** status 204 When the customer has been deleted */ undefined;
export type CustomerDeleteCustomerByPublicIdApiArg = {
  customerPublicId: string;
};
export type CustomerParticipantDownloadTemplateByPublicIdApiResponse = /** status 200 OK */ Blob;
export type CustomerParticipantDownloadTemplateByPublicIdApiArg = {
  customerPublicId: string;
};
export type ProjectGetProjectOverviewByPublicIdApiResponse =
  /** status 200 Returns projects belonging to the customer, or an empty list when the customer has no projects or does not exist */ OverviewProjectDto[];
export type ProjectGetProjectOverviewByPublicIdApiArg = {
  customerPublicId: string;
};
export type CustomerGetSingleByCompanyPublicIdApiResponse =
  /** status 200 List of customers with some information for use in the overview */ OverviewCustomerDto;
export type CustomerGetSingleByCompanyPublicIdApiArg = {
  companyPublicId: string;
};
export type CustomerGetOverviewByCompanyPublicIdApiResponse =
  /** status 200 List of customers with some information for use in the overview */ OverviewCustomerDto[];
export type CustomerGetOverviewByCompanyPublicIdApiArg = {
  companyPublicId: string;
};
export type ProjectGetProjectOverviewForCompanyApiResponse =
  /** status 200 Returns projectsnot exist */ OverviewProjectDto[];
export type ProjectGetProjectOverviewForCompanyApiArg = void;
export type CompanyGetByPublicIdApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type CompanyGetByPublicIdApiArg = {
  publicId: string;
};
export type CompanyGetAllUsersApiResponse = /** status 200 OK */ UserDto[];
export type CompanyGetAllUsersApiArg = {
  id: number;
};
export type CompanyGetProjectTypesForCompanyApiResponse = /** status 200 OK */ ProjectTypeDto[];
export type CompanyGetProjectTypesForCompanyApiArg = {
  id: number;
};
export type CompanyGetAllUsersByPublicIdApiResponse = /** status 200 OK */ UserDto[];
export type CompanyGetAllUsersByPublicIdApiArg = {
  publicId: string;
};
export type CompanyGetProjectTypesForCompanyByPublicIdApiResponse =
  /** status 200 OK */ ProjectTypeWithViewDto[];
export type CompanyGetProjectTypesForCompanyByPublicIdApiArg = {
  publicId: string;
  locale?: string;
  filter?: string;
};
export type CompanyGetApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type CompanyGetApiArg = {
  id: number;
};
export type VisualizationAdminGetVisualizationByIdApiResponse =
  /** status 200 When the visualization is found */ VisualizationDto;
export type VisualizationAdminGetVisualizationByIdApiArg = {
  visualizationId: number;
};
export type VisualizationAdminDeleteVisualizationApiResponse = /** status 200 OK */ SuccessResponse;
export type VisualizationAdminDeleteVisualizationApiArg = {
  visualizationId: number;
};
export type VisualizationAdminGetRowsApiResponse =
  /** status 200 When the visualization is found */ {
    [key: string]: object;
  }[];
export type VisualizationAdminGetRowsApiArg = {
  visualizationId: number;
  dataReporterConfiguration?: string;
  dataInputType?: 'SCAN' | 'FILE';
  dataInputFile?: string;
  scanId?: number;
  transformerName?: string;
};
export type VisualizationAdminGetIterationsApiResponse =
  /** status 200 When the visualization is found */ string[];
export type VisualizationAdminGetIterationsApiArg = {
  visualizationId: number;
  dataReporterConfiguration?: string;
  dataInputType?: 'SCAN' | 'FILE';
  dataInputFile?: string;
  scanId?: number;
  transformerName?: string;
  locale?: string;
};
export type VisualizationAdminDownloadVisualisationDatasetApiResponse = /** status 200 OK */ Blob;
export type VisualizationAdminDownloadVisualisationDatasetApiArg = {
  visualizationId: number;
  name: string;
};
export type VisualizationAdminDeleteVisualisationDatasetApiResponse =
  /** status 200 OK */ VisualizationDto;
export type VisualizationAdminDeleteVisualisationDatasetApiArg = {
  visualizationId: number;
  name: string;
};
export type VisualizationAdminGetDataReportsApiResponse =
  /** status 200 When the visualization is found */ DataReportContainerDto;
export type VisualizationAdminGetDataReportsApiArg = {
  visualizationId: number;
  iterations?: string[];
  dataReporterConfiguration?: string;
  dataInputType?: 'SCAN' | 'FILE';
  dataInputFile?: string;
  scanId?: number;
  transformerName?: string;
  filters?: string;
  locale?: string;
};
export type VisualizationAdminGetAllVisualizationsForExportPageApiResponse =
  /** status 200 OK */ VisualizationDto[];
export type VisualizationAdminGetAllVisualizationsForExportPageApiArg = void;
export type VisualizationAdminGetAllCreationalTemplateVisualizationsApiResponse =
  /** status 200 OK */ VisualizationDto[];
export type VisualizationAdminGetAllCreationalTemplateVisualizationsApiArg = void;
export type AdminUserGetByIdApiResponse = /** status 200 OK */ UserDto;
export type AdminUserGetByIdApiArg = {
  id: number;
};
export type AdminUserGetIdentitiesByIdApiResponse = /** status 200 OK */ AzureB2CUserDto[];
export type AdminUserGetIdentitiesByIdApiArg = {
  id: number;
};
export type AdminUserGetDashboardsByIdApiResponse = /** status 200 OK */ DashboardUrlDto[];
export type AdminUserGetDashboardsByIdApiArg = {
  id: number;
};
export type AdminUserDownloadTemplateApiResponse = /** status 200 OK */ Blob;
export type AdminUserDownloadTemplateApiArg = void;
export type TrajectoryInstanceAdminGetTrajectoryInstanceApiResponse =
  /** status 200 OK */ TrajectoryInstanceDto;
export type TrajectoryInstanceAdminGetTrajectoryInstanceApiArg = {
  instanceId: string;
};
export type TrajectoryInstanceAdminDeleteTrajectoryInstanceApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryInstanceAdminDeleteTrajectoryInstanceApiArg = {
  instanceId: string;
};
export type TrajectoryInstanceAdminGetTrajectoryInstanceViewApiResponse =
  /** status 200 OK */ TrajectoryTypeDefinitionViewDto;
export type TrajectoryInstanceAdminGetTrajectoryInstanceViewApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceApiResponse =
  /** status 200 OK */ TrajectoryTypeInstanceDto;
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceApiArg = {
  instanceId: string;
};
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplatesApiResponse =
  /** status 200 OK */ {
    [key: string]: ContentTemplateViewDto;
  };
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplatesApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryInstanceAdminGetResolvedTemplateValueApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryInstanceAdminGetResolvedTemplateValueApiArg = {
  instanceId: string;
  contentKey: string;
  locale?: string;
  participantInstanceId?: string;
};
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceActionsApiResponse =
  /** status 200 OK */ {
    [key: string]: ManualActionViewDto;
  };
export type TrajectoryInstanceAdminGetTrajectoryTypeInstanceActionsApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryParticipantInstanceDto;
export type TrajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceIdApiArg = {
  instanceId: string;
  participantInstanceId: string;
};
export type TrajectoryInstanceAdminDeleteTrajectoryParticipantApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryInstanceAdminDeleteTrajectoryParticipantApiArg = {
  instanceId: string;
  participantInstanceId: string;
};
export type TrajectoryInstanceAdminGetParticipantDashboardApiResponse =
  /** status 200 OK */ DashboardViewDto;
export type TrajectoryInstanceAdminGetParticipantDashboardApiArg = {
  instanceId: string;
  participantInstanceId: string;
  locale?: string;
  filter?: string;
};
export type TrajectoryInstanceAdminGetTrajectoryParticipantInstanceActionsApiResponse =
  /** status 200 OK */ {
    [key: string]: ManualActionViewDto;
  };
export type TrajectoryInstanceAdminGetTrajectoryParticipantInstanceActionsApiArg = {
  instanceId: string;
  participantInstanceId: string;
  locale?: string;
};
export type TrajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryParticipantInstanceProjectionForListDto[];
export type TrajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceIdApiArg = {
  instanceId: string;
};
export type TrajectoryInstanceAdminGetDashboardApiResponse = /** status 200 OK */ DashboardViewDto;
export type TrajectoryInstanceAdminGetDashboardApiArg = {
  instanceId: string;
  locale?: string;
  filter?: string;
};
export type TrajectoryInstanceAdminGetTrajectoryInstanceBulkActionsApiResponse =
  /** status 200 OK */ {
    [key: string]: ManualActionBulkViewDto;
  };
export type TrajectoryInstanceAdminGetTrajectoryInstanceBulkActionsApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryInstanceAdminGetAttachmentUrlApiResponse =
  /** status 200 OK */ StringResponse;
export type TrajectoryInstanceAdminGetAttachmentUrlApiArg = {
  instanceId: string;
  container: string;
  directory: string;
  filename: string;
};
export type TrajectoryInstanceAdminDownloadAttachmentBytesApiResponse = /** status 200 OK */ Blob;
export type TrajectoryInstanceAdminDownloadAttachmentBytesApiArg = {
  instanceId: string;
  container: string;
  directory: string;
  filename: string;
};
export type TrajectoryInstanceAdminGetTrajectoryInstanceActionsApiResponse = /** status 200 OK */ {
  [key: string]: ManualActionViewDto;
};
export type TrajectoryInstanceAdminGetTrajectoryInstanceActionsApiArg = {
  instanceId: string;
  locale?: string;
};
export type TrajectoryTypeAdminGetTrajectoryTypeApiResponse =
  /** status 200 OK */ TrajectoryTypeDto;
export type TrajectoryTypeAdminGetTrajectoryTypeApiArg = {
  typeId: number;
};
export type TrajectoryTypeAdminDeleteTrajectoryTypeApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryTypeAdminDeleteTrajectoryTypeApiArg = {
  typeId: number;
};
export type TrajectoryInstanceAdminGetAllTrajectoryInstancesByTypeIdApiResponse =
  /** status 200 OK */ TrajectoryInstanceDto[];
export type TrajectoryInstanceAdminGetAllTrajectoryInstancesByTypeIdApiArg = {
  typeId: number;
};
export type TrajectoryTypeAdminGetTrajectoryActionsApiResponse = /** status 200 OK */ {
  [key: string]: ManualActionViewDto;
};
export type TrajectoryTypeAdminGetTrajectoryActionsApiArg = {
  typeId: number;
  locale?: string;
};
export type TrajectoryTypeAdminGetTagsApiResponse = /** status 200 OK */ string[];
export type TrajectoryTypeAdminGetTagsApiArg = void;
export type TrajectoryInstanceAdminGetTagsApiResponse = /** status 200 OK */ string[];
export type TrajectoryInstanceAdminGetTagsApiArg = void;
export type TrajectoryInstanceAdminGetAllTrajectoryProjectionsApiResponse =
  /** status 200 OK */ TrajectoryInstanceProjectionDto[];
export type TrajectoryInstanceAdminGetAllTrajectoryProjectionsApiArg = void;
export type TrajectoryInstanceAdminGetTrajectoryInstanceByProjectIdApiResponse =
  /** status 200 OK */ TrajectoryInstanceDto;
export type TrajectoryInstanceAdminGetTrajectoryInstanceByProjectIdApiArg = {
  projectPublicId: string;
};
export type TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto[];
export type TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceIdApiArg = {
  trajectoryInstanceId: string;
  locale?: string;
};
export type TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceIdApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto[];
export type TrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceIdApiArg =
  {
    trajectoryInstanceId: string;
    participantInstanceId: string;
    locale?: string;
  };
export type TrajectoryInstanceAdminGetPlannedCommandsApiResponse =
  /** status 200 OK */ TrajectoryPlannedCommandDto[];
export type TrajectoryInstanceAdminGetPlannedCommandsApiArg = {
  state?: 'SCHEDULED' | 'EXECUTED' | 'FAILED' | 'CANCELLED' | 'RUNNING' | 'ENQUEUED';
};
export type TrajectoryInstanceAdminGetNameMapApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type TrajectoryInstanceAdminGetNameMapApiArg = void;
export type TrajectoryInstanceAdminGetLatestTrajectoryEventsApiResponse =
  /** status 200 OK */ TrajectoryInstanceEventOverviewDto[];
export type TrajectoryInstanceAdminGetLatestTrajectoryEventsApiArg = {
  limit?: number;
};
export type TrajectoryInstanceAdminGetAllTrajectoryFlowsApiResponse =
  /** status 200 OK */ TrajectoryInstanceProjectionDto[];
export type TrajectoryInstanceAdminGetAllTrajectoryFlowsApiArg = void;
export type ScanAdminGetExcelExportApiResponse = /** status 200 OK */ Blob;
export type ScanAdminGetExcelExportApiArg = {
  scanId: number;
  transformationName?: string;
};
export type ScanUrlAdminGetScanUrlByUrlApiResponse = /** status 200 OK */ ScanUrlDto;
export type ScanUrlAdminGetScanUrlByUrlApiArg = {
  scanId: number;
  scanUrl: string;
};
export type ScanAdminGetScanSubmissionsApiResponse = /** status 200 OK */ PageScanSubmissionDto;
export type ScanAdminGetScanSubmissionsApiArg = {
  scanId: number;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
  filter?: string;
  includePending?: boolean;
};
export type ScanAdminGetTransformedDataApiResponse = /** status 200 OK */ DataTransformerResultDto;
export type ScanAdminGetTransformedDataApiArg = {
  scanId: number;
  transformationName: string;
};
export type ScanTypeAdminGetScanTypeApiResponse = /** status 200 OK */ ScanTypeDto;
export type ScanTypeAdminGetScanTypeApiArg = {
  scanTypeId: number;
};
export type ScanTypeAdminDeleteScanApiResponse = /** status 200 OK */ SuccessResponse;
export type ScanTypeAdminDeleteScanApiArg = {
  scanTypeId: number;
};
export type ScanTypeAdminGetScanTypeTagsApiResponse = /** status 200 OK */ string[];
export type ScanTypeAdminGetScanTypeTagsApiArg = void;
export type ScanAdminGetScanTagsApiResponse = /** status 200 OK */ string[];
export type ScanAdminGetScanTagsApiArg = void;
export type ScanAdminGetSubmissionOverviewApiResponse =
  /** status 200 OK */ ScanSubmissionOverviewDto[];
export type ScanAdminGetSubmissionOverviewApiArg = {
  since?: string;
};
export type ScanAdminGetSubmissionOverviewPerDayApiResponse =
  /** status 200 OK */ ScanSubmissionCountPerDayDto[];
export type ScanAdminGetSubmissionOverviewPerDayApiArg = {
  since?: string;
};
export type ScanAdminDownloadFileApiResponse = /** status 200 OK */ ScanAttachmentUrlDto;
export type ScanAdminDownloadFileApiArg = {
  path: string;
};
export type ScanAdminGetAssetManagementUrlApiResponse = /** status 200 OK */ AssetManagementUrlDto;
export type ScanAdminGetAssetManagementUrlApiArg = void;
export type ProjectTypeAdminGetCompaniesForProjectTypeApiResponse =
  /** status 200 OK */ CompanyDto[];
export type ProjectTypeAdminGetCompaniesForProjectTypeApiArg = {
  projectTypeId: number;
};
export type ProjectAdminGetTagsApiResponse = /** status 200 OK */ string[];
export type ProjectAdminGetTagsApiArg = void;
export type AdminEmailGetDetailsByMessageIdApiResponse = /** status 200 OK */ DetailedMailStatusDto;
export type AdminEmailGetDetailsByMessageIdApiArg = {
  messageId: string;
};
export type AdminEmailGetStatusByMessageIdApiResponse = /** status 200 OK */ MailStatusListDto;
export type AdminEmailGetStatusByMessageIdApiArg = {
  messageId: string;
};
export type AdminEmailGetUndeliveredMailsOfLastMonthApiResponse =
  /** status 200 OK */ MailStatusListDto[];
export type AdminEmailGetUndeliveredMailsOfLastMonthApiArg = void;
export type AdminEmailGetMailsOfLastMonthApiResponse = /** status 200 OK */ MailStatusListDto[];
export type AdminEmailGetMailsOfLastMonthApiArg = void;
export type DataTransformerPresetGetByIdApiResponse = /** status 200 OK */ DataTransformerPresetDto;
export type DataTransformerPresetGetByIdApiArg = {
  id: number;
};
export type DataTransformerPresetDeleteApiResponse = /** status 200 OK */ SuccessResponse;
export type DataTransformerPresetDeleteApiArg = {
  id: number;
};
export type DataTransformerPresetGetByKeyApiResponse =
  /** status 200 OK */ DataTransformerPresetDto;
export type DataTransformerPresetGetByKeyApiArg = {
  key: string;
};
export type CustomerAdminGetCustomerApiResponse =
  /** status 200 When the customer is found */ CustomerDto;
export type CustomerAdminGetCustomerApiArg = {
  customerId: number;
};
export type AdminCreditGetCreditsApiResponse = /** status 200 OK */ CreditDto;
export type AdminCreditGetCreditsApiArg = {
  id: number;
};
export type AdminCompanyGetApiResponse = /** status 200 OK */ CompanyWithCreditDto;
export type AdminCompanyGetApiArg = {
  id: number;
};
export type AdminCompanyDeleteApiResponse = /** status 200 OK */ SuccessResponse;
export type AdminCompanyDeleteApiArg = {
  id: number;
};
export type AdminCompanyGetAllUsersApiResponse = /** status 200 OK */ UserDto[];
export type AdminCompanyGetAllUsersApiArg = {
  id: number;
};
export type AdminCompanyGetProjectTypesForCompanyApiResponse =
  /** status 200 OK */ ProjectTypeDto[];
export type AdminCompanyGetProjectTypesForCompanyApiArg = {
  id: number;
};
export type AdminCompanyGetTagsApiResponse = /** status 200 OK */ string[];
export type AdminCompanyGetTagsApiArg = void;
export type CustomerParticipantDeleteParticipantByPublicIdApiResponse =
  /** status 200 OK */ SuccessResponse;
export type CustomerParticipantDeleteParticipantByPublicIdApiArg = {
  publicCustomerId: string;
  participantId: number;
};
export type CustomerRepresentativeDeleteCustomerRepresentativeApiResponse =
  /** status 204 When the customer representative is successfully deleted */ undefined;
export type CustomerRepresentativeDeleteCustomerRepresentativeApiArg = {
  customerRepresentativeId: number;
};
export type TrajectoryInstanceAdminDeletePlannedCommandApiResponse =
  /** status 200 OK */ SuccessResponse;
export type TrajectoryInstanceAdminDeletePlannedCommandApiArg = {
  id: number;
};
export type DashboardUrlAdminDeleteDashboardUrlApiResponse = /** status 200 OK */ SuccessResponse;
export type DashboardUrlAdminDeleteDashboardUrlApiArg = {
  dashboardUrlId: string;
};
export type InvoiceAddressDto = {
  street?: string;
  number?: string;
  additionalNumberInformation?: string;
  postalCode?: string;
  city?: string;
  country?: string;
};
export type CompanyDto = {
  name?: string;
  description?: string;
  invoiceAddress?: InvoiceAddressDto;
  vatNumber?: string;
  isSingleCustomerCompany?: boolean;
  canCreateProjects?: boolean;
  canManageOrganizationalStructure?: boolean;
  tags?: string[];
  id?: number;
  publicId?: string;
  keycloakGroupId?: string;
  createdOn?: string;
  modifiedOn?: string;
};
export type UserDto = {
  role?: string;
  name?: string;
  firstname?: string;
  email?: string;
  active?: boolean;
  used?: boolean;
  description?: string;
  id?: number;
  company?: CompanyDto;
  publicUserId?: string;
};
export type ErrorResponse = {
  messages?: string[];
  code?:
    | 'UNKNOWN'
    | 'NO_ACCESS'
    | 'NOT_FOUND'
    | 'BAD_REQUEST'
    | 'SCAN_NOT_ACTIVE'
    | 'SCAN_PARTIAL_SUBMISSION_MISMATCH'
    | 'SCAN_ALREADY_SUBMITTED'
    | 'USER_NOT_ACTIVE';
};
export type UpdateUserDetailDto = {
  id?: number;
  name?: string;
  firstname?: string;
  email?: string;
};
export type ParticipantRoleDto = {
  organizationalUnitId?: string;
  roleType?: 'SUPERVISOR' | 'EMPLOYEE';
};
export type ParticipantDto = {
  id?: number;
  customerId?: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  roles?: ParticipantRoleDto[];
  metaData?: {
    [key: string]: object;
  };
  publicId?: string;
  locale?: string;
};
export type ProjectOrganizationalUnitDto = {
  id?: string;
  selected?: boolean;
};
export type BaseProjectDto = {
  tags?: string[];
  modifiedOn?: string;
  publicId?: string;
  name?: string;
  id?: number;
  active?: boolean;
  customerId?: number;
  comments?: string;
  startDate?: string;
  lastCompletedCreationStage?: 'BASIC_INFORMATION' | 'ORGANIZATIONAL_STRUCTURE' | 'COMPLETED';
  endDate?: string;
  creationCompleted?: boolean;
  organizationalUnits?: ProjectOrganizationalUnitDto[];
  projectTypeId?: number;
  customerRepresentativeId?: number;
};
export type UpdateProjectDetailsDto = {
  name: string;
  tags?: string[];
};
export type OrganizationalUnitDto = {
  id?: string;
  name?: string;
  subunits?: OrganizationalUnitDto[];
};
export type CustomerOrganizationalStructureDto = {
  units?: OrganizationalUnitDto[];
  customerId?: number;
  completed?: boolean;
  created?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  anonymousAccessKey?: string;
};
export type ParticipantsSummaryDto = {
  totalNumberOfParticipants?: number;
  numberOfSupervisors?: number;
  numberOfEmployees?: number;
};
export type AddressDto = {
  recipient?: string;
  street: string;
  number: string;
  additionalNumberInformation?: string;
  postalCode: string;
  city: string;
  country: string;
};
export type CustomerDto = {
  id?: number;
  publicId?: string;
  name?: string;
  logoUrl?: string;
  vatNumber?: string;
  comments?: string;
  registrationCompleted?: boolean;
  lastCompletedRegistrationStage?:
    | 'BASIC_INFORMATION'
    | 'CUSTOMER_REPRESENTATIVE'
    | 'BILLING_INFORMATION'
    | 'ORGANIZATIONAL_STRUCTURE';
  invoiceAddress?: AddressDto;
  organizationalStructure?: CustomerOrganizationalStructureDto;
  companyId?: number;
};
export type SaveBillingInformationRequest = {
  invoiceAddress?: AddressDto;
  vatNumber?: string;
};
export type CustomerRepresentativeDto = {
  id?: number;
  name?: string;
  phoneNumber?: string;
  emailAddress?: string;
  customerId?: number;
};
export type SaveCustomerRepresentativeRequest = {
  name: string;
  id: number;
  customerId: number;
  phoneNumber: string;
  emailAddress: string;
};
export type ChartCategorizationTotalOptionsDto = {
  enabled?: boolean;
  label?: string;
  front?: boolean;
};
export type ChartCategorizationOptionsDto = {
  minimalEntries?: number;
  localTotal?: ChartCategorizationTotalOptionsDto;
  globalTotal?: ChartCategorizationTotalOptionsDto;
  order?: string[];
};
export type ChartCategorizationDto = {
  name?: string;
  options?: ChartCategorizationOptionsDto;
  hiddenItems?: string[];
  type: string;
};
export type CaseExpressionDto = {
  label?: string;
  expression?: string;
};
export type ImmutableCaseExpressionsChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  expressions?: CaseExpressionDto[];
  column?: string;
};
export type ValueAliasDto = {
  value?: string;
  alias?: string;
};
export type ImmutableColumnListChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  columns?: string[];
  aliases?: ValueAliasDto[];
  filterEmptyLines?: boolean;
};
export type ImmutableColumnValuesChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  column?: string;
  values?: string[];
  aliases?: ValueAliasDto[];
  hideMissing?: boolean;
};
export type ImmutableGlobalReferenceChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  reference?: string;
};
export type ImmutableMultiValueColumnChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  column?: string;
  separator?: string;
  includeArrayColumns?: boolean;
  values?: string[];
  aliases?: ValueAliasDto[];
};
export type ImmutableNoneChartCategorizationDto = ChartCategorizationDto & {
  hiddenItems?: string[];
  label?: string;
};
export type ChartConfigurationDto = {
  name?: string;
  datasource?: 'LOCAL' | 'GLOBAL';
  valueType?: 'AVERAGE' | 'PERCENTAGE' | 'PERCENTAGE_ROW_COUNT' | 'COUNT';
  series?:
    | ImmutableCaseExpressionsChartCategorizationDto
    | ImmutableColumnListChartCategorizationDto
    | ImmutableColumnValuesChartCategorizationDto
    | ImmutableGlobalReferenceChartCategorizationDto
    | ImmutableMultiValueColumnChartCategorizationDto
    | ImmutableNoneChartCategorizationDto;
  categories?:
    | ImmutableCaseExpressionsChartCategorizationDto
    | ImmutableColumnListChartCategorizationDto
    | ImmutableColumnValuesChartCategorizationDto
    | ImmutableGlobalReferenceChartCategorizationDto
    | ImmutableMultiValueColumnChartCategorizationDto
    | ImmutableNoneChartCategorizationDto;
  presentationExpressions?: CaseExpressionDto[];
  transpose?: boolean;
  decimals?: number;
  overrideColumns?: boolean;
  columns?: string[];
  disableLargestRemainderRounding?: boolean;
};
export type DataReportSectionDto = {
  name?: string;
  columns?: string[];
  charts?: ChartConfigurationDto[];
  categorization?:
    | ImmutableCaseExpressionsChartCategorizationDto
    | ImmutableColumnListChartCategorizationDto
    | ImmutableColumnValuesChartCategorizationDto
    | ImmutableGlobalReferenceChartCategorizationDto
    | ImmutableMultiValueColumnChartCategorizationDto
    | ImmutableNoneChartCategorizationDto;
};
export type GlobalChartCategorizationDto = {
  reference?: string;
  categorization?:
    | ImmutableCaseExpressionsChartCategorizationDto
    | ImmutableColumnListChartCategorizationDto
    | ImmutableColumnValuesChartCategorizationDto
    | ImmutableGlobalReferenceChartCategorizationDto
    | ImmutableMultiValueColumnChartCategorizationDto
    | ImmutableNoneChartCategorizationDto;
  name?: string;
  conditionExpression?: string;
};
export type DataReportCalculatedColumnDto = {
  name?: string;
  expression?: string;
  type?: 'SHORT' | 'INTEGER' | 'LONG' | 'FLOAT' | 'BOOLEAN' | 'STRING' | 'DOUBLE';
};
export type DataReportFilterExpressionDto = {
  name?: string;
  expression?: string;
};
export type DataReportFilterDto = {
  name?: string;
  label?: string;
  fieldName?: string;
  expressions?: DataReportFilterExpressionDto[];
  allowMultiple?: boolean;
  enabledIfFilterSet?: string[];
  disabledIfFilterSet?: string[];
};
export type DataReportTranslationMapDto = {
  [key: string]: string;
};
export type DataReportVariableDto = {
  name?: string;
  value?: object;
  type?:
    | 'SHORT'
    | 'INTEGER'
    | 'LONG'
    | 'FLOAT'
    | 'BOOLEAN'
    | 'STRING'
    | 'DOUBLE'
    | 'EXPRESSION'
    | 'EXPRESSION_AFTER_SECTIONS'
    | 'JSON';
};
export type DataReportRequestDto = {
  sections?: DataReportSectionDto[];
  categorizations?: GlobalChartCategorizationDto[];
  iterations?: (
    | ImmutableCaseExpressionsChartCategorizationDto
    | ImmutableColumnListChartCategorizationDto
    | ImmutableColumnValuesChartCategorizationDto
    | ImmutableGlobalReferenceChartCategorizationDto
    | ImmutableMultiValueColumnChartCategorizationDto
    | ImmutableNoneChartCategorizationDto
  )[];
  filterExpression?: string;
  calculatedColumns?: DataReportCalculatedColumnDto[];
  filters?: DataReportFilterDto[];
  translations?: {
    [key: string]: DataReportTranslationMapDto;
  };
  variables?: DataReportVariableDto[];
  locale?: string;
};
export type BaseVisualizationDto = {
  type: string;
};
export type ImmutableInfographicVisualizationDto = BaseVisualizationDto & {
  infographicName?: string;
  availableOptions?: {
    [key: string]: string[];
  };
  config?: {
    [key: string]: object;
  };
};
export type ImmutablePowerpointVisualizationDto = BaseVisualizationDto & {
  fileName?: string;
  availableOptions?: {
    [key: string]: string[];
  };
};
export type VisualizationDefinitionDto = {
  defaultDataReporterConfiguration?: string;
  dataReportRequests?: {
    [key: string]: DataReportRequestDto;
  };
  visualizationConfig?: ImmutableInfographicVisualizationDto | ImmutablePowerpointVisualizationDto;
  scanId?: number;
  transformerName?: string;
  dataInputFiles?: string[];
  defaultDataInputFilename?: string;
  dataInputType?: 'SCAN' | 'FILE';
};
export type VisualizationDto = {
  id?: number;
  name?: string;
  definition?: VisualizationDefinitionDto;
  projectId?: number;
  visibleOnExportPage?: boolean;
  creationalTemplate?: boolean;
  createdOn?: string;
  modifiedOn?: string;
};
export type UpdateUserDto = {
  role?: string;
  name?: string;
  firstname?: string;
  email?: string;
  active?: boolean;
  used?: boolean;
  description?: string;
  companyId?: number;
  id?: number;
};
export type CreateUserDto = {
  role?: string;
  name?: string;
  firstname?: string;
  email?: string;
  active?: boolean;
  used?: boolean;
  description?: string;
  companyId?: number;
};
export type UserImportSummaryDto = {
  addedCount?: number;
  skippedCount?: number;
};
export type StepInstanceDto = {
  name?: string;
  type: string;
};
export type ImmutableDashboardStepInstanceDto = StepInstanceDto & {
  url?: string;
  urls?: {
    [key: string]: string;
  };
};
export type ImmutableScanStepInstanceDto = StepInstanceDto & {
  scanId?: number;
  url?: string;
  urls?: {
    [key: string]: string;
  };
};
export type VisualizationAssetDto = {
  id?: string;
  filename?: string;
  iteration?: string;
};
export type ImmutableVisualizationStepInstanceDto = StepInstanceDto & {
  assets?: {
    [key: string]: VisualizationAssetDto;
  };
};
export type IndividualMilestoneSharedInstanceDto = {
  name?: string;
  steps?: {
    [key: string]:
      | ImmutableDashboardStepInstanceDto
      | ImmutableScanStepInstanceDto
      | ImmutableVisualizationStepInstanceDto;
  };
};
export type MilestoneInstanceDto = {
  name?: string;
  startIndividualMilestone?: string;
  number?: number;
  individualMilestones?: {
    [key: string]: IndividualMilestoneSharedInstanceDto;
  };
};
export type TrajectoryAttachmentItemDto = {
  container?: string;
  path?: string;
  label?: string;
  key?: string;
};
export type TrajectoryLogItemDto = {
  severity?: 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR' | 'SUCCESS';
  message?: string;
  timestamp?: string;
};
export type TrajectoryInstanceEventDto = {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  eventId?: string;
  trajectoryTypeId?: number;
  createdOn?: string;
  instanceId?: string;
  participantInstanceId?: string;
  keycloakUserId?: string;
  type: string;
};
export type ImmutableAddMetadataEventDto = TrajectoryInstanceEventDto & {
  data?: {
    [key: string]: object;
  };
  merge?: boolean;
};
export type ImmutableAttachmentAddedEventDto = TrajectoryInstanceEventDto & {
  key?: string;
  attachment?: TrajectoryAttachmentItemDto;
};
export type ImmutableDashboardUrlAddedEventDto = TrajectoryInstanceEventDto & {
  dashboardPath?: string;
  url?: string;
  key?: string;
};
export type ImmutableDataStoredEventDto = TrajectoryInstanceEventDto & {
  key?: string;
};
export type ImmutableLogbookTrajectoryEventDto = TrajectoryInstanceEventDto & {
  logItem?: TrajectoryLogItemDto;
};
export type CommandDto = {
  name?: string;
  description?: string;
  nextCommand?: CommandDto;
  type: string;
};
export type ContextItemDto = {
  type: string;
};
export type DynamicListValueDto = {
  value?: object[];
  expression?: ExpressionDto;
};
export type ImmutableCompaniesContextItemDto = ContextItemDto & {
  tagsFilter?: DynamicListValueDto;
};
export type ImmutableCompanyContextItemDto = ContextItemDto;
export type DynamicBoolValueDto = {
  value?: boolean;
  expression?: ExpressionDto;
};
export type DynamicDictionaryValueDto = {
  value?: {
    [key: string]: object;
  };
  expression?: ExpressionDto;
};
export type ImmutableDataReporterContextItemDto = ContextItemDto & {
  data?: DynamicListValueDto;
  iterations?: DynamicListValueDto;
  iterationsOnly?: DynamicBoolValueDto;
  visualizationName?: DynamicStringValueDto;
  dataReporterConfiguration?: DynamicStringValueDto;
  variables?: DynamicDictionaryValueDto;
};
export type TemplateValueDto = {
  templateName?: DynamicStringValueDto;
  context?: RequiredContextDto;
};
export type DynamicObjectValueDto = {
  value?: object;
  expression?: ExpressionDto;
  template?: TemplateValueDto;
};
export type ImmutableDataStoreContextItemDto = ContextItemDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  key?: DynamicStringValueDto;
  fallbackData?: DynamicObjectValueDto;
  storeFallbackData?: DynamicBoolValueDto;
};
export type ImmutableDataTransformerContextItemDto = ContextItemDto & {
  data?: DynamicListValueDto;
  presetKey?: DynamicStringValueDto;
  variables?: DynamicDictionaryValueDto;
};
export type ImmutableDynamicValueContextItemDto = ContextItemDto & {
  dynamicObjectValue?: DynamicObjectValueDto;
  condition?: DynamicBoolValueDto;
};
export type ImmutableExpressionContextItemDto = ContextItemDto & {
  expression?: ExpressionDto;
};
export type ImmutableExternalTrajectoryContextItemDto = ContextItemDto & {
  instanceId?: DynamicStringValueDto;
  projectPublicId?: DynamicStringValueDto;
  withParticipants?: boolean;
  useProjection?: DynamicBoolValueDto;
};
export type ImmutableListProjectionContextItemDto = ContextItemDto & {
  itemProjection?: DynamicObjectValueDto;
  itemCondition?: DynamicBoolValueDto;
  list?: DynamicListValueDto;
};
export type ImmutableParticipantContextItemDto = ContextItemDto;
export type DynamicIntegerValueDto = {
  value?: number;
  expression?: ExpressionDto;
};
export type ImmutableParticipantScanSubmissionContextItemDto = ContextItemDto & {
  scanPath?: DynamicStringValueDto;
  scanSubmissionId?: DynamicIntegerValueDto;
};
export type ImmutableProjectContextItemDto = ContextItemDto;
export type ImmutableProjectsContextItemDto = ContextItemDto & {
  companyId?: DynamicIntegerValueDto;
  customerId?: DynamicIntegerValueDto;
  projectTypeId?: DynamicIntegerValueDto;
  tagsFilter?: DynamicListValueDto;
};
export type ImmutableScanSubmissionContextItemDto = ContextItemDto & {
  scanPath?: DynamicStringValueDto;
  allRows?: boolean;
  joinOn?: DynamicStringValueDto;
  transformationKey?: DynamicStringValueDto;
  transformationVariables?: DynamicDictionaryValueDto;
  addParticipantList?: DynamicBoolValueDto;
};
export type ImmutableSheetFileContextItemDto = ContextItemDto & {
  key?: DynamicStringValueDto;
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
};
export type ImmutableUserAccountContextItemDto = ContextItemDto & {
  email?: DynamicStringValueDto;
  firstname?: DynamicStringValueDto;
  lastname?: DynamicStringValueDto;
  allowCreation?: DynamicBoolValueDto;
};
export type RequiredContextDto = {
  items?: {
    [key: string]:
      | ImmutableCompaniesContextItemDto
      | ImmutableCompanyContextItemDto
      | ImmutableDataReporterContextItemDto
      | ImmutableDataStoreContextItemDto
      | ImmutableDataTransformerContextItemDto
      | ImmutableDynamicValueContextItemDto
      | ImmutableExpressionContextItemDto
      | ImmutableExternalTrajectoryContextItemDto
      | ImmutableListProjectionContextItemDto
      | ImmutableParticipantContextItemDto
      | ImmutableParticipantScanSubmissionContextItemDto
      | ImmutableProjectContextItemDto
      | ImmutableProjectsContextItemDto
      | ImmutableScanSubmissionContextItemDto
      | ImmutableSheetFileContextItemDto
      | ImmutableUserAccountContextItemDto;
  };
  itemsOrder?: string[];
  rootItems?: (
    | ImmutableCompaniesContextItemDto
    | ImmutableCompanyContextItemDto
    | ImmutableDataReporterContextItemDto
    | ImmutableDataStoreContextItemDto
    | ImmutableDataTransformerContextItemDto
    | ImmutableDynamicValueContextItemDto
    | ImmutableExpressionContextItemDto
    | ImmutableExternalTrajectoryContextItemDto
    | ImmutableListProjectionContextItemDto
    | ImmutableParticipantContextItemDto
    | ImmutableParticipantScanSubmissionContextItemDto
    | ImmutableProjectContextItemDto
    | ImmutableProjectsContextItemDto
    | ImmutableScanSubmissionContextItemDto
    | ImmutableSheetFileContextItemDto
    | ImmutableUserAccountContextItemDto
  )[];
};
export type ExpressionDto = {
  context?: RequiredContextDto;
  expression?: string;
};
export type DynamicStringValueDto = {
  value?: string;
  expression?: ExpressionDto;
  template?: TemplateValueDto;
};
export type TrajectoryAttachmentDto = {
  name?: string;
  type: string;
};
export type SendMailCommandDto = {
  name?: string;
  description?: string;
  nextCommand?: CommandDto;
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  subject?: DynamicStringValueDto;
  message?: DynamicStringValueDto;
  recipients?: DynamicListValueDto;
  data?: DynamicDictionaryValueDto;
  attachments?: TrajectoryAttachmentDto[];
  templateId?: DynamicStringValueDto;
};
export type MailRecipientDto = {
  email?: string;
  name?: string;
};
export type ImmutableMailSentEventDto = TrajectoryInstanceEventDto & {
  resendCommand?: SendMailCommandDto;
  recipients?: MailRecipientDto[];
  attachmentFiles?: string[];
  templateId?: string;
  success?: boolean;
  data?: {
    [key: string]: object;
  };
  messageId?: string;
  testMode?: boolean;
};
export type ImmutableMoveIndividualMilestoneEventDto = TrajectoryInstanceEventDto & {
  newIndividualMilestoneName?: string;
  milestonePath?: string;
};
export type ImmutableMoveMilestoneEventDto = TrajectoryInstanceEventDto & {
  newMilestoneName?: string;
};
export type ImmutablePlannedCommandEventDto = TrajectoryInstanceEventDto & {
  plannedCommandId?: number;
  label?: string;
};
export type ImmutableScanActivationEventDto = TrajectoryInstanceEventDto & {
  scanPath?: string;
  scanId?: number;
  active?: boolean;
};
export type ImmutableScanCreatedEventDto = TrajectoryInstanceEventDto & {
  scanPath?: string;
  scanId?: number;
};
export type ImmutableScanSubmissionDeletedEventDto = TrajectoryInstanceEventDto & {
  submissionId?: number;
  scanPath?: string;
};
export type ImmutableScanSubmissionEventDto = TrajectoryInstanceEventDto & {
  submissionId?: number;
  scanPath?: string;
};
export type ImmutableScanUrlActivationEventDto = TrajectoryInstanceEventDto & {
  scanPath?: string;
  scanUrlId?: number;
  key?: string;
  active?: boolean;
  alreadySubmitted?: boolean;
};
export type ImmutableScanUrlAddedEventDto = TrajectoryInstanceEventDto & {
  scanPath?: string;
  url?: string;
  key?: string;
  embeddedInTrajectoryView?: boolean;
};
export type ImmutableTrajectoryInstanceCreatedEventDto = TrajectoryInstanceEventDto & {
  data?: TrajectoryInstanceDto;
};
export type StepParticipantInstanceDto = {
  name?: string;
  type: string;
};
export type IndividualMilestoneParticipantInstanceDto = {
  name?: string;
  number?: number;
  steps?: {
    [key: string]: StepParticipantInstanceDto;
  };
};
export type MilestoneParticipantInstanceDto = {
  name?: string;
  individualMilestones?: {
    [key: string]: IndividualMilestoneParticipantInstanceDto;
  };
  number?: number;
  currentIndividualMilestone?: string;
};
export type TrajectoryParticipantInstanceDto = {
  id?: string;
  trajectoryInstanceId?: string;
  participantId?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  locale?: string;
  currentMilestone?: string;
  currentIndividualMilestonePath?: string;
  milestones?: {
    [key: string]: MilestoneParticipantInstanceDto;
  };
  attachments?: {
    [key: string]: TrajectoryAttachmentItemDto;
  };
  participantEvents?: TrajectoryInstanceEventDto[];
  metaData?: {
    [key: string]: object;
  };
  logItems?: TrajectoryLogItemDto[];
  active?: boolean;
  lastModified?: string;
};
export type ImmutableTrajectoryParticipantInstanceCreatedEventDto = TrajectoryInstanceEventDto & {
  data?: TrajectoryParticipantInstanceDto;
};
export type ImmutableTrajectoryParticipantUpdatedEventDto = TrajectoryInstanceEventDto & {
  firstname?: string;
  lastname?: string;
  email?: string;
  locale?: string;
  active?: boolean;
};
export type ImmutableVisualizationsCreatedEventDto = TrajectoryInstanceEventDto & {
  visualizationPath?: string;
  assets?: {
    [key: string]: VisualizationAssetDto;
  };
  isPartial?: boolean;
};
export type TrajectoryInstanceDto = {
  id?: string;
  name?: string;
  projectId?: number;
  trajectoryTypeId?: number;
  currentMilestone?: string;
  defaultLocale?: string;
  milestones?: {
    [key: string]: MilestoneInstanceDto;
  };
  attachments?: {
    [key: string]: TrajectoryAttachmentItemDto;
  };
  metaData?: {
    [key: string]: object;
  };
  logItems?: TrajectoryLogItemDto[];
  events?: (
    | ImmutableAddMetadataEventDto
    | ImmutableAttachmentAddedEventDto
    | ImmutableDashboardUrlAddedEventDto
    | ImmutableDataStoredEventDto
    | ImmutableLogbookTrajectoryEventDto
    | ImmutableMailSentEventDto
    | ImmutableMoveIndividualMilestoneEventDto
    | ImmutableMoveMilestoneEventDto
    | ImmutablePlannedCommandEventDto
    | ImmutableScanActivationEventDto
    | ImmutableScanCreatedEventDto
    | ImmutableScanSubmissionDeletedEventDto
    | ImmutableScanSubmissionEventDto
    | ImmutableScanUrlActivationEventDto
    | ImmutableScanUrlAddedEventDto
    | ImmutableTrajectoryInstanceCreatedEventDto
    | ImmutableTrajectoryParticipantInstanceCreatedEventDto
    | ImmutableTrajectoryParticipantUpdatedEventDto
    | ImmutableVisualizationsCreatedEventDto
  )[];
  active?: boolean;
  lastModified?: string;
};
export type StepDto = {
  name?: string;
  title?: DynamicStringValueDto;
  description?: DynamicStringValueDto;
  type: string;
};
export type ImmutableDashboardStepDto = StepDto & {
  dashboardName?: string;
  initialData?: DynamicDictionaryValueDto;
};
export type ImmutableScanStepDto = StepDto & {
  scanTypeName?: string;
  initialData?: DynamicDictionaryValueDto;
  overrideKeys?: DynamicListValueDto;
  onCreatedCommand?: CommandDto;
  onSubmitCommand?: CommandDto;
  onStartedCommand?: CommandDto;
  onProgressCommand?: CommandDto;
};
export type ImmutableVisualizationStepDto = StepDto & {
  visualizationTypeName?: string;
  datareportName?: string;
};
export type IndividualMilestoneDto = {
  name?: string;
  steps?: {
    [key: string]: ImmutableDashboardStepDto | ImmutableScanStepDto | ImmutableVisualizationStepDto;
  };
  stepsOrder?: string[];
  isVisible?: DynamicBoolValueDto;
  label?: DynamicStringValueDto;
  color?: DynamicStringValueDto;
  icon?: DynamicStringValueDto;
  number?: number;
};
export type MilestoneDto = {
  name?: string;
  startIndividualMilestone?: string;
  individualMilestones?: {
    [key: string]: IndividualMilestoneDto;
  };
  individualMilestonesOrder?: string[];
  steps?: {
    [key: string]: ImmutableDashboardStepDto | ImmutableScanStepDto | ImmutableVisualizationStepDto;
  };
  number?: number;
  isVisible?: DynamicBoolValueDto;
  label?: DynamicStringValueDto;
  color?: DynamicStringValueDto;
  icon?: DynamicStringValueDto;
};
export type EnrollmentTypeDto = {
  type: string;
};
export type ImmutableScanSubmissionEnrollmentTypeDto = EnrollmentTypeDto & {
  scanPath?: string;
};
export type ImmutableAddAttachmentCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  container?: DynamicStringValueDto;
  path?: DynamicStringValueDto;
  label?: DynamicStringValueDto;
  key?: DynamicStringValueDto;
};
export type ImmutableAddMetaDataCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  metaData?: DynamicDictionaryValueDto;
  ignoreWhenIdempotent?: boolean;
  merge?: boolean;
};
export type ImmutableConditionalCommandDto = CommandDto & {
  command?: CommandDto;
  elseCommand?: CommandDto;
  condition?: DynamicBoolValueDto;
};
export type ImmutableContextWrapperCommandDto = CommandDto & {
  data?: DynamicDictionaryValueDto;
  command?: CommandDto;
};
export type ImmutableCreateDashboardUrlCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  data?: DynamicDictionaryValueDto;
  dashboardPath?: DynamicStringValueDto;
  key?: DynamicStringValueDto;
  active?: DynamicBoolValueDto;
  isPublic?: DynamicBoolValueDto;
  showOnMainDashboard?: DynamicBoolValueDto;
  userId?: DynamicIntegerValueDto;
};
export type ImmutableCreateProjectCommandDto = CommandDto & {
  projectName?: DynamicStringValueDto;
  customerId?: DynamicIntegerValueDto;
  customerRepresentativeId?: DynamicIntegerValueDto;
  projectTypeName?: DynamicStringValueDto;
  projectTypeId?: DynamicIntegerValueDto;
  organizationalUnits?: DynamicListValueDto;
  participants?: DynamicListValueDto;
  parameters?: DynamicDictionaryValueDto;
  tags?: DynamicListValueDto;
};
export type ImmutableCreateScanCommandDto = CommandDto & {
  scanName?: DynamicStringValueDto;
  scanTypeName?: DynamicStringValueDto;
  scanTypeId?: DynamicIntegerValueDto;
  scanPath?: DynamicStringValueDto;
  existingScanId?: DynamicIntegerValueDto;
  active?: DynamicBoolValueDto;
  projectId?: DynamicIntegerValueDto;
};
export type ImmutableCreateScanUrlCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  scanId?: DynamicIntegerValueDto;
  data?: DynamicDictionaryValueDto;
  scanPath?: DynamicStringValueDto;
  metaDataVariableName?: DynamicStringValueDto;
  addTrajectoryData?: boolean;
  metaDataTarget?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  embeddedInTrajectoryView?: boolean;
  active?: DynamicBoolValueDto;
  key?: DynamicStringValueDto;
  resumeEnabled?: DynamicBoolValueDto;
  maxSubmissions?: DynamicIntegerValueDto;
};
export type ImmutableCreateTrajectoryInstanceCommandDto = CommandDto & {
  instanceName?: DynamicStringValueDto;
  projectId?: DynamicIntegerValueDto;
  metaData?: {
    [key: string]: object;
  };
};
export type ImmutableCreateTrajectoryParticipantInstanceCommandDto = CommandDto & {
  participantId?: number;
  clone?: DynamicObjectValueDto;
  dynamicParticipantId?: DynamicIntegerValueDto;
  participantInstanceId?: DynamicStringValueDto;
  firstname?: DynamicStringValueDto;
  lastname?: DynamicStringValueDto;
  email?: DynamicStringValueDto;
  locale?: DynamicStringValueDto;
  metaData?: DynamicDictionaryValueDto;
  active?: DynamicBoolValueDto;
};
export type ImmutableDataStoreCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  key?: DynamicStringValueDto;
  data?: DynamicObjectValueDto;
};
export type ImmutableDeleteScanSubmissionCommandDto = CommandDto & {
  scanPath?: DynamicStringValueDto;
  scanSubmissionId?: DynamicIntegerValueDto;
};
export type ImmutableForeachCommandDto = CommandDto & {
  values?: DynamicListValueDto;
  variableName?: string;
  command?: CommandDto;
  allowParallel?: boolean;
  trackProgress?: boolean;
};
export type ImmutableForeachParticipantCommandDto = CommandDto & {
  command?: CommandDto;
  enabledParticipantsOnly?: boolean;
  allowParallel?: boolean;
  trackProgress?: boolean;
};
export type ImmutableInvokeTrajectoryActionCommandDto = CommandDto & {
  trajectoryTypeId?: DynamicIntegerValueDto;
  trajectoryInstanceId?: DynamicStringValueDto;
  trajectoryParticipantInstanceId?: DynamicStringValueDto;
  projectId?: DynamicIntegerValueDto;
  actionName?: DynamicStringValueDto;
  locale?: DynamicStringValueDto;
  parameters?: DynamicDictionaryValueDto;
};
export type ImmutableListToExcelAttachmentCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  filename?: DynamicStringValueDto;
  label?: DynamicStringValueDto;
  key?: DynamicStringValueDto;
  data?: DynamicListValueDto;
  pinnedColumns?: DynamicListValueDto;
};
export type ImmutableLogCommandDto = CommandDto & {
  severity?: 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR' | 'SUCCESS';
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  message?: DynamicObjectValueDto;
};
export type ImmutableMoveIndividualMilestoneCommandDto = CommandDto & {
  newIndividualMilestoneName?: DynamicStringValueDto;
  milestonePath?: DynamicStringValueDto;
};
export type ImmutableMoveMilestoneCommandDto = CommandDto & {
  newMilestoneName?: DynamicStringValueDto;
  milestonePath?: DynamicStringValueDto;
};
export type ImmutableParticipantContextWrapperCommandDto = CommandDto & {
  participantId?: DynamicStringValueDto;
  command?: CommandDto;
};
export type ImmutablePipelineCommandDto = CommandDto & {
  commands?: CommandDto[];
};
export type DynamicDateTimeValueDto = {
  value?: string;
  expression?: ExpressionDto;
};
export type ContextItem = object;
export type RequiredContext = {
  items?: {
    [key: string]: ContextItem;
  };
  itemsOrder?: string[];
  rootItems?: ContextItem[];
};
export type Expression = {
  context?: RequiredContext;
  expression?: string;
};
export type DynamicDictionaryValue = {
  value?: {
    [key: string]: object;
  };
  expression?: Expression;
  valuePresent?: boolean;
};
export type ImmutablePlannedCommandDto = CommandDto & {
  command?: CommandDto;
  scheduledOn?: DynamicDateTimeValueDto;
  context?: DynamicDictionaryValue;
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  label?: DynamicStringValueDto;
  plannedDescription?: DynamicStringValueDto;
  key?: DynamicStringValueDto;
  uniquePlannedKey?: DynamicBoolValueDto;
};
export type ImmutableRenderVisualizationCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  visualizationPath?: DynamicStringValueDto;
  visualizationId?: DynamicIntegerValueDto;
  visualizationName?: DynamicStringValueDto;
  data?: DynamicListValueDto;
  variables?: DynamicDictionaryValueDto;
  filename?: DynamicStringValueDto;
  isPartial?: DynamicBoolValueDto;
  fileType?: DynamicStringValueDto;
  asExcelData?: boolean;
  logJsonResult?: boolean;
  logProgress?: boolean;
  iterations?: DynamicListValueDto;
  dataReporterConfiguration?: DynamicStringValueDto;
  onSingleVisualizationReadyCommand?: CommandDto;
  onSuccessCommand?: CommandDto;
  onFailureCommand?: CommandDto;
};
export type ImmutableReportProgressCommandDto = CommandDto & {
  count?: DynamicIntegerValueDto;
  total?: DynamicIntegerValueDto;
};
export type ImmutableResultCommandDto = CommandDto & {
  value?: DynamicObjectValueDto;
};
export type ImmutableScanActivationCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  scanId?: DynamicIntegerValueDto;
  scanPath?: DynamicStringValueDto;
  active?: DynamicBoolValueDto;
};
export type ImmutableScanSubmissionCommandDto = CommandDto & {
  scanSubmissionId?: DynamicIntegerValueDto;
  scanPath?: DynamicStringValueDto;
};
export type ImmutableScanUrlActivationCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  scanUrlId?: DynamicIntegerValueDto;
  scanPath?: DynamicStringValueDto;
  key?: DynamicStringValueDto;
  active?: DynamicBoolValueDto;
  alreadySubmitted?: DynamicBoolValueDto;
};
export type ImmutableSendMailCommandDto = CommandDto & {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  subject?: DynamicStringValueDto;
  message?: DynamicStringValueDto;
  recipients?: DynamicListValueDto;
  data?: DynamicDictionaryValueDto;
  attachments?: TrajectoryAttachmentDto[];
  templateId?: DynamicStringValueDto;
};
export type ImmutableSharedCommandDto = CommandDto & {
  commandName?: DynamicStringValueDto;
};
export type ImmutableUpdateScanInstanceFromTypeCommandDto = CommandDto & {
  scanPath?: DynamicStringValueDto;
  scanId?: DynamicIntegerValueDto;
  forceTypeId?: DynamicIntegerValueDto;
};
export type ImmutableUpdateTrajectoryParticipantCommandDto = CommandDto & {
  firstname?: DynamicStringValueDto;
  lastname?: DynamicStringValueDto;
  email?: DynamicStringValueDto;
  locale?: DynamicStringValueDto;
  active?: DynamicBoolValueDto;
};
export type ImmutableUpdateTrajectoryTypeCommandDto = CommandDto & {
  trajectoryInstanceId?: DynamicStringValueDto;
  projectId?: DynamicIntegerValueDto;
  keepOverriddenContent?: DynamicBoolValueDto;
};
export type ImmutableUpsertCompanyCommandDto = CommandDto & {
  companyName?: DynamicStringValueDto;
  companyDescription?: DynamicStringValueDto;
  companyId?: DynamicIntegerValueDto;
  vatNumber?: DynamicStringValueDto;
  tags?: DynamicListValueDto;
  isSingleCustomerCompany?: DynamicBoolValueDto;
  canCreateProjects?: DynamicBoolValueDto;
  canManageOrganizationalStructure?: DynamicBoolValueDto;
};
export type ImmutableUpsertCustomerRepresentativeCommandDto = CommandDto & {
  customerRepresentativeId?: DynamicIntegerValueDto;
  customerRepresentativeName?: DynamicStringValueDto;
  customerRepresentativeEmail?: DynamicStringValueDto;
  customerRepresentativePhoneNumber?: DynamicStringValueDto;
  customerId?: DynamicIntegerValueDto;
};
export type ImmutableUpsertUserCommandDto = CommandDto & {
  userId?: DynamicIntegerValueDto;
  role?: DynamicStringValueDto;
  lastname?: DynamicStringValueDto;
  firstname?: DynamicStringValueDto;
  email?: DynamicStringValueDto;
  isActive?: DynamicBoolValueDto;
  companyId?: DynamicIntegerValueDto;
};
export type ManualActionDto = {
  actionLabel?: DynamicStringValueDto;
  actionIcon?: DynamicStringValueDto;
  requiredRole?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  availabilityExpression?: DynamicBoolValueDto;
  command?:
    | ImmutableAddAttachmentCommandDto
    | ImmutableAddMetaDataCommandDto
    | ImmutableConditionalCommandDto
    | ImmutableContextWrapperCommandDto
    | ImmutableCreateDashboardUrlCommandDto
    | ImmutableCreateProjectCommandDto
    | ImmutableCreateScanCommandDto
    | ImmutableCreateScanUrlCommandDto
    | ImmutableCreateTrajectoryInstanceCommandDto
    | ImmutableCreateTrajectoryParticipantInstanceCommandDto
    | ImmutableDataStoreCommandDto
    | ImmutableDeleteScanSubmissionCommandDto
    | ImmutableForeachCommandDto
    | ImmutableForeachParticipantCommandDto
    | ImmutableInvokeTrajectoryActionCommandDto
    | ImmutableListToExcelAttachmentCommandDto
    | ImmutableLogCommandDto
    | ImmutableMoveIndividualMilestoneCommandDto
    | ImmutableMoveMilestoneCommandDto
    | ImmutableParticipantContextWrapperCommandDto
    | ImmutablePipelineCommandDto
    | ImmutablePlannedCommandDto
    | ImmutableRenderVisualizationCommandDto
    | ImmutableReportProgressCommandDto
    | ImmutableResultCommandDto
    | ImmutableScanActivationCommandDto
    | ImmutableScanSubmissionCommandDto
    | ImmutableScanUrlActivationCommandDto
    | ImmutableSendMailCommandDto
    | ImmutableSharedCommandDto
    | ImmutableUpdateScanInstanceFromTypeCommandDto
    | ImmutableUpdateTrajectoryParticipantCommandDto
    | ImmutableUpdateTrajectoryTypeCommandDto
    | ImmutableUpsertCompanyCommandDto
    | ImmutableUpsertCustomerRepresentativeCommandDto
    | ImmutableUpsertUserCommandDto;
  isVisible?: DynamicBoolValueDto;
  priority?: DynamicIntegerValueDto;
  detailedDescription?: DynamicStringValueDto;
  color?: DynamicStringValueDto;
  openModal?: DynamicBoolValueDto;
  successMessage?: DynamicStringValueDto;
  failureMessage?: DynamicStringValueDto;
  group?: DynamicStringValueDto;
  allowBulk?: boolean;
  surveyForm?: object;
  surveyFormInitialData?: DynamicDictionaryValueDto;
};
export type ImmutableMapStringManualActionDto = {
  [key: string]: ManualActionDto;
};
export type ContentTemplateDto = {
  key?: string;
  richText?: boolean;
  fallbackLocale?: string;
  content?: {
    [key: string]: string;
  };
  enabledLocales?: {
    [key: string]: boolean;
  };
  context?: RequiredContextDto;
  children?: {
    [key: string]: ContentTemplateDto;
  };
  label?: DynamicStringValueDto;
  description?: DynamicStringValueDto;
  isEditable?: DynamicBoolValueDto;
  isVisible?: DynamicBoolValueDto;
  requiredRole?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  testContext?: DynamicDictionaryValueDto;
};
export type ImmutableMapStringContentTemplateDto = {
  [key: string]: ContentTemplateDto;
};
export type VisualDto = {
  visible?: DynamicBoolValueDto;
  type: string;
};
export type ImmutableAttachmentListVisualDto = VisualDto & {
  dashboardUrl?: DynamicStringValueDto;
  attachmentName?: DynamicStringValueDto;
  options?: DynamicDictionaryValueDto;
};
export type ImmutableCardVisualDto = VisualDto & {
  title?: DynamicStringValueDto;
  visuals?: VisualCollectionDto;
  options?: DynamicDictionaryValueDto;
};
export type ImmutableChartVisualDto = VisualDto & {
  data?: DynamicDictionaryValueDto;
  options?: DynamicDictionaryValueDto;
  chartType?: DynamicStringValueDto;
  title?: DynamicStringValueDto;
};
export type ImmutableConditionalVisualDto = VisualDto & {
  condition?: DynamicBoolValueDto;
  trueVisuals?: VisualCollectionDto;
  falseVisuals?: VisualCollectionDto;
};
export type ImmutableDashboardVisualDto = VisualDto & {
  name?: DynamicStringValueDto;
  url?: DynamicStringValueDto;
};
export type ImmutableFilterVisualDto = VisualDto & {
  name?: DynamicStringValueDto;
  label?: DynamicStringValueDto;
  multiple?: DynamicBoolValueDto;
  choices?: DynamicListValueDto;
  choiceLabels?: DynamicDictionaryValueDto;
  choiceLabelForX?: DynamicStringValueDto;
  options?: DynamicDictionaryValueDto;
};
export type ImmutableForeachVisualDto = VisualDto & {
  condition?: DynamicListValueDto;
  variableName?: DynamicStringValueDto;
  visuals?: VisualCollectionDto;
};
export type GridVisualItemDto = {
  span?: DynamicIntegerValueDto;
  spanLarge?: DynamicIntegerValueDto;
  spanSmall?: DynamicIntegerValueDto;
  title?: DynamicStringValueDto;
  visuals?: VisualCollectionDto;
  visible?: DynamicBoolValueDto;
};
export type ImmutableGridVisualDto = VisualDto & {
  items?: GridVisualItemDto[];
  options?: DynamicDictionaryValueDto;
};
export type ImmutableLinkCardVisualDto = VisualDto & {
  linkTarget?: 'SURVEY' | 'ELEARNING' | 'FORM' | 'DASHBOARD' | 'URL' | 'ACTION';
  link?: DynamicStringValueDto;
  title?: DynamicStringValueDto;
  options?: DynamicDictionaryValueDto;
};
export type ImmutablePredefinedVisualDto = VisualDto & {
  key?: DynamicStringValueDto;
  data?: DynamicDictionaryValueDto;
};
export type ImmutableSurveyVisualDto = VisualDto & {
  scanUrlId?: DynamicStringValueDto;
  options?: DynamicDictionaryValueDto;
};
export type TabVisualItemDto = {
  name?: DynamicStringValueDto;
  title?: DynamicStringValueDto;
  visuals?: VisualCollectionDto;
  visible?: DynamicBoolValueDto;
};
export type ImmutableTabVisualDto = VisualDto & {
  items?: TabVisualItemDto[];
  name?: DynamicStringValueDto;
};
export type ImmutableTableVisualDto = VisualDto & {
  columns?: DynamicDictionaryValueDto;
  columnOrder?: DynamicListValueDto;
  data?: DynamicListValueDto;
  rowForX?: DynamicDictionaryValueDto;
  options?: DynamicDictionaryValueDto;
};
export type ImmutableTextVisualDto = VisualDto & {
  text?: DynamicStringValueDto;
  variant?: DynamicStringValueDto;
  align?: DynamicStringValueDto;
  options?: DynamicDictionaryValueDto;
};
export type VisualCollectionDto = {
  items?: (
    | ImmutableAttachmentListVisualDto
    | ImmutableCardVisualDto
    | ImmutableChartVisualDto
    | ImmutableConditionalVisualDto
    | ImmutableDashboardVisualDto
    | ImmutableFilterVisualDto
    | ImmutableForeachVisualDto
    | ImmutableGridVisualDto
    | ImmutableLinkCardVisualDto
    | ImmutablePredefinedVisualDto
    | ImmutableSurveyVisualDto
    | ImmutableTabVisualDto
    | ImmutableTableVisualDto
    | ImmutableTextVisualDto
  )[];
};
export type DashboardDto = {
  title?: DynamicStringValueDto;
  description?: DynamicStringValueDto;
  visuals?: VisualCollectionDto;
  data?: DynamicDictionaryValueDto;
  attachments?: {
    [key: string]: TrajectoryAttachmentDto;
  };
  actions?: ImmutableMapStringManualActionDto;
};
export type TrajectoryPropertyDto = {
  name?: DynamicStringValueDto;
  description?: DynamicStringValueDto;
  value?: DynamicObjectValueDto;
  setterAction?: DynamicStringValueDto;
};
export type TrajectoryTypeDefinitionDto = {
  startMilestone?: string;
  milestones?: {
    [key: string]: MilestoneDto;
  };
  milestonesOrder?: string[];
  enrollmentType?: ImmutableScanSubmissionEnrollmentTypeDto;
  defaultLocale?: string;
  supportedLocales?: string[];
  metaData?: {
    [key: string]: object;
  };
  visualDefinition?: object;
  typeActions?: {
    [key: string]: ManualActionDto;
  };
  instanceActions?: ImmutableMapStringManualActionDto;
  participantInstanceActions?: ImmutableMapStringManualActionDto;
  sharedCommands?: {
    [key: string]:
      | ImmutableAddAttachmentCommandDto
      | ImmutableAddMetaDataCommandDto
      | ImmutableConditionalCommandDto
      | ImmutableContextWrapperCommandDto
      | ImmutableCreateDashboardUrlCommandDto
      | ImmutableCreateProjectCommandDto
      | ImmutableCreateScanCommandDto
      | ImmutableCreateScanUrlCommandDto
      | ImmutableCreateTrajectoryInstanceCommandDto
      | ImmutableCreateTrajectoryParticipantInstanceCommandDto
      | ImmutableDataStoreCommandDto
      | ImmutableDeleteScanSubmissionCommandDto
      | ImmutableForeachCommandDto
      | ImmutableForeachParticipantCommandDto
      | ImmutableInvokeTrajectoryActionCommandDto
      | ImmutableListToExcelAttachmentCommandDto
      | ImmutableLogCommandDto
      | ImmutableMoveIndividualMilestoneCommandDto
      | ImmutableMoveMilestoneCommandDto
      | ImmutableParticipantContextWrapperCommandDto
      | ImmutablePipelineCommandDto
      | ImmutablePlannedCommandDto
      | ImmutableRenderVisualizationCommandDto
      | ImmutableReportProgressCommandDto
      | ImmutableResultCommandDto
      | ImmutableScanActivationCommandDto
      | ImmutableScanSubmissionCommandDto
      | ImmutableScanUrlActivationCommandDto
      | ImmutableSendMailCommandDto
      | ImmutableSharedCommandDto
      | ImmutableUpdateScanInstanceFromTypeCommandDto
      | ImmutableUpdateTrajectoryParticipantCommandDto
      | ImmutableUpdateTrajectoryTypeCommandDto
      | ImmutableUpsertCompanyCommandDto
      | ImmutableUpsertCustomerRepresentativeCommandDto
      | ImmutableUpsertUserCommandDto;
  };
  contentTemplates?: ImmutableMapStringContentTemplateDto;
  dashboards?: {
    [key: string]: DashboardDto;
  };
  onCreatedCommand?: CommandDto;
  onParticipantCreatedCommand?:
    | ImmutableAddAttachmentCommandDto
    | ImmutableAddMetaDataCommandDto
    | ImmutableConditionalCommandDto
    | ImmutableContextWrapperCommandDto
    | ImmutableCreateDashboardUrlCommandDto
    | ImmutableCreateProjectCommandDto
    | ImmutableCreateScanCommandDto
    | ImmutableCreateScanUrlCommandDto
    | ImmutableCreateTrajectoryInstanceCommandDto
    | ImmutableCreateTrajectoryParticipantInstanceCommandDto
    | ImmutableDataStoreCommandDto
    | ImmutableDeleteScanSubmissionCommandDto
    | ImmutableForeachCommandDto
    | ImmutableForeachParticipantCommandDto
    | ImmutableInvokeTrajectoryActionCommandDto
    | ImmutableListToExcelAttachmentCommandDto
    | ImmutableLogCommandDto
    | ImmutableMoveIndividualMilestoneCommandDto
    | ImmutableMoveMilestoneCommandDto
    | ImmutableParticipantContextWrapperCommandDto
    | ImmutablePipelineCommandDto
    | ImmutablePlannedCommandDto
    | ImmutableRenderVisualizationCommandDto
    | ImmutableReportProgressCommandDto
    | ImmutableResultCommandDto
    | ImmutableScanActivationCommandDto
    | ImmutableScanSubmissionCommandDto
    | ImmutableScanUrlActivationCommandDto
    | ImmutableSendMailCommandDto
    | ImmutableSharedCommandDto
    | ImmutableUpdateScanInstanceFromTypeCommandDto
    | ImmutableUpdateTrajectoryParticipantCommandDto
    | ImmutableUpdateTrajectoryTypeCommandDto
    | ImmutableUpsertCompanyCommandDto
    | ImmutableUpsertCustomerRepresentativeCommandDto
    | ImmutableUpsertUserCommandDto;
  onInitialParticipantsAddedCommand?:
    | ImmutableAddAttachmentCommandDto
    | ImmutableAddMetaDataCommandDto
    | ImmutableConditionalCommandDto
    | ImmutableContextWrapperCommandDto
    | ImmutableCreateDashboardUrlCommandDto
    | ImmutableCreateProjectCommandDto
    | ImmutableCreateScanCommandDto
    | ImmutableCreateScanUrlCommandDto
    | ImmutableCreateTrajectoryInstanceCommandDto
    | ImmutableCreateTrajectoryParticipantInstanceCommandDto
    | ImmutableDataStoreCommandDto
    | ImmutableDeleteScanSubmissionCommandDto
    | ImmutableForeachCommandDto
    | ImmutableForeachParticipantCommandDto
    | ImmutableInvokeTrajectoryActionCommandDto
    | ImmutableListToExcelAttachmentCommandDto
    | ImmutableLogCommandDto
    | ImmutableMoveIndividualMilestoneCommandDto
    | ImmutableMoveMilestoneCommandDto
    | ImmutableParticipantContextWrapperCommandDto
    | ImmutablePipelineCommandDto
    | ImmutablePlannedCommandDto
    | ImmutableRenderVisualizationCommandDto
    | ImmutableReportProgressCommandDto
    | ImmutableResultCommandDto
    | ImmutableScanActivationCommandDto
    | ImmutableScanSubmissionCommandDto
    | ImmutableScanUrlActivationCommandDto
    | ImmutableSendMailCommandDto
    | ImmutableSharedCommandDto
    | ImmutableUpdateScanInstanceFromTypeCommandDto
    | ImmutableUpdateTrajectoryParticipantCommandDto
    | ImmutableUpdateTrajectoryTypeCommandDto
    | ImmutableUpsertCompanyCommandDto
    | ImmutableUpsertCustomerRepresentativeCommandDto
    | ImmutableUpsertUserCommandDto;
  description?: DynamicStringValueDto;
  branding?: 'Impetus' | 'GrowthSpot';
  properties?: TrajectoryPropertyDto[];
  participantProperties?: TrajectoryPropertyDto[];
  defaultContentTemplateContext?: DynamicDictionaryValueDto;
  testMode?: boolean;
  skipParticipantCreation?: boolean;
  skipUnitSelectionOnCreation?: boolean;
  trainerDashboardNameForTrajectory?: string;
  trainerDashboardNameForTrajectoryParticipant?: string;
  adminDashboardNameForTrajectory?: string;
  adminDashboardNameForTrajectoryParticipant?: string;
  projectTypeShowcaseDashboardName?: string;
};
export type TrajectoryTypeDto = {
  id?: number;
  name?: string;
  definition?: TrajectoryTypeDefinitionDto;
  createdOn?: string;
  modifiedOn?: string;
  active?: boolean;
  tags?: string[];
};
export type TrajectoryTypeInstanceDto = {
  id?: number;
  instanceId?: string;
  trajectoryType?: TrajectoryTypeDto;
  projectId?: number;
  name?: string;
  definition?: TrajectoryTypeDefinitionDto;
  createdOn?: string;
  modifiedOn?: string;
  tags?: string[];
};
export type ImmutableMapStringObject = {
  [key: string]: object;
};
export type TrajectoryPlannedCommandMetaDto = {
  label?: DynamicStringValueDto;
  description?: DynamicStringValueDto;
};
export type TrajectoryPlannedCommandMetaViewDto = {
  label?: string;
  description?: string;
};
export type TrajectoryPlannedCommandDto = {
  id?: number;
  state?: 'SCHEDULED' | 'EXECUTED' | 'FAILED' | 'CANCELLED' | 'RUNNING' | 'ENQUEUED';
  eventId?: string;
  instanceId?: string;
  participantInstanceId?: string;
  trajectoryTypeId?: number;
  command?:
    | ImmutableAddAttachmentCommandDto
    | ImmutableAddMetaDataCommandDto
    | ImmutableConditionalCommandDto
    | ImmutableContextWrapperCommandDto
    | ImmutableCreateDashboardUrlCommandDto
    | ImmutableCreateProjectCommandDto
    | ImmutableCreateScanCommandDto
    | ImmutableCreateScanUrlCommandDto
    | ImmutableCreateTrajectoryInstanceCommandDto
    | ImmutableCreateTrajectoryParticipantInstanceCommandDto
    | ImmutableDataStoreCommandDto
    | ImmutableDeleteScanSubmissionCommandDto
    | ImmutableForeachCommandDto
    | ImmutableForeachParticipantCommandDto
    | ImmutableInvokeTrajectoryActionCommandDto
    | ImmutableListToExcelAttachmentCommandDto
    | ImmutableLogCommandDto
    | ImmutableMoveIndividualMilestoneCommandDto
    | ImmutableMoveMilestoneCommandDto
    | ImmutableParticipantContextWrapperCommandDto
    | ImmutablePipelineCommandDto
    | ImmutablePlannedCommandDto
    | ImmutableRenderVisualizationCommandDto
    | ImmutableReportProgressCommandDto
    | ImmutableResultCommandDto
    | ImmutableScanActivationCommandDto
    | ImmutableScanSubmissionCommandDto
    | ImmutableScanUrlActivationCommandDto
    | ImmutableSendMailCommandDto
    | ImmutableSharedCommandDto
    | ImmutableUpdateScanInstanceFromTypeCommandDto
    | ImmutableUpdateTrajectoryParticipantCommandDto
    | ImmutableUpdateTrajectoryTypeCommandDto
    | ImmutableUpsertCompanyCommandDto
    | ImmutableUpsertCustomerRepresentativeCommandDto
    | ImmutableUpsertUserCommandDto;
  context?: ImmutableMapStringObject;
  createdOn?: string;
  scheduledOn?: string;
  executedOn?: string;
  label?: string;
  key?: string;
  meta?: TrajectoryPlannedCommandMetaDto;
  metaView?: TrajectoryPlannedCommandMetaViewDto;
  progress?: number;
  userId?: string;
};
export type SuccessResponse = {
  success?: boolean;
  messages?: string[];
  message?: string;
};
export type ScanUrlDto = {
  id?: number;
  urlId?: string;
  data?: {
    [key: string]: object;
  };
  resumeEnabled?: boolean;
  alreadySubmitted?: boolean;
  maxSubmissions?: number;
  active?: boolean;
  publicAccessible?: boolean;
  userId?: number;
  scanId?: number;
};
export type ScanMetadataDto = {
  downloadUrl?: string;
  generatedRapportsAmount?: number;
  supervisorSubmissionCount?: number;
  employeeSubmissionCount?: number;
  totalSubmissionCount?: number;
};
export type DataTransformerStepDto = {
  description?: string;
  type: string;
};
export type NamedExpressionDto = {
  name?: string;
  expression?: string;
};
export type ImmutableCalculatedColumnsStepDto = DataTransformerStepDto & {
  columnExpressions?: {
    [key: string]: string;
  };
  columns?: NamedExpressionDto[];
};
export type ImmutableCalculatedVariableStepDto = DataTransformerStepDto & {
  variableName?: string;
  expression?: string;
};
export type ImmutableConditionalStepDto = DataTransformerStepDto & {
  condition?: string;
  trueSteps?: DataTransformerStepDto[];
  falseSteps?: DataTransformerStepDto[];
};
export type DuplicateStepItemDto = {
  name?: string;
  steps?: DataTransformerStepDto[];
};
export type ImmutableDuplicateStepDto = DataTransformerStepDto & {
  stepsList?: DataTransformerStepDto[][];
  items?: DuplicateStepItemDto[];
};
export type ImmutableExpressionColumnStepDto = DataTransformerStepDto & {
  name?: string;
  expressions?: CaseExpressionDto[];
};
export type ImmutableFilterStepDto = DataTransformerStepDto & {
  expression?: string;
};
export type ImmutableGroupFilterStepDto = DataTransformerStepDto & {
  rowCountExpressions?: {
    [key: string]: string;
  };
  groupByFieldName?: string;
  rowFilterExpression?: string;
};
export type ImmutableJoinStepDto = DataTransformerStepDto & {
  baseRowExpression?: string;
  joinFieldName?: string;
  isJoinedFieldName?: string;
  joinIdTargetFieldName?: string;
  baseRowIdField?: string;
  baseRowIdFilterExpression?: string;
};
export type ImmutableKeepColumnsStepDto = DataTransformerStepDto & {
  columnNames?: string[];
};
export type ImmutableNoopStepDto = DataTransformerStepDto;
export type ImmutableParticipantDataStepDto = DataTransformerStepDto;
export type ImmutablePinnedColumnsStepDto = DataTransformerStepDto & {
  columns?: string[];
};
export type ImmutablePresetStepDto = DataTransformerStepDto & {
  key?: string;
};
export type QuerystringFilterOptionDto = {
  name?: string;
  fieldName?: string;
};
export type ImmutableQuerystringFilterStepDto = DataTransformerStepDto & {
  allowAllMatchingFields?: boolean;
  queryStringExpression?: string;
  options?: QuerystringFilterOptionDto[];
};
export type ImmutableRemoveColumnsStepDto = DataTransformerStepDto & {
  columnNames?: string[];
};
export type ImmutableRowCountVariableStepDto = DataTransformerStepDto & {
  variableName?: string;
  countRowConditionExpression?: string;
};
export type ConditionalValueExpressionDto = {
  conditionExpression?: string;
  valueExpression?: string;
};
export type ImmutableSubGroupStepDto = DataTransformerStepDto & {
  rowSubGroupExpressions?: ConditionalValueExpressionDto[];
  rowBelongsToSubGroupExpressions?: ConditionalValueExpressionDto[];
  groupByFieldName?: string;
  subGroupTargetFieldName?: string;
};
export type ImmutableUniqueValuesVariableStepDto = DataTransformerStepDto & {
  variableName?: string;
  rowValueExpression?: string;
};
export type DataTransformerRequestDto = {
  steps?: (
    | ImmutableCalculatedColumnsStepDto
    | ImmutableCalculatedVariableStepDto
    | ImmutableConditionalStepDto
    | ImmutableDuplicateStepDto
    | ImmutableExpressionColumnStepDto
    | ImmutableFilterStepDto
    | ImmutableGroupFilterStepDto
    | ImmutableJoinStepDto
    | ImmutableKeepColumnsStepDto
    | ImmutableNoopStepDto
    | ImmutableParticipantDataStepDto
    | ImmutablePinnedColumnsStepDto
    | ImmutablePresetStepDto
    | ImmutableQuerystringFilterStepDto
    | ImmutableRemoveColumnsStepDto
    | ImmutableRowCountVariableStepDto
    | ImmutableSubGroupStepDto
    | ImmutableUniqueValuesVariableStepDto
  )[];
  variables?: ImmutableMapStringObject;
  pinnedColumns?: string[];
};
export type SurveyDefinitionDto = {
  definition?: {
    [key: string]: object;
  };
  supportedLocales?: {
    [key: string]: boolean;
  };
  joins?: string[];
  transformers?: {
    [key: string]: DataTransformerRequestDto;
  };
  typeVersion?: number;
  instanceVersion?: number;
};
export type ScanDto = {
  id?: number;
  name?: string;
  projectId?: number;
  scanTypeId?: number;
  intro?: string;
  language?: string;
  scanUrls?: ScanUrlDto[];
  downloadUuid?: string;
  scanMetadata?: ScanMetadataDto;
  survey?: SurveyDefinitionDto;
  clientLogoVisible?: boolean;
  clientNameVisible?: boolean;
  projectTitleVisible?: boolean;
  active?: boolean;
  numberOfEmployeeEntries?: number;
  numberOfSupervisorEntries?: number;
  participantSummary?: ParticipantsSummaryDto;
  totalNumberOfEntries?: number;
  numberOfInfographics?: number;
  generatedRapportsCount?: number;
  publicId?: string;
  tags?: string[];
  modifiedOn?: string;
};
export type UpdateScanUrlDto = {
  id?: number;
  urlId?: string;
  data?: {
    [key: string]: object;
  };
  resumeEnabled?: boolean;
  alreadySubmitted?: boolean;
  maxSubmissions?: number;
  active?: boolean;
  publicAccessible?: boolean;
  userId?: number;
};
export type ScanTypeDto = {
  id?: number;
  name?: string;
  survey?: SurveyDefinitionDto;
  createdOn?: string;
  modifiedOn?: string;
  configuration?: ImmutableMapStringObject;
  active?: boolean;
  tags?: string[];
  public?: boolean;
};
export type ScanSubmissionDataDto = {
  values?: {
    [key: string]: object;
  };
};
export type ScanSubmissionDto = {
  id?: number;
  scanId?: number;
  scanUrlId?: number;
  data?: ScanSubmissionDataDto;
  page?: number;
  totalPages?: number;
  progress?: number;
  state?: 'PENDING' | 'SUBMITTED';
  remoteIPAddress?: string;
  machineId?: string;
  partialSubmissionId?: string;
  surveyHash?: string;
  createdOn?: string;
  modifiedOn?: string;
};
export type ProjectDto = {
  id?: number;
  publicId?: string;
  customerId?: number;
  name?: string;
  customerRepresentativeId?: number;
  startDate?: string;
  endDate?: string;
  comments?: string;
  creationCompleted?: boolean;
  lastCompletedCreationStage?: 'BASIC_INFORMATION' | 'ORGANIZATIONAL_STRUCTURE' | 'COMPLETED';
  active?: boolean;
  organizationalUnits?: ProjectOrganizationalUnitDto[];
  projectTypeId?: number;
  modifiedOn?: string;
  tags?: string[];
};
export type BasicProjectInformationDto = {
  name: string;
  customerId: number;
  customerRepresentativeId: number;
  startDate: string;
  endDate?: string;
  comments?: string;
  organizationalUnits?: ProjectOrganizationalUnitDto[];
  projectTypeId?: number;
  participants?: string[];
  parameters?: {
    [key: string]: object;
  };
};
export type ProjectTypeDto = {
  id?: number;
  code?: string;
  trajectoryTypeId?: number;
  createAction?: string;
  active?: boolean;
  publicVisible?: boolean;
  createdOn?: string;
  modifiedOn?: string;
};
export type DataTransformerPresetDto = {
  id?: number;
  key?: string;
  name?: string;
  steps?: (
    | ImmutableCalculatedColumnsStepDto
    | ImmutableCalculatedVariableStepDto
    | ImmutableConditionalStepDto
    | ImmutableDuplicateStepDto
    | ImmutableExpressionColumnStepDto
    | ImmutableFilterStepDto
    | ImmutableGroupFilterStepDto
    | ImmutableJoinStepDto
    | ImmutableKeepColumnsStepDto
    | ImmutableNoopStepDto
    | ImmutableParticipantDataStepDto
    | ImmutablePinnedColumnsStepDto
    | ImmutablePresetStepDto
    | ImmutableQuerystringFilterStepDto
    | ImmutableRemoveColumnsStepDto
    | ImmutableRowCountVariableStepDto
    | ImmutableSubGroupStepDto
    | ImmutableUniqueValuesVariableStepDto
  )[];
  createdOn?: string;
  modifiedOn?: string;
};
export type DashboardUrlDto = {
  id?: number;
  urlId?: string;
  instanceId?: string;
  participantInstanceId?: string;
  trajectoryPath?: string;
  createdOn?: string;
  modifiedOn?: string;
  data?: {
    [key: string]: object;
  };
  active?: boolean;
  publicAccessible?: boolean;
  showOnMainDashboard?: boolean;
  userId?: number;
};
export type CreditBaseDto = {
  id?: number;
  balance?: number;
  balancePrice?: number;
};
export type CompanyWithCreditDto = {
  name?: string;
  description?: string;
  vatNumber?: string;
  isSingleCustomerCompany?: boolean;
  canCreateProjects?: boolean;
  canManageOrganizationalStructure?: boolean;
  tags?: string[];
  id?: number;
  publicId?: string;
  keycloakGroupId?: string;
  createdOn?: string;
  modifiedOn?: string;
  credit?: CreditBaseDto;
  invoiceAddress?: InvoiceAddressDto;
};
export type CreditUpdateDto = {
  companyId: number;
  creditChange: number;
  pricePerCredit?: number;
  description: string;
};
export type CompanyCreateDto = {
  name?: string;
  description?: string;
  invoiceAddress?: InvoiceAddressDto;
  vatNumber?: string;
  isSingleCustomerCompany?: boolean;
  canCreateProjects?: boolean;
  canManageOrganizationalStructure?: boolean;
  tags?: string[];
};
export type ContentTemplateViewDto = {
  key?: string;
  label?: string;
  description?: string;
  isVisible?: boolean;
  isEditable?: boolean;
  children?: {
    [key: string]: ContentTemplateViewDto;
  };
  contentTemplate?: ContentTemplateDto;
};
export type ContentTemplateUpdateDto = {
  content?: {
    [key: string]: string;
  };
  enabledLocales?: {
    [key: string]: boolean;
  };
};
export type EmitTrajectoryInstanceEventFunction = object;
export type TrajectoryAttachmentItem = {
  label?: string;
  key?: string;
  path?: string;
  container?: string;
};
export type StepParticipantInstance = {
  name?: string;
};
export type IndividualMilestoneParticipantInstance = {
  name?: string;
  number?: number;
  steps?: {
    [key: string]: StepParticipantInstance;
  };
};
export type MilestoneParticipantInstance = {
  number?: number;
  currentIndividualMilestone?: string;
  individualMilestones?: {
    [key: string]: IndividualMilestoneParticipantInstance;
  };
  name?: string;
};
export type TrajectoryLogItem = {
  message?: string;
  timestamp?: string;
  severity?: 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR' | 'SUCCESS';
};
export type TrajectoryInstanceEvent = {
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  eventId?: string;
  projectId?: number;
  trajectoryTypeId?: number;
  createdOn?: string;
  instanceId?: string;
  participantInstanceId?: string;
  keycloakUserId?: string;
};
export type TemplateValue = {
  context?: RequiredContext;
  templateName?: DynamicStringValue;
};
export type DynamicStringValue = {
  template?: TemplateValue;
  valuePresent?: boolean;
  value?: string;
  expression?: Expression;
};
export type TrajectoryAttachment = {
  name?: string;
};
export type DynamicListValue = {
  value?: object[];
  expression?: Expression;
  valuePresent?: boolean;
};
export type Command = {
  name?: string;
  description?: string;
  nextCommand?: Command;
};
export type SendMailCommand = {
  subject?: DynamicStringValue;
  message?: DynamicStringValue;
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  data?: DynamicDictionaryValue;
  attachments?: TrajectoryAttachment[];
  templateId?: DynamicStringValue;
  recipients?: DynamicListValue;
  name?: string;
  description?: string;
  nextCommand?: Command;
};
export type MailRecipient = {
  name?: string;
  email?: string;
};
export type MailSentEvent = {
  data?: {
    [key: string]: object;
  };
  success?: boolean;
  messageId?: string;
  templateId?: string;
  resendCommand?: SendMailCommand;
  attachmentFiles?: string[];
  recipients?: MailRecipient[];
  testMode?: boolean;
  target?: 'INSTANCE' | 'PARTICIPANT_INSTANCE';
  eventId?: string;
  projectId?: number;
  trajectoryTypeId?: number;
  createdOn?: string;
  instanceId?: string;
  participantInstanceId?: string;
  keycloakUserId?: string;
};
export type TrajectoryParticipantInstance = {
  name?: string;
  id?: string;
  locale?: string;
  lastModified?: string;
  active?: boolean;
  email?: string;
  metaData?: {
    [key: string]: object;
  };
  firstname?: string;
  attachments?: {
    [key: string]: TrajectoryAttachmentItem;
  };
  currentIndividualMilestonePath?: string;
  currentMilestone?: string;
  currentMilestoneNumber?: number;
  milestones?: {
    [key: string]: MilestoneParticipantInstance;
  };
  participantId?: number;
  trajectoryInstanceId?: string;
  lastname?: string;
  logItems?: TrajectoryLogItem[];
  participantEvents?: TrajectoryInstanceEvent[];
  participantMetaData?: {
    [key: string]: object;
  };
  sentMails?: MailSentEvent[];
};
export type AddContextForNextCommandFunction = object;
export type EmitExternalProcessCommandFunction = object;
export type EmitTrajectoryParticipantInstanceEventFunction = object;
export type EmitResultFunction = object;
export type StepInstance = {
  name?: string;
};
export type IndividualMilestoneSharedInstance = {
  name?: string;
  steps?: {
    [key: string]: StepInstance;
  };
};
export type MilestoneInstance = {
  number?: number;
  startIndividualMilestone?: string;
  individualMilestones?: {
    [key: string]: IndividualMilestoneSharedInstance;
  };
  name?: string;
};
export type TrajectoryInstance = {
  name?: string;
  id?: string;
  lastModified?: string;
  events?: TrajectoryInstanceEvent[];
  active?: boolean;
  metaData?: {
    [key: string]: object;
  };
  defaultLocale?: string;
  projectId?: number;
  attachments?: {
    [key: string]: TrajectoryAttachmentItem;
  };
  currentMilestone?: string;
  currentMilestoneNumber?: number;
  milestones?: {
    [key: string]: MilestoneInstance;
  };
  trajectoryTypeId?: number;
  logItems?: TrajectoryLogItem[];
  mails?: MailSentEvent[];
};
export type DynamicObjectValue = {
  template?: TemplateValue;
  value?: object;
  expression?: Expression;
  valuePresent?: boolean;
};
export type TrajectoryProperty = {
  name?: DynamicStringValue;
  value?: DynamicObjectValue;
  description?: DynamicStringValue;
  setterAction?: DynamicStringValue;
};
export type DynamicIntegerValue = {
  value?: number;
  expression?: Expression;
  valuePresent?: boolean;
};
export type DynamicBoolValue = {
  value?: boolean;
  expression?: Expression;
  valuePresent?: boolean;
};
export type ManualAction = {
  priority?: DynamicIntegerValue;
  group?: DynamicStringValue;
  color?: DynamicStringValue;
  command?: Command;
  actionLabel?: DynamicStringValue;
  actionIcon?: DynamicStringValue;
  requiredRole?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  isVisible?: DynamicBoolValue;
  detailedDescription?: DynamicStringValue;
  openModal?: DynamicBoolValue;
  allowBulk?: boolean;
  surveyForm?: object;
  surveyFormInitialData?: DynamicDictionaryValue;
  availabilityExpression?: DynamicBoolValue;
  successMessage?: DynamicStringValue;
  failureMessage?: DynamicStringValue;
};
export type Step = {
  name?: string;
  title?: DynamicStringValue;
  description?: DynamicStringValue;
};
export type IndividualMilestone = {
  icon?: DynamicStringValue;
  label?: DynamicStringValue;
  number?: number;
  color?: DynamicStringValue;
  steps?: {
    [key: string]: Step;
  };
  isVisible?: DynamicBoolValue;
  stepsOrder?: string[];
  name?: string;
};
export type Milestone = {
  icon?: DynamicStringValue;
  label?: DynamicStringValue;
  number?: number;
  individualMilestonesOrder?: string[];
  startIndividualMilestone?: string;
  color?: DynamicStringValue;
  steps?: {
    [key: string]: Step;
  };
  isVisible?: DynamicBoolValue;
  individualMilestones?: {
    [key: string]: IndividualMilestone;
  };
  name?: string;
};
export type ContentTemplate = {
  label?: DynamicStringValue;
  key?: string;
  context?: RequiredContext;
  content?: {
    [key: string]: string;
  };
  fallbackLocale?: string;
  description?: DynamicStringValue;
  richText?: boolean;
  enabledLocales?: {
    [key: string]: boolean;
  };
  requiredRole?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  isVisible?: DynamicBoolValue;
  isEditable?: DynamicBoolValue;
  testContext?: DynamicDictionaryValue;
};
export type Visual = {
  visible?: DynamicBoolValue;
};
export type Dashboard = {
  actions?: {
    [key: string]: ManualAction;
  };
  data?: DynamicDictionaryValue;
  title?: DynamicStringValue;
  attachments?: {
    [key: string]: TrajectoryAttachment;
  };
  description?: DynamicStringValue;
  visuals?: Visual[];
};
export type EnrollmentType = object;
export type TrajectoryTypeDefinition = {
  properties?: TrajectoryProperty[];
  metaData?: {
    [key: string]: object;
  };
  supportedLocales?: string[];
  defaultLocale?: string;
  description?: DynamicStringValue;
  defaultContentTemplateContext?: DynamicDictionaryValue;
  onParticipantCreatedCommand?: Command;
  participantInstanceActions?: {
    [key: string]: ManualAction;
  };
  onInitialParticipantsAddedCommand?: Command;
  trainerDashboardNameForTrajectory?: string;
  adminDashboardNameForTrajectory?: string;
  skipUnitSelectionOnCreation?: boolean;
  projectTypeShowcaseDashboardName?: string;
  trainerDashboardNameForTrajectoryParticipant?: string;
  adminDashboardNameForTrajectoryParticipant?: string;
  milestones?: {
    [key: string]: Milestone;
  };
  contentTemplates?: {
    [key: string]: ContentTemplate;
  };
  sharedCommands?: {
    [key: string]: Command;
  };
  onCreatedCommand?: Command;
  typeActions?: {
    [key: string]: ManualAction;
  };
  instanceActions?: {
    [key: string]: ManualAction;
  };
  dashboards?: {
    [key: string]: Dashboard;
  };
  milestonesOrder?: string[];
  participantProperties?: TrajectoryProperty[];
  skipParticipantCreation?: boolean;
  enrollmentType?: EnrollmentType;
  visualDefinition?: object;
  branding?: 'Impetus' | 'GrowthSpot';
  testMode?: boolean;
  startMilestone?: string;
};
export type TrajectoryType = {
  name?: string;
  id?: number;
  definition?: TrajectoryTypeDefinition;
  active?: boolean;
  tags?: string[];
  projectId?: number;
  createdOn?: string;
  modifiedOn?: string;
};
export type EmitCommandFunction = object;
export type EmitProgressFunction = object;
export type CommandHandler = object;
export type SkipNextCommandFunction = object;
export type LogbookLogger = object;
export type ProcessCommandContext = {
  trajectoryInstanceEventEmitter?: EmitTrajectoryInstanceEventFunction;
  trajectoryParticipantInstance?: TrajectoryParticipantInstance;
  addContextForNextCommandFunction?: AddContextForNextCommandFunction;
  emitExternalProcessCommandFunction?: EmitExternalProcessCommandFunction;
  trajectoryParticipantInstanceEventEmitter?: EmitTrajectoryParticipantInstanceEventFunction;
  resultEmitter?: EmitResultFunction;
  trajectoryInstance?: TrajectoryInstance;
  trajectoryType?: TrajectoryType;
  commandEmitter?: EmitCommandFunction;
  commandContext?: {
    [key: string]: object;
  };
  keycloakUserId?: string;
  emitProgressFunction?: EmitProgressFunction;
  commandHandler?: CommandHandler;
  skipNextCommandFunction?: SkipNextCommandFunction;
  logbookLogger?: LogbookLogger;
};
export type WrappedCommandExecutionException = {
  cause?: {
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      nativeMethod?: boolean;
      className?: string;
    }[];
    message?: string;
    localizedMessage?: string;
  };
  stackTrace?: {
    classLoaderName?: string;
    moduleName?: string;
    moduleVersion?: string;
    methodName?: string;
    fileName?: string;
    lineNumber?: number;
    nativeMethod?: boolean;
    className?: string;
  }[];
  exception?: {
    cause?: {
      stackTrace?: {
        classLoaderName?: string;
        moduleName?: string;
        moduleVersion?: string;
        methodName?: string;
        fileName?: string;
        lineNumber?: number;
        nativeMethod?: boolean;
        className?: string;
      }[];
      message?: string;
      localizedMessage?: string;
    };
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      nativeMethod?: boolean;
      className?: string;
    }[];
    message?: string;
    suppressed?: {
      stackTrace?: {
        classLoaderName?: string;
        moduleName?: string;
        moduleVersion?: string;
        methodName?: string;
        fileName?: string;
        lineNumber?: number;
        nativeMethod?: boolean;
        className?: string;
      }[];
      message?: string;
      localizedMessage?: string;
    }[];
    localizedMessage?: string;
  };
  context?: ProcessCommandContext;
  command?: Command;
  message?: string;
  suppressed?: {
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      nativeMethod?: boolean;
      className?: string;
    }[];
    message?: string;
    localizedMessage?: string;
  }[];
  localizedMessage?: string;
};
export type CommandExecutionResultDto = {
  result?: object;
  message?: string;
  exceptions?: WrappedCommandExecutionException[];
  success?: boolean;
};
export type BulkActionBodyDto = {
  parameters?: {
    [key: string]: object;
  };
  participantIds?: string[];
};
export type SignUpOrSignInResponseDto = {
  version?: string;
  action?: string;
  userMessage?: string;
};
export type SignInIdentityDto = {
  signInType?: string;
  issuer?: string;
  issuerAssignedId?: string;
};
export type SignUpOrSignInRequestDto = {
  step?: string;
  client_id?: string;
  email?: string;
  objectId?: string;
  surname?: string;
  displayName?: string;
  givenName?: string;
  identities?: SignInIdentityDto[];
};
export type SubmitScanSubmissionDto = {
  data?: ScanSubmissionDataDto;
  page?: number;
  totalPages?: number;
  progress?: number;
  machineId?: string;
  previousPartialSubmissionId?: string;
  surveyHash?: string;
  partialSubmissionId?: string;
};
export type DataReportChart = {
  name?: string;
  map?: {
    [key: string]: object;
  };
  values?: number[][];
  series?: string[];
  categories?: string[];
  seriesKeys?: string[];
  categoriesKeys?: string[];
  presentationValues?: string[][];
};
export type DataReport = {
  name?: string;
  variables?: {
    [key: string]: object;
  };
  charts?: DataReportChart[];
  translations?: {
    [key: string]: string;
  };
};
export type DataReportContainerDto = {
  dataReports?: DataReport[];
};
export type ImmutableMapStringString = {
  [key: string]: string;
};
export type VisualizationRequestDto = {
  visualizationId?: number;
  options?: {
    [key: string]: string;
  };
  variables?: ImmutableMapStringString;
  dataReportName?: string;
};
export type CloneVisualizationDto = {
  name?: string;
};
export type ScanForListDto = {
  id?: number;
  name?: string;
  projectId?: number;
  scanTypeId?: number;
  tags?: string[];
  active?: boolean;
  publicId?: string;
  createdOn?: string;
  modifiedOn?: string;
};
export type SortObject = {
  unsorted?: boolean;
  sorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  unpaged?: boolean;
  pageNumber?: number;
  pageSize?: number;
};
export type PageScanForListDto = {
  totalPages?: number;
  totalElements?: number;
  last?: boolean;
  size?: number;
  content?: ScanForListDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  numberOfElements?: number;
  pageable?: PageableObject;
};
export type CreateScanDto = {
  name: string;
  ScanType: number;
  projectId: number;
  logoClient?: boolean;
  nameClient?: boolean;
  titleProject?: boolean;
  language?: string;
  intro: string;
};
export type CreateScanUrlDto = {
  id?: number;
  urlId?: string;
  data?: {
    [key: string]: object;
  };
  resumeEnabled?: boolean;
  alreadySubmitted?: boolean;
  maxSubmissions?: number;
  active?: boolean;
  publicAccessible?: boolean;
  userId?: number;
  scanId?: number;
};
export type OverviewProjectDto = {
  id?: number;
  publicId?: string;
  name?: string;
  creationCompleted?: boolean;
  lastCompletedCreationStage?: 'BASIC_INFORMATION' | 'ORGANIZATIONAL_STRUCTURE' | 'COMPLETED';
  active?: boolean;
  numberOfSurveys?: number;
  modifiedOn?: string;
  customer?: CustomerDto;
  tags?: string[];
};
export type StepViewDto = {
  title?: string;
  description?: string;
  type: string;
};
export type ImmutableDashboardStepViewDto = StepViewDto;
export type ImmutableScanStepViewDto = StepViewDto;
export type ImmutableVisualizationStepViewDto = StepViewDto;
export type IndividualMilestoneViewDto = {
  steps?: {
    [key: string]:
      | ImmutableDashboardStepViewDto
      | ImmutableScanStepViewDto
      | ImmutableVisualizationStepViewDto;
  };
  stepsOrder?: string[];
  isVisible?: boolean;
  label?: string;
  color?: string;
  icon?: string;
};
export type MilestoneViewDto = {
  individualMilestones?: {
    [key: string]: IndividualMilestoneViewDto;
  };
  individualMilestonesOrder?: string[];
  steps?: {
    [key: string]:
      | ImmutableDashboardStepViewDto
      | ImmutableScanStepViewDto
      | ImmutableVisualizationStepViewDto;
  };
  isVisible?: boolean;
  label?: string;
  color?: string;
  icon?: string;
};
export type TrajectoryPropertyViewDto = {
  name?: string;
  description?: string;
  value?: object;
  setterAction?: string;
};
export type TrajectoryTypeDefinitionViewDto = {
  milestones?: {
    [key: string]: MilestoneViewDto;
  };
  milestonesOrder?: string[];
  description?: string;
  properties?: TrajectoryPropertyViewDto[];
  defaultLocale?: string;
  supportedLocales?: string[];
  visualizationPaths?: string[];
};
export type TrajectoryInstanceProjectionDto = {
  instanceId?: string;
  name?: string;
  trajectoryTypeId?: number;
  projectId?: number;
  currentMilestone?: string;
  currentMilestoneNumber?: number;
  defaultLocale?: string;
  active?: boolean;
  createdOn?: string;
  modifiedOn?: string;
  milestones?: {
    [key: string]: MilestoneInstanceDto;
  };
  metaData?: {
    [key: string]: object;
  };
};
export type TrajectoryParticipantInstanceProjectionForListDto = {
  instanceId?: string;
  participantInstanceId?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  participantId?: number;
  currentMilestone?: string;
  currentIndividualMilestonePath?: string;
  currentMilestoneNumber?: number;
  locale?: string;
  active?: boolean;
  createdOn?: string;
  modifiedOn?: string;
  metaData?: {
    [key: string]: object;
  };
};
export type TrajectoryParticipantInstanceViewDto = {
  properties?: TrajectoryPropertyViewDto[];
};
export type VisualViewDto = {
  visible?: boolean;
  type: string;
};
export type ImmutableAttachmentListVisualViewDto = VisualViewDto & {
  dashboardUrl?: string;
  attachmentName?: string;
  options?: {
    [key: string]: object;
  };
};
export type VisualViewCollectionDto = {
  items?: VisualViewDto[];
};
export type ImmutableCardVisualViewDto = VisualViewDto & {
  title?: string;
  visuals?: VisualViewCollectionDto;
  options?: {
    [key: string]: object;
  };
};
export type ImmutableChartVisualViewDto = VisualViewDto & {
  data?: {
    [key: string]: object;
  };
  options?: {
    [key: string]: object;
  };
  chartType?: string;
  title?: string;
};
export type ImmutableDashboardVisualViewDto = VisualViewDto & {
  visuals?: VisualViewCollectionDto;
  url?: string;
};
export type ImmutableFilterVisualViewDto = VisualViewDto & {
  name?: string;
  label?: string;
  options?: {
    [key: string]: object;
  };
  multiple?: boolean;
  choices?: string[];
  choiceLabels?: {
    [key: string]: string;
  };
};
export type GridVisualItemViewDto = {
  span?: number;
  spanLarge?: number;
  spanSmall?: number;
  title?: string;
  test?: string;
  visuals?: VisualViewCollectionDto;
  visible?: boolean;
};
export type ImmutableGridVisualViewDto = VisualViewDto & {
  items?: GridVisualItemViewDto[];
  options?: {
    [key: string]: object;
  };
};
export type ImmutableLinkCardVisualViewDto = VisualViewDto & {
  linkTarget?: 'SURVEY' | 'ELEARNING' | 'FORM' | 'DASHBOARD' | 'URL' | 'ACTION';
  link?: string;
  title?: string;
  options?: {
    [key: string]: object;
  };
};
export type ImmutablePredefinedVisualViewDto = VisualViewDto & {
  key?: string;
  data?: {
    [key: string]: object;
  };
};
export type ImmutableSurveyVisualViewDto = VisualViewDto & {
  surveyView?: object;
  options?: {
    [key: string]: object;
  };
};
export type TabVisualItemViewDto = {
  name?: string;
  title?: string;
  visuals?: VisualViewCollectionDto;
  visible?: boolean;
};
export type ImmutableTabVisualViewDto = VisualViewDto & {
  items?: TabVisualItemViewDto[];
  name?: string;
};
export type ImmutableTableVisualViewDto = VisualViewDto & {
  columns?: {
    [key: string]: object;
  };
  columnOrder?: string[];
  data?: object[];
  options?: {
    [key: string]: object;
  };
};
export type ImmutableTextVisualViewDto = VisualViewDto & {
  text?: string;
  variant?: string;
  align?: string;
  options?: {
    [key: string]: object;
  };
};
export type ManualActionViewDto = {
  actionLabel?: string;
  actionIcon?: string;
  requiredRole?: 'GUEST' | 'USER' | 'TRAINER' | 'ORGANIZATION_ADMIN' | 'ADMIN';
  isVisible?: boolean;
  priority?: number;
  detailedDescription?: string;
  color?: string;
  openModal?: boolean;
  successMessage?: string;
  failureMessage?: string;
  group?: string;
  allowBulk?: boolean;
  surveyForm?: object;
  surveyFormInitialData?: {
    [key: string]: object;
  };
};
export type DashboardViewDto = {
  title?: string;
  description?: string;
  visuals?: (
    | ImmutableAttachmentListVisualViewDto
    | ImmutableCardVisualViewDto
    | ImmutableChartVisualViewDto
    | ImmutableDashboardVisualViewDto
    | ImmutableFilterVisualViewDto
    | ImmutableGridVisualViewDto
    | ImmutableLinkCardVisualViewDto
    | ImmutablePredefinedVisualViewDto
    | ImmutableSurveyVisualViewDto
    | ImmutableTabVisualViewDto
    | ImmutableTableVisualViewDto
    | ImmutableTextVisualViewDto
  )[];
  urlId?: string;
  available?: boolean;
  actions?: {
    [key: string]: ManualActionViewDto;
  };
};
export type ManualActionBulkViewDto = {
  manualAction?: ManualActionViewDto;
  participantIds?: string[];
};
export type ScanSubmissionOverviewDto = {
  scanPublicId?: string;
  scanName?: string;
  projectPublicId?: string;
  projectName?: string;
  customerPublicId?: string;
  customerName?: string;
  companyPublicId?: string;
  companyName?: string;
  cnt?: number;
  latestSubmission?: string;
};
export type ScanSubmissionCountPerDayDto = {
  day?: string;
  count?: number;
};
export type DetailedProjectDto = {
  id?: number;
  publicId?: string;
  customerId?: number;
  name?: string;
  customerRepresentativeId?: number;
  startDate?: string;
  endDate?: string;
  comments?: string;
  creationCompleted?: boolean;
  lastCompletedCreationStage?: 'BASIC_INFORMATION' | 'ORGANIZATIONAL_STRUCTURE' | 'COMPLETED';
  active?: boolean;
  organizationalUnits?: ProjectOrganizationalUnitDto[];
  projectTypeId?: number;
  modifiedOn?: string;
  tags?: string[];
};
export type SurveyViewDto = {
  id?: number;
  survey?: SurveyDefinitionDto;
  initialData?: ImmutableMapStringObject;
  resumeEnabled?: boolean;
  partialSubmissionId?: string;
  surveyHash?: string;
};
export type ValidPartialSubmissionIdResponseDto = {
  isValid?: boolean;
};
export type SurveyUploadUrlDto = {
  url?: string;
  blobName?: string;
  containerName?: string;
};
export type DashboardTitleViewDto = {
  title?: string;
  description?: string;
};
export type AttachmentListItemDto = {
  filename?: string;
};
export type OverviewCustomerDto = {
  id?: number;
  publicId?: string;
  name?: string;
  logoUrl?: string;
  registrationCompleted?: boolean;
  lastCompletedRegistrationStage?:
    | 'BASIC_INFORMATION'
    | 'CUSTOMER_REPRESENTATIVE'
    | 'BILLING_INFORMATION'
    | 'ORGANIZATIONAL_STRUCTURE';
};
export type ProjectTypeWithViewDto = {
  id?: number;
  code?: string;
  trajectoryTypeId?: number;
  createAction?: string;
  active?: boolean;
  publicVisible?: boolean;
  createdOn?: string;
  modifiedOn?: string;
  showcaseDashboardView?: DashboardViewDto;
  createActionView?: ManualActionViewDto;
};
export type AzureB2CUserIdentityDto = {
  issuer?: string;
  issuerAssignedId?: string;
  signInType?: string;
};
export type AzureB2CUserDto = {
  email?: string;
  firstname?: string;
  lastname?: string;
  publicUserId?: string;
  identities?: AzureB2CUserIdentityDto[];
};
export type StringResponse = {
  value?: string;
};
export type TrajectoryInstanceEventOverviewDto = {
  event?:
    | ImmutableAddMetadataEventDto
    | ImmutableAttachmentAddedEventDto
    | ImmutableDashboardUrlAddedEventDto
    | ImmutableDataStoredEventDto
    | ImmutableLogbookTrajectoryEventDto
    | ImmutableMailSentEventDto
    | ImmutableMoveIndividualMilestoneEventDto
    | ImmutableMoveMilestoneEventDto
    | ImmutablePlannedCommandEventDto
    | ImmutableScanActivationEventDto
    | ImmutableScanCreatedEventDto
    | ImmutableScanSubmissionDeletedEventDto
    | ImmutableScanSubmissionEventDto
    | ImmutableScanUrlActivationEventDto
    | ImmutableScanUrlAddedEventDto
    | ImmutableTrajectoryInstanceCreatedEventDto
    | ImmutableTrajectoryParticipantInstanceCreatedEventDto
    | ImmutableTrajectoryParticipantUpdatedEventDto
    | ImmutableVisualizationsCreatedEventDto;
  companyId?: number;
  customerId?: number;
  firstname?: string;
  projectId?: number;
  lastname?: string;
  projectPublicId?: string;
  projectName?: string;
  customerPublicId?: string;
  customerName?: string;
  companyPublicId?: string;
  companyName?: string;
};
export type PageScanSubmissionDto = {
  totalPages?: number;
  totalElements?: number;
  last?: boolean;
  size?: number;
  content?: ScanSubmissionDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  numberOfElements?: number;
  pageable?: PageableObject;
};
export type DataTransformerResultDto = {
  result?: {
    [key: string]: object;
  }[];
  removedRowCount?: number;
  pinnedColumns?: string[];
  variables?: {
    [key: string]: object;
  };
};
export type ScanAttachmentUrlDto = {
  url?: string;
};
export type AssetManagementUrlDto = {
  url?: string;
  containerName?: string;
};
export type MailEventDto = {
  event_name?: string;
  processed?: string;
  properties?: {
    [key: string]: string;
  };
};
export type DetailedMailStatusDto = {
  from_email?: string;
  msg_id?: string;
  subject?: string;
  to_email?: string;
  status?: 'delivered' | 'not_delivered' | 'processing';
  template_id?: string;
  events?: MailEventDto[];
};
export type MailStatusListDto = {
  fromEmail?: string;
  messageId?: string;
  subject?: string;
  toEmail?: string;
  status?: 'delivered' | 'not_delivered' | 'processing';
  opens?: number;
  clicks?: number;
  lastEventTime?: string;
};
export type CreditTransactionDto = {
  id?: number;
  description?: string;
  byUser?: string;
  transactionDate?: string;
  pricePerCredit?: number;
  creditChange?: number;
  newBalance?: number;
  price?: number;
};
export type CreditDto = {
  id?: number;
  balance?: number;
  balancePrice?: number;
  transactions?: CreditTransactionDto[];
};
export const {
  useUserUpdateUserMutation,
  useTrajectoryGetProjectParticipantsToAddTrajectoryQuery,
  useTrajectoryAddProjectParticipantsToTrajectoryMutation,
  useProjectUpdateProjectDetailsByPublicIdMutation,
  useProjectUpdateProjectOrganizationalUnitsByPublicIdMutation,
  useCustomerOrganizationalStructureGetCustomerOrganizationalStructureByAccessKeyQuery,
  useCustomerOrganizationalStructureUpdateCustomerOrganizationalStructureByAccessKeyMutation,
  useCustomerParticipantUpdateParticipantMutation,
  useCustomerParticipantCreateParticipantMutation,
  useCustomerParticipantGetAllParticipantsByCustomerPublicIdQuery,
  useCustomerParticipantAddParticipantsByPublicIdMutation,
  useCustomerOrganizationalStructureGetOrganizationalStructureForCustomerByPublicIdQuery,
  useCustomerOrganizationalStructureSaveOrganizationStructureForCustomerByPublicIdMutation,
  useCustomerOrganizationalStructureDeleteOrganizationalStructureForCustomerByPublicIdMutation,
  useCustomerSaveBillingInformationForCustomerByPublicIdMutation,
  useCustomerUpdateBasicCustomerInformationByPublicIdMutation,
  useCustomerRepresentativeUpdateCustomerRepresentativeMutation,
  useCustomerRepresentativeAddCustomerRepresentativeMutation,
  useVisualizationAdminGetAllVisualizationsQuery,
  useVisualizationAdminUpdateVisualizationMutation,
  useVisualizationAdminCreateVisualizationMutation,
  useVisualizationAdminSetVisualizationDatasetDefaultMutation,
  useVisualizationAdminUpdateVisualizationDataReportMutation,
  useAdminUserGetAllQuery,
  useAdminUserUpdateUserMutation,
  useAdminUserSaveUserMutation,
  useAdminUserImportUsersMutation,
  useTrajectoryInstanceAdminGetAllTrajectoryInstancesQuery,
  useTrajectoryInstanceAdminUpdateTrajectoryTypeInstanceMutation,
  useTrajectoryInstanceAdminCreateTrajectoryMutation,
  useTrajectoryInstanceAdminGetProjectParticipantsToAddTrajectoryQuery,
  useTrajectoryInstanceAdminAddProjectParticipantsToTrajectoryMutation,
  useTrajectoryTypeAdminGetAllTrajectoryTypesQuery,
  useTrajectoryTypeAdminUpdateTrajectoryTypeMutation,
  useTrajectoryTypeAdminCreateTrajectoryTypeMutation,
  useTrajectoryPlannedCommandAdminGettrajectoryPlannedCommandQuery,
  useTrajectoryPlannedCommandAdminUpdateTrajectoryPlannedCommandMutation,
  useTrajectoryPlannedCommandAdminDeleteTrajectoryPlannedCommandMutation,
  useTrajectoryPlannedCommandAdminRetryCommandMutation,
  useTrajectoryPlannedCommandAdminCancelCommandMutation,
  useScanAdminGetScanQuery,
  useScanAdminUpdateScanMutation,
  useScanAdminDeleteScanMutation,
  useScanUrlAdminGetScanUrlQuery,
  useScanUrlAdminUpdateScanUrlMutation,
  useScanUrlAdminDeleteScanUrlMutation,
  useScanAdminSyncScanFromTypeMutation,
  useScanTypeAdminGetAllScanTypesQuery,
  useScanTypeAdminUpdateScanTypeMutation,
  useScanTypeAdminCreateScanTypeMutation,
  useScanAdminGetScanSubmissionQuery,
  useScanAdminUpdateScanSubmissionMutation,
  useScanAdminDeleteScanSubmissionMutation,
  useProjectAdminGetProjectQuery,
  useProjectAdminUpdateProjectMutation,
  useProjectAdminDeleteProjectMutation,
  useProjectTypeAdminGetProjectTypeQuery,
  useProjectTypeAdminUpdateProjectTypeMutation,
  useProjectTypeAdminDeleteProjectTypeMutation,
  useDataTransformerPresetGetAllQuery,
  useDataTransformerPresetUpdateMutation,
  useDataTransformerPresetCreateMutation,
  useDataTransformerPresetPerformExcelTransformationMutation,
  useDashboardUrlAdminUpsertDashboardUrlMutation,
  useAdminCreditUpdateCreditsMutation,
  useAdminCompanyGetAllQuery,
  useAdminCompanyUpdateMutation,
  useAdminCompanyCreateMutation,
  useTrajectoryGetTrajectoryTypeInstanceContentTemplatesQuery,
  useTrajectoryUpdateTrajectoryTypeInstanceContentTemplatesMutation,
  useTrajectoryUpdateTrajectoryTypeInstanceContentTemplateMutation,
  useTrajectoryInvokeTrajectoryParticipantInstanceActionMutation,
  useTrajectoryInvokeTrajectoryInstanceBulkActionMutation,
  useTrajectoryInvokeTrajectoryInstanceActionMutation,
  useServiceTokenIssuanceMutation,
  useProjectSaveBasicProjectInformationMutation,
  useScanSubmissionSubmitSurveyMutation,
  useScanSubmissionProgressSurveyMutation,
  useDataReportGetDataReportMutation,
  useDataReportGetIterationsMutation,
  useDashboardInvokeDashboardActionMutation,
  useCustomerSaveBasicCustomerInformationMutation,
  useVisualizationAdminUploadVisualisationDatasetMutation,
  useVisualizationAdminGenerateVisualizationsMutation,
  useVisualizationAdminCloneVisualizationMutation,
  useAdminUserResendOnboardingMailMutation,
  useTrajectoryInstanceAdminUpdateTrajectoryTypeInstanceContentTemplateMutation,
  useTrajectoryInstanceAdminInvokeTrajectoryTypeInstanceActionMutation,
  useTrajectoryInstanceAdminInvokeTrajectoryParticipantInstanceActionMutation,
  useTrajectoryInstanceAdminInvokeTrajectoryInstanceBulkActionMutation,
  useTrajectoryInstanceAdminInvokeTrajectoryInstanceActionMutation,
  useTrajectoryTypeAdminInvokeTrajectoryActionMutation,
  useTrajectoryTypeAdminEvaluateExpressionMutation,
  useScanAdminGetAllScansQuery,
  useScanAdminCreateScanMutation,
  useScanUrlAdminGetAllScanUrlsForScanQuery,
  useScanUrlAdminCreateScanUrlMutation,
  useProjectAdminGetAllProjectsQuery,
  useProjectAdminCreateProjectMutation,
  useProjectTypeAdminGetAllProjectTypesQuery,
  useProjectTypeAdminCreateProjectTypeMutation,
  useProjectTypeAdminAddCompanyToProjectTypeMutation,
  useProjectTypeAdminDeleteCompanyFromProjectTypeMutation,
  useUserGetCurrentUserQuery,
  useTrajectoryGetTrajectoryInstanceQuery,
  useTrajectoryGetTrajectoryInstanceViewQuery,
  useTrajectoryGetTrajectoryInstanceProjectionQuery,
  useTrajectoryGetAllTrajectoryParticipantProjectionsByInstanceIdQuery,
  useTrajectoryGetTrajectoryParticipantInstanceByInstanceIdQuery,
  useTrajectoryGetTrajectoryParticipantInstanceViewQuery,
  useTrajectoryGetParticipantDashboardQuery,
  useTrajectoryGetTrajectoryParticipantInstanceActionsQuery,
  useTrajectoryGetDashboardQuery,
  useTrajectoryGetTrajectoryInstanceBulkActionsQuery,
  useTrajectoryGetTrajectoryInstanceActionsQuery,
  useTrajectoryDownloadAllTrajectoryVisualizationsByPathQuery,
  useTrajectoryDownloadTrajectoryVisualizationQuery,
  useTrajectoryDownloadAllTrajectoryParticipantVisualizationsByPathQuery,
  useTrajectoryGetTrajectoryInstanceByProjectIdQuery,
  useScansGetSubmissionOverviewQuery,
  useScansGetSubmissionOverviewPerDayQuery,
  useProjectGetProjectByIdQuery,
  useProjectDeleteProjectMutation,
  usePingGetPingQuery,
  useLogoRetrieveManualLogoQuery,
  useScanSubmissionGetSurveyQuery,
  useScanSubmissionValidatePartialSubmissionIdQuery,
  useScanSubmissionSurveyUploadQuery,
  useDashboardGetDashboardQuery,
  useDashboardGetDashboardTitleQuery,
  useDashboardGetDashboardAttachmentListQuery,
  useDashboardGetDashboardAttachmentQuery,
  useDashboardGetMainDashboardQuery,
  useCustomerRepresentativeGetCustomerRepresentativesForCustomerByPublicIdQuery,
  useCustomerGetCustomerByPublicIdQuery,
  useCustomerDeleteCustomerByPublicIdMutation,
  useCustomerParticipantDownloadTemplateByPublicIdQuery,
  useProjectGetProjectOverviewByPublicIdQuery,
  useCustomerGetSingleByCompanyPublicIdQuery,
  useCustomerGetOverviewByCompanyPublicIdQuery,
  useProjectGetProjectOverviewForCompanyQuery,
  useCompanyGetByPublicIdQuery,
  useCompanyGetAllUsersQuery,
  useCompanyGetProjectTypesForCompanyQuery,
  useCompanyGetAllUsersByPublicIdQuery,
  useCompanyGetProjectTypesForCompanyByPublicIdQuery,
  useCompanyGetQuery,
  useVisualizationAdminGetVisualizationByIdQuery,
  useVisualizationAdminDeleteVisualizationMutation,
  useVisualizationAdminGetRowsQuery,
  useVisualizationAdminGetIterationsQuery,
  useVisualizationAdminDownloadVisualisationDatasetQuery,
  useVisualizationAdminDeleteVisualisationDatasetMutation,
  useVisualizationAdminGetDataReportsQuery,
  useVisualizationAdminGetAllVisualizationsForExportPageQuery,
  useVisualizationAdminGetAllCreationalTemplateVisualizationsQuery,
  useAdminUserGetByIdQuery,
  useAdminUserGetIdentitiesByIdQuery,
  useAdminUserGetDashboardsByIdQuery,
  useAdminUserDownloadTemplateQuery,
  useTrajectoryInstanceAdminGetTrajectoryInstanceQuery,
  useTrajectoryInstanceAdminDeleteTrajectoryInstanceMutation,
  useTrajectoryInstanceAdminGetTrajectoryInstanceViewQuery,
  useTrajectoryInstanceAdminGetTrajectoryTypeInstanceQuery,
  useTrajectoryInstanceAdminGetTrajectoryTypeInstanceContentTemplatesQuery,
  useTrajectoryInstanceAdminGetResolvedTemplateValueQuery,
  useTrajectoryInstanceAdminGetTrajectoryTypeInstanceActionsQuery,
  useTrajectoryInstanceAdminGetTrajectoryParticipantInstanceByInstanceIdQuery,
  useTrajectoryInstanceAdminDeleteTrajectoryParticipantMutation,
  useTrajectoryInstanceAdminGetParticipantDashboardQuery,
  useTrajectoryInstanceAdminGetTrajectoryParticipantInstanceActionsQuery,
  useTrajectoryInstanceAdminGetAllTrajectoryParticipantProjectionsByInstanceIdQuery,
  useTrajectoryInstanceAdminGetDashboardQuery,
  useTrajectoryInstanceAdminGetTrajectoryInstanceBulkActionsQuery,
  useTrajectoryInstanceAdminGetAttachmentUrlQuery,
  useTrajectoryInstanceAdminDownloadAttachmentBytesQuery,
  useTrajectoryInstanceAdminGetTrajectoryInstanceActionsQuery,
  useTrajectoryTypeAdminGetTrajectoryTypeQuery,
  useTrajectoryTypeAdminDeleteTrajectoryTypeMutation,
  useTrajectoryInstanceAdminGetAllTrajectoryInstancesByTypeIdQuery,
  useTrajectoryTypeAdminGetTrajectoryActionsQuery,
  useTrajectoryTypeAdminGetTagsQuery,
  useTrajectoryInstanceAdminGetTagsQuery,
  useTrajectoryInstanceAdminGetAllTrajectoryProjectionsQuery,
  useTrajectoryInstanceAdminGetTrajectoryInstanceByProjectIdQuery,
  useTrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByInstanceIdQuery,
  useTrajectoryPlannedCommandAdminGetAllTrajectoryPlannedCommandsByParticipantInstanceIdQuery,
  useTrajectoryInstanceAdminGetPlannedCommandsQuery,
  useTrajectoryInstanceAdminGetNameMapQuery,
  useTrajectoryInstanceAdminGetLatestTrajectoryEventsQuery,
  useTrajectoryInstanceAdminGetAllTrajectoryFlowsQuery,
  useScanAdminGetExcelExportQuery,
  useScanUrlAdminGetScanUrlByUrlQuery,
  useScanAdminGetScanSubmissionsQuery,
  useScanAdminGetTransformedDataQuery,
  useScanTypeAdminGetScanTypeQuery,
  useScanTypeAdminDeleteScanMutation,
  useScanTypeAdminGetScanTypeTagsQuery,
  useScanAdminGetScanTagsQuery,
  useScanAdminGetSubmissionOverviewQuery,
  useScanAdminGetSubmissionOverviewPerDayQuery,
  useScanAdminDownloadFileQuery,
  useScanAdminGetAssetManagementUrlQuery,
  useProjectTypeAdminGetCompaniesForProjectTypeQuery,
  useProjectAdminGetTagsQuery,
  useAdminEmailGetDetailsByMessageIdQuery,
  useAdminEmailGetStatusByMessageIdQuery,
  useAdminEmailGetUndeliveredMailsOfLastMonthQuery,
  useAdminEmailGetMailsOfLastMonthQuery,
  useDataTransformerPresetGetByIdQuery,
  useDataTransformerPresetDeleteMutation,
  useDataTransformerPresetGetByKeyQuery,
  useCustomerAdminGetCustomerQuery,
  useAdminCreditGetCreditsQuery,
  useAdminCompanyGetQuery,
  useAdminCompanyDeleteMutation,
  useAdminCompanyGetAllUsersQuery,
  useAdminCompanyGetProjectTypesForCompanyQuery,
  useAdminCompanyGetTagsQuery,
  useCustomerParticipantDeleteParticipantByPublicIdMutation,
  useCustomerRepresentativeDeleteCustomerRepresentativeMutation,
  useTrajectoryInstanceAdminDeletePlannedCommandMutation,
  useDashboardUrlAdminDeleteDashboardUrlMutation,
} = injectedRtkApi;
