import './locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import ThemeProvider from './theme';
import ThemeLocalization from './locales';
import SnackbarProvider from './components/Snackbar';
import ScrollToTop from './components/ScrollToTop';
import { MotionLazyContainer } from './components/Animate';
import { store } from './redux/store';
import { ImpetusAuthProvider } from './auth/ImpetusAuthProvider';
import { msalInstance } from './auth/msalInstance';


export default function App() {

  useEffect(() => {
    // Handle redirect response
    msalInstance.handleRedirectPromise().then(response => {
      if (response) {
        console.log({ response });
      } else {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          console.log({ account: accounts[0] });
        }
      }
    }).catch(error => {
      console.error("Redirect error:", error);
    });
  }, []);

  return (
    <ReduxProvider store={store}>
      <ImpetusAuthProvider>
        <HelmetProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter} options={{
            removeDefaultsFromUrl: true,
            enableBatching: true,
          }}>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <Outlet />
                  </SnackbarProvider>
                </ThemeLocalization>
              </ThemeProvider>
            </MotionLazyContainer>
          </QueryParamProvider>
        </HelmetProvider>
      </ImpetusAuthProvider>
    </ReduxProvider>
  );
}
